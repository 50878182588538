import React from "react";
import "./ParentHeaderLeft.scss";
import { NavLink } from "react-router-dom";
import images from "../../../utilities/images/images";
import { connect } from "react-redux";

const ParentHeaderLeft = (props) => {
  let { studentRollNum, loginFromParent } = props;
  return (
    <>
      <div className="parent-header_left_wrapper">
        <div className="logo">
          <NavLink to="/parent-dashboard">
            <img src={images.motion_logo} alt="" />
          </NavLink>
        </div>
        <div className="left_menus">
          <ul>
            {loginFromParent && (
              <li>
                <NavLink
                  activeClassName="current"
                  className="menu-link"
                  exact
                  to="/parent-dashboard"
                >
                  <span>Dashboard</span>
                </NavLink>
              </li>
            )}

            <li>
              <NavLink
                activeClassName="current"
                className="menu-link"
                to={{
                  pathname: "/student-tests",
                  //   state: { tab: "exam" },
                  state: { step: 1, roll_no: studentRollNum },
                }}
              >
                <span>Tests</span>
              </NavLink>
            </li>

            {loginFromParent && (
              <li>
                <NavLink
                  activeClassName="current"
                  className="menu-link"
                  exact
                  to="/attendance"
                >
                  <span>Attendance</span>
                </NavLink>
              </li>
            )}
             {loginFromParent && (
              <li>
                <NavLink
                  activeClassName="current"
                  className="menu-link"
                  exact
                  to="/student-activity"
                >
                  <span>Student Activity</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    // studentRollNum: state.parentAuthReducer.user.st_rollno,
    studentRollNum: state.parentrootReducer.parentAuthReducer.user.st_rollno,
    loginFromParent: state.parentrootReducer.parentAuthReducer.loginFromParent,
  };
};
export default connect(mapStateToProps, null)(ParentHeaderLeft);
