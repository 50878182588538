import React from "react";
import "./QuestionInfoSection.scss";

const QuestionInfoSection = ({
  currentQuestionDetails,
  lang,
  ConvertStringToHTML,
  testQuestionsDetails,
  currentQuestion,
  setLang,
}) => {
  const renderSingleQuestion = () => {
    const { q_text, q_text_hindi } = currentQuestionDetails || {};
    const isImage = q_text && q_text.includes("<img");
    const isTable = q_text && q_text.includes("<table");
    const isMTable = q_text && q_text.includes("<mtable");

    if (lang) {
      return (
        <div>
          {q_text && (
            <p
              className={`${isImage ? "image-style" : ""} ${
                isTable ? "table-style" : ""
              } ${isMTable ? "mtable-style" : ""}`}
              dangerouslySetInnerHTML={{
                __html: q_text,
              }}
            ></p>
          )}

          {isTable && <table className="table-style"></table>}
          {isMTable && <mtable className="mtable-style"></mtable>}
        </div>
      );
    } else {
      if (ConvertStringToHTML(q_text_hindi)) {
        return (
          <div>
            {q_text_hindi && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text_hindi,
                }}
              ></p>
            )}

            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      } else {
        return (
          <div>
            {q_text && (
              <p
                className={`${isImage ? "image-style" : ""} ${
                  isTable ? "table-style" : ""
                } ${isMTable ? "mtable-style" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: q_text,
                }}
              ></p>
            )}
            {isTable && <table className="table-style"></table>}
            {isMTable && <mtable className="mtable-style"></mtable>}
          </div>
        );
      }
    }
  };

  return (
    <div className="quesitonInfoSection">
      <div className="text_content_wrapper">
        <div className="typeLanguageWrapper">
          <span>
            <span className="qstnTypeKey">Question Type: </span>
            {testQuestionsDetails[currentQuestion]?.answer_type}
          </span>
        </div>
        <div className="qstnCount">
          Question {`${Number(currentQuestion) + 1}.`}
        </div>
        <div className="questionBox">{renderSingleQuestion()}</div>
      </div>
    </div>
  );
};

export default QuestionInfoSection;
