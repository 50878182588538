import { Fragment, useEffect, useState } from "react";
import "./OtsTestResultContentComponent.scss";
import OtsTestSummaryPage from "./ots_result_components/OtsTestSummaryPage";
import { url_129 } from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import { Link } from "react-router-dom";
import images from "../../../../../../utilities/images/images";

// Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import otsActions from "../../../../../../redux/online-test_series/action";

const OtsTestResultContentComponent = (props) => {
  let {
    testResultData,
    testPaperResultData,
    startLoading,
    finishLoading,
    token,
    tokenType,
    setOtsPaperResultData,
  } = props;

  const checkPaperType = (testResultData) => {
    if (testResultData.target_type === "JEE (A)") {
      if (testResultData?.papers?.length > 1) {
        if (testResultData.paper_type === "Paper 1") {
          return 0;
        } else if (testResultData.paper_type === "Paper 2") {
          return 1;
        }
      } else {
        return 0;
      }
    }

    return 0;
  };

  const [paperCategories, setPaperCategories] = useState(testResultData.papers);
  const [currentPaper, setCurrentPaper] = useState(
    testResultData?.papers?.[checkPaperType(testResultData)]
  );

  useEffect(() => {
    getReport();
  }, [currentPaper]);

  const handleChangePaper = (paper) => {
    setCurrentPaper(paper);
  };

  const getReport = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      paper_id: currentPaper?.paper_id,
      test_id: currentPaper?.test_id,
    };

    startLoading();

    try {
      const response = await axios.post(url_129, data, { headers });
      if (response.data.status === 200) {
        finishLoading();
        setOtsPaperResultData(response.data.data);
      } else {
        finishLoading();
        setOtsPaperResultData({});
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const checkTarget = (testResultData) =>
    testResultData?.target_type === "JEE (A)";

  return (
    <Fragment>
      <div className="ots_test_result_content_component_wrapper">
        <div className="ots_test_result_content_component_inner_wrapper">
          <div className="otsTestSummaryPageWrapper">
            <div className="d-flex">
              <Link
                className="backBtn"
                to={{
                  pathname: "/exam",
                  state: { tab: "exam" },
                }}
              >
                <img src={images.user_activity_1} alt="user-activity" />
              </Link>
              <h3 className="ots_test_name">{currentPaper?.paper_name}</h3>
            </div>

            {checkTarget(testResultData) && paperCategories?.length > 1 && (
              <div className="ots_test_summary_header">
                <div className="ots_paper_categories">
                  {paperCategories.map((element) => (
                    <div
                      key={element.paper}
                      className="ots_single_paper_select_category"
                    >
                      <input
                        type="radio"
                        id={element.paper}
                        name="paper"
                        value={element.paper}
                        checked={element.paper === currentPaper.paper}
                        onChange={() => handleChangePaper(element)}
                      />
                      <label htmlFor={element.paper}>{element.paper}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <OtsTestSummaryPage
              testPaperResultData={testPaperResultData}
              currentPaper={currentPaper}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testResultData: state.otsTestsData.otsResultData,
    testPaperResultData: state?.otsTestsData?.otsPaperResultData,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setOtsPaperResultData: (payload) => {
      dispatch(otsActions.setOtsTestsPaperResultData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtsTestResultContentComponent);
