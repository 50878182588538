import React from "react";
import HomeWorkListItemHomePage from "./HomeWorkListItemHomePage";
import "./HomeWorkListHomePage.scss";
import { useSelector } from "react-redux";

const HomeWorkListHomePage = () => {
  const homeworkData = useSelector((state) => state.HomeWorkSlice.homeworkData);

  return homeworkData.length !== 0 ? (
    <div className="homework_list_container_home_page">
      {homeworkData.map((item) => {
        return <HomeWorkListItemHomePage item={item} />;
      })}
    </div>
  ) : (
    <div></div>
  );
};

export default HomeWorkListHomePage;
