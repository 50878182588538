import React, { useState } from "react";
import images from "../../../../../../utilities/images/images";
import "./CreateNewDoubtBtn.scss";
import axios from "axios";
import { url_262 } from "../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";

const CreateNewDoubtBtn = (props) => {
  const { handleShow, userData } = props;
  const [activeButton, setActiveButton] = useState("connectWithTutor");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  // const getConnectTutor = async (userData) => {
  //   try {
  //     const response = await axios.get(
  //       `${url_262}?user_id=${userData?.user_id}`
  //     );
  //     if (response.data.status === "success") {
  //       const sessionUrl = response.data.session_url;
  //       if (sessionUrl) {
  //         window.open(sessionUrl, "_blank");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div
      style={{
        display: "flex",
        margin: "0px auto",
        gap: "16px",
        justifyContent: "center",
      }}
    >
      <div>
        {/* <button
          className={`tutor_button ${
            activeButton === "connectWithTutor" ? "active" : "inactive"
          }`}
          onClick={() => {
            handleButtonClick("connectWithTutor");
            getConnectTutor(userData);
          }}
        >
          Connect with Tutor
          <img
            style={{ marginLeft: "10px", width: "16px", height: "16px" }}
            src={
              activeButton === "connectWithTutor"
                ? images.new_report1
                : images.new_report
            }
            alt=""
          />
        </button> */}
      </div>
      <div>
        <button
          className={`tutor_button ${
            activeButton === "connectNewDoubt" ? "active" : "inactive"
          }`}
          onClick={() => {
            handleButtonClick("connectNewDoubt");
            handleShow();
          }}
        >
          Connect a New Doubt
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(CreateNewDoubtBtn);
