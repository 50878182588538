import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Slider from "react-slick";
import { url_233 } from "../../../custom_utilities/api_services";
import "./Packages.scss";
import { Link } from "react-router-dom";

const Packages = () => {
  const [packages, setPackages] = useState([]);

  const getPackages = useCallback(async () => {
    try {
      const response = await axios.get(url_233);
      if (response.data.status === 200) {
        setPackages([...response.data.data.course]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getPackages();
  }, [getPackages]);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="packages-inner-wrapper">
      <header className="packages-heading-wrapper">
        <div className="packages-heading">
          <span> Accelerate </span> Your Learning Journey
        </div>
        <div className="packages-subheading">
          Master essential skills in record time with our intensive crash
          courses, designed to propel you toward success faster than ever.
        </div>
      </header>

      <div className="packages-content-wrapper">
        <div className="packages-content-inner-wrapper">
          <Slider {...settings}>
            {packages.map((pkg, index) => (
              <div key={index} className="package" style={{ margin: "auto" }}>
                <div className="package-img">
                  <img src={pkg?.banner_image} alt="pkg-img" />
                </div>
                <div className="pkg-details-wrapper">
                  <div className="pkg-title">{pkg.course}</div>
                  <div className="pkg-description">
                    {pkg.description?.length > 27
                      ? `${pkg.description?.slice(0, 27)}...`
                      : pkg.description}
                  </div>
                </div>
                <div className="view-details-btn">
                  <Link
                    to={`/login-store/${pkg?.target_id}/${pkg?.class_id}`}
                    className="class-pill"
                  >
                    <span>View Details</span>{" "}
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Packages;
