import { Fragment, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import LoginStepOne from "./login_step_one/login_step_one";
import LoginStepTwo from "./login_step_two/login_step_two";
import LoaderPopUp from "../../components/loader/loader";
import Signup from "../signUp/signUp";

import axios from "axios";
import { url_1, url_2 } from "../../custom_utilities/api_services";

/////firebase
import firebase from "../../firebase";

import { Analytics } from "aws-amplify";

/////Redux
import { connect } from "react-redux";
import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";
import userPreferenceActions from "../../redux/user_preference/action";
import { setLoginCandidate } from "../../redux/login/LoginSlice";
import { setBlockedStatus } from "../../redux/blocking_user/BlockUserSlice";
// import userProductList from "../../redux/new_store/action";
import userProductList from "../../redux/new_store/action";

// parent Redux

// import parentAuthActions from "../../parent/redux/auth/action";
import {
  inputParentContactNumber,
  setParentUser,
  setParentAuth,
  setLoginFromParentFlag,
} from "../../parent/redux/authSlice/AuthSlice";

const Login = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    step: 1,
    phoneDetails: { mobile_number: "" },
    otpNumbersCollection: {
      otp_number_one: "",
      otp_number_two: "",
      otp_number_three: "",
      otp_number_four: "",
    },
    firebaseToken: "",
    loginType: "Web",
  });

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const [resendCooldown, setResendCooldown] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLogin, setIsLogin] = useState(1);
  const [signUpSteps, setSignupSteps] = useState(1);
  const location = useLocation();
  const store = location.pathname.split("/")[1];

  const packageId = location.pathname.split("/")[2];
  const packageId1 = localStorage.getItem("packageId");

  let {
    loader,
    startLoading,
    finishLoading,
    setContactNumber,
    setAuthToken,
    setUser,
    setAuth,
    setUserPreference,
    setParentContactNumber,
    setParentUser,
    setParentAuth,
    setLoginFromParentFlag,
    loginCandidate,
    setLoginCandidate,
    setLoginType,
  } = props;

  useEffect(() => {
    getFirebaseDeviceToken();
    props.setBlockedStatusAction({
      isBlocked: false,
      remark: null,
    });
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  };
  const getFirebaseDeviceToken = () => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .getToken()
        .then((token) => {
          // console.log("Permitted");
          setState((prevState) => ({ ...prevState, firebaseToken: token }));
        })
        .catch(() => {
          // console.log("Not Permitted");
        });
    }
  };

  const handleRedirectToStudentLogin = () => {
    setLoginCandidate("student");
    history.push("/");
  };

  const handleRedirectToParentLogin = () => {
    setLoginCandidate("parent");
    // history.push("/parent-login");
  };

  const handleNextBtn = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step + 1 }));
  };

  const handlePreviousBtn = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step - 1 }));
  };

  const handleChangeMobileNumber = (event) => {
    let value = event.target.value;

    // Check if the input matches the Indian mobile number pattern
    if (/^[6-9]\d{0,9}$/.test(value) || value === "") {
      setState((prevState) => ({
        ...prevState,
        phoneDetails: { ...prevState.phoneDetails, mobile_number: value },
      }));
    } else {
      alert("Please Enter a Valid Mobile Number");
    }
  };

  const setMobileNumberInStorage = (phoneDetails) => {
    setContactNumber(phoneDetails.mobile_number);
    // localStorage.setItem("phoneDetails", JSON.stringify(phoneDetails));
  };

  const setParentMobileNumberInStorage = (phoneDetails) => {
    setParentContactNumber(phoneDetails.mobile_number);
    // localStorage.setItem("phoneDetails", JSON.stringify(phoneDetails));
  };

  const sendOtpApi = async (mobile_number) => {
    try {
      let data = { mobile_number: mobile_number };

      const response = await axios.post(url_1, data);

      if (response.status === 200) {
        if (response.status === 200) {
          return {
            result: 1,
            data: { message: response.data.message },
          };
        } else {
          return {
            result: 0,
            data: { message: response.data.message },
          };
        }
      } else {
        return {
          result: 0,
          data: { message: "Something went wrong!!!" },
        };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const sendParentOtpApi = async (mobile_number) => {
    const data = {
      method_name: "get_login_step_1",
      f_mobile_no: mobile_number,
      // f_mobile_no: "8958667311",

      var_otp: "",
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/parentotp",
        data
      );
      if (response.data.status === 200) {
        return {
          result: 1,
          data: { message: response.data.message },
        };
      } else {
        return {
          result: 0,
          data: { message: response.data.message },
        };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const submitMobileNumber = async (event, loginCandidate) => {
    event.preventDefault();
    let phoneDetails = { ...state.phoneDetails };

    if (phoneDetails.mobile_number.length == 10) {
      if (phoneDetails.mobile_number) {
        /////////////////////checking the net connection/////////////////////////
        if (window.navigator.onLine) {
          ///////////////////Call send otp api/////////////////////////
          startLoading();
          let returnedResponse = await sendOtpApi(phoneDetails.mobile_number);
          if (returnedResponse.result) {
            //////////////if successfull hit api////////////////////////
            finishLoading();
            setMobileNumberInStorage(phoneDetails);
            handleNextBtn();
            setLoginType("");
          } else {
            ////////////if unsuccessful hit api or not getting desired result//////////
            finishLoading();

            // alert(returnedResponse.data.message);
          }
        } else {
          alert("Your internet connection is not working");
        }
      }
    } else {
      alert("Please Enter Valid Mobile Number");
    }
  };

  const submitParentMobileNumber = async (event) => {
    event.preventDefault();
    let phoneDetails = { ...state.phoneDetails };

    if (phoneDetails.mobile_number.length == 10) {
      if (phoneDetails.mobile_number) {
        /////////////////////checking the net connection/////////////////////////
        if (window.navigator.onLine) {
          ///////////////////Call send otp api/////////////////////////
          startLoading();
          let returnedResponse = await sendParentOtpApi(
            phoneDetails.mobile_number
          );
          if (returnedResponse.result) {
            //////////////if successfull hit api////////////////////////
            finishLoading();
            // setParentData(returnedResponse.data);
            setParentMobileNumberInStorage(phoneDetails);
            handleNextBtn();
          } else {
            ////////////if unsuccessful hit api or not getting desired result//////////
            finishLoading();
            alert(returnedResponse.data.message);
          }
        } else {
          alert("Your internet connection is not working");
        }
      }
    } else {
      alert("Please Enter a Valid Mobile Number");
    }
  };

  ////////////////////step_2////////////////////////
  const handleInputOtpNumbers = (event) => {
    let { name, value } = event.target;
    // console.log(name, value);
    switch (name) {
      case "otp_number_one":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_one: value,
          },
        }));
        break;
      case "otp_number_two":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_two: value,
          },
        }));
        break;
      case "otp_number_three":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_three: value,
          },
        }));
        break;
      case "otp_number_four":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_four: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const validCheckOtpNumber = (otpNumbers) => {
    let valid = true;

    Object.values(otpNumbers).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };

  const verifyOtpApi = async (otpDetails) => {
    try {
      const response = await axios.post(url_2, otpDetails);

      if (response.status === 200) {
        /////if api successfull hit//////////

        if (response.data.data.is_sign_up) {
          if (response.status === 200) {
            /////////////////existing user//////////
            return {
              result: 1,
              data: {
                message: response.data.message,
                apiData: response.data.data,
              },
            };
          } else if (response.data.status === 201) {
            ///////for new-user////////////////////
            return {
              result: 2,
              data: {
                message: response.data.message,
                apiData: response.data.data,
              },
            };
          } else if (response.data.status === 423) {
            /////////redirect to resend otp//////////////
            return {
              result: 3,
              data: {
                message: response.data.message,
                apiData: response.data.data,
              },
            };
          } else {
            ////////for other status code///////////////
            return {
              result: 5,
              data: {
                message: response.data.message,
                apiData: response.data.data,
              },
            };
          }
        } else if (response.data.status === 422) {
          return {
            result: 0,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          setIsLogin(2);
          return {
            result: 2,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        }
      } else {
        /////if api un-successfull hit//////////
        return {
          result: 0,
          data: { message: "Something went wrong!!!" },
        };
      }
    } catch (error) {
      console.log(error.response);
      Analytics.record({
        name: "_userauth.auth_fail",
      });
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const handleSubmitOtpNumber = async (event) => {
    event.preventDefault();
    let otpNumbersCollection = { ...state.otpNumbersCollection };
    let phoneDetails = { ...state.phoneDetails };
    let firebaseToken = state.firebaseToken,
      loginType = state.loginType;

    if (validCheckOtpNumber(otpNumbersCollection)) {
      let filledOtp = `${otpNumbersCollection.otp_number_one}${otpNumbersCollection.otp_number_two}${otpNumbersCollection.otp_number_three}${otpNumbersCollection.otp_number_four}`;
      ///////change the type  of string to number//////////////
      filledOtp = parseInt(filledOtp);

      if (filledOtp) {
        startLoading();
        let data = {
          mobile_number: phoneDetails.mobile_number,
          otp: filledOtp,
          firebaseToken: firebaseToken,
          loginType: loginType,
        };
        let returnedResponse = await verifyOtpApi(data);
        // console.log(returnedResponse);
        if (returnedResponse.result === 1) {
          finishLoading();
          //////////for existing user//////////
          let tokenDetails = {
            token: returnedResponse.data.apiData.token,
            tokenType: returnedResponse.data.apiData.token_type,
          };
          let user = returnedResponse.data.apiData.user_data;

          /////setting auth token////////
          setAuthToken(tokenDetails);

          ////////set user data/////////////////
          setUser(user);
          setAuth(true);
          //////set user preference////////
          setUserPreference({
            targetDetails:
              returnedResponse.data.apiData.user_data.UsertargetDetail,
            classDetails: {
              class_id: returnedResponse.data.apiData.user_data.class_id,
              class: returnedResponse.data.apiData.user_data.class,
            },
            courseDetails: returnedResponse.data.apiData.user_data.targets,
            batchDetails: {
              batch_id: returnedResponse.data.apiData.user_data.batch_id,
              batch: returnedResponse.data.apiData.user_data.batch,
            },
          });

          ////////redirecting to the user dashboard//////
          // history.push("/user_dashboard");

          const queryParams = new URLSearchParams(window.location.search);
          const pageParam = queryParams.get("page");

          console.log("queryparamssss", queryParams);

          if (store) {
            if (store == "packages-overview" || store == "login-store") {
              setLoginType("store");
              history.push({ pathname: `/package/${packageId1 || packageId}` });
            } else {
              setLoginType("dashboard");

              history.push({ pathname: "/user_dashboard" });
            }
          } else {
            history.push({ pathname: "/user_dashboard" });
            setLoginType("dashboard");
          }

          // if (!pageParam) {
          //   history.push({ pathname: "/store" });
          //   setLoginType("store");
          // } else {
          //   history.push({ pathname: "/user_dashboard" });
          //   setLoginType("dashboard");
          // }

          Analytics.record({
            name: "_userauth.sign_in",
            attributes: {
              user_name: user.name,
            },
          });

          let userPaidFree = user.is_subscribe == 1 ? "paid_user" : "free_user";

          await Analytics.updateEndpoint({
            userId: user.user_id,

            userAttributes: {
              userName: [user.name],
              Mobile: [user.mobile],
              email: [user.email],
              DOB: [user.dob],
              Gender: [user.gender],
              Class_name: [user.class],
              Target: [user.UsertargetDetail[0].target],
              user_paid_free: [userPaidFree],
              Batch: [user.batch],
              Course: [user.targets[0].course],
              addressId: [user.address.address_id],
              addressType: [user.address.type],
              city: [user.address.city],
              pincode: [user.address.pincode],
              state: [user.address.state],
              latitude: [lat?.length ? lat : null], // The latitude of the endpoint location, rounded to one decimal place.
              longitude: [lng?.length ? lng : null], // The longitude of the endpoint location, rounded to one decimal place.
            },
          }).then(() => {});

          // history.push("/parent_login");
        } else if (returnedResponse.result === 2) {
          finishLoading();
          /////for new user///////////////
          let tokenDetails = {
            token: returnedResponse.data.apiData.token,
            tokenType: returnedResponse.data.apiData.token_type,
          };

          /////setting auth token////////
          setAuthToken(tokenDetails);
          ////////redirecting to the sign up//////
          // history.push("/signUp");
        } else {
          finishLoading();
          alert(returnedResponse.data.message);
        }
      }
    } else {
      alert("!!!Please,Enter proper valid OTP");
    }
  };

  const verifyParentOtpApi = async (otpDetails) => {
    const data = {
      method_name: "verify_otp",
      f_mobile_no: otpDetails.mobile_number,
      var_otp: otpDetails.otp,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/parentverifyotp",
        data
      );

      /////if api successfull hit//////////
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
            apiData: response.data.data,
          },
        };
      } else {
        /////if api un-successfull hit//////////
        return {
          result: 0,
          data: { message: response.data.message },
        };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const handleSubmitParentOtpNumber = async (event) => {
    event.preventDefault();
    let otpNumbersCollection = { ...state.otpNumbersCollection };
    let phoneDetails = { ...state.phoneDetails };
    let firebaseToken = state.firebaseToken,
      loginType = state.loginType;

    if (validCheckOtpNumber(otpNumbersCollection)) {
      let filledOtp = `${otpNumbersCollection.otp_number_one}${otpNumbersCollection.otp_number_two}${otpNumbersCollection.otp_number_three}${otpNumbersCollection.otp_number_four}`;
      ///////change the type  of string to number//////////////
      filledOtp = parseInt(filledOtp);

      if (filledOtp) {
        startLoading();
        let data = {
          mobile_number: phoneDetails.mobile_number,
          otp: filledOtp,
          firebaseToken: firebaseToken,
          loginType: loginType,
        };
        let returnedResponse = await verifyParentOtpApi(data);
        if (returnedResponse.result === 1) {
          let user = returnedResponse.data.apiData;
          finishLoading();

          setParentUser(user);
          setLoginFromParentFlag(true);
          // setParentAuth(true);

          ////////redirecting to the parent dashboard//////
          history.push({
            pathname: "/parent-dashboard",
          });
        } else {
          finishLoading();
          alert(returnedResponse.data.message);
          // alert("alert from handleSubmitOtpNumber func");
        }
      }
    } else {
      alert("!!!Please,Enter proper valid OTP");
    }
  };

  // const handleResendOtp = async () => {
  //   let phoneDetails = { ...state.phoneDetails };

  //   if (phoneDetails.mobile_number) {
  //     /////////////////////checking the net connection/////////////////////////
  //     if (window.navigator.onLine) {
  //       ///////////////////Call send otp api/////////////////////////
  //       // startLoading();
  //       let returnedResponse = await sendOtpApi(phoneDetails.mobile_number);
  //       if (returnedResponse.result) {
  //         //////////////if successfull hit api////////////////////////
  //         finishLoading();
  //       } else {
  //         ////////////if unsuccessful hit api or not getting desired result//////////
  //         finishLoading();
  //         alert(returnedResponse.data.message);
  //       }
  //     } else {
  //       alert("Your internet connection is not working");
  //     }
  //   }
  // };

  const handleResendOtp = async () => {
    if (!isButtonDisabled) {
      // Check if the button is disabled (cooldown active)
      let phoneDetails = { ...state.phoneDetails };

      if (phoneDetails.mobile_number) {
        /////////////////////checking the net connection/////////////////////////
        if (window.navigator.onLine) {
          setIsButtonDisabled(true); // Disable the button before sending OTP
          ///////////////////Call send otp api/////////////////////////
          // startLoading();
          let returnedResponse = await sendOtpApi(phoneDetails.mobile_number);
          if (returnedResponse.result) {
            //////////////if successful hit api////////////////////////
            finishLoading();
            // Set a new cooldown timer after OTP is sent
            setResendCooldown(30);
            setTimeout(() => {
              setIsButtonDisabled(false); // Enable the button after cooldown
            }, 30000); // Enable after 30 seconds
          } else {
            ////////////if unsuccessful hit API or not getting the desired result//////////
            finishLoading();
            setIsButtonDisabled(false); // Enable the button in case of an error
            alert(returnedResponse.data.message);
          }
        } else {
          alert("Your internet connection is not working");
        }
      }
    }
  };

  // const handleParentResendOtp = async () => {
  //   let phoneDetails = { ...state.phoneDetails };

  //   if (phoneDetails.mobile_number) {
  //     /////////////////////checking the net connection/////////////////////////
  //     if (window.navigator.onLine) {
  //       ///////////////////Call send otp api/////////////////////////
  //       // startLoading();
  //       let returnedResponse = await sendParentOtpApi(
  //         phoneDetails.mobile_number
  //       );
  //       if (returnedResponse.result) {
  //         //////////////if successfull hit api////////////////////////
  //         finishLoading();
  //       } else {
  //         ////////////if unsuccessful hit api or not getting desired result//////////
  //         finishLoading();
  //         alert(returnedResponse.data.message);
  //       }
  //     } else {
  //       alert("Your internet connection is not working");
  //     }
  //   }
  // };

  const handleParentResendOtp = async () => {
    if (!isButtonDisabled) {
      // Check if the button is disabled (cooldown active)
      let phoneDetails = { ...state.phoneDetails };

      if (phoneDetails.mobile_number) {
        /////////////////////checking the net connection/////////////////////////
        if (window.navigator.onLine) {
          setIsButtonDisabled(true); // Disable the button before sending OTP
          ///////////////////Call send parent otp api/////////////////////////
          // startLoading();
          let returnedResponse = await sendParentOtpApi(
            phoneDetails.mobile_number
          );
          if (returnedResponse.result) {
            //////////////if successful hit api////////////////////////
            finishLoading();
            // Set a new cooldown timer after OTP is sent
            setResendCooldown(30);
            setTimeout(() => {
              setIsButtonDisabled(false); // Enable the button after cooldown
            }, 30000); // Enable after 30 seconds
          } else {
            ////////////if unsuccessful hit API or not getting the desired result//////////
            finishLoading();
            setIsButtonDisabled(false); // Enable the button in case of an error
            alert(returnedResponse.data.message);
          }
        } else {
          alert("Your internet connection is not working");
        }
      }
    }
  };

  const renderView = () => {
    let { step, phoneDetails } = state;

    switch (step) {
      case 1:
        return (
          <LoginStepOne
            phoneDetails={phoneDetails}
            handleChangeMobileNumber={handleChangeMobileNumber}
            submitMobileNumber={submitMobileNumber}
            submitParentMobileNumber={submitParentMobileNumber}
            loginCandidate={loginCandidate}
            handleRedirectToStudentLogin={handleRedirectToStudentLogin}
            // handleChangeToParentLogin={handleChangeToParentLogin}
            handleRedirectToParentLogin={handleRedirectToParentLogin}
            history={history}
          />
        );
      case 2:
        return (
          <LoginStepTwo
            handlePreviousBtn={handlePreviousBtn}
            handleInputOtpNumbers={handleInputOtpNumbers}
            handleSubmitOtpNumber={handleSubmitOtpNumber}
            handleResendOtp={handleResendOtp}
            handleSubmitParentOtpNumber={handleSubmitParentOtpNumber}
            loginCandidate={loginCandidate}
            handleParentResendOtp={handleParentResendOtp}
            resendCooldown={resendCooldown}
            isButtonDisabled={isButtonDisabled}
            setResendCooldown={setResendCooldown}
            setIsButtonDisabled={setIsButtonDisabled}

            // handleChangeLogin={handleChangeLogin}
          />
        );
      default:
        return null;
    }
  };

  const signupStepOne = () => {
    return (
      <div className="name-container">
        <div className="heading">Hi! What shall we call you.</div>
        <input />
      </div>
    );
  };

  const signupStepTwo = () => {
    return <div>sign up step one</div>;
  };

  const signupStepThree = () => {
    return <div>sign up step one</div>;
  };
  const renderSignup = () => {
    switch (signUpSteps) {
      case 1:
        return signupStepOne();

      case 2:
        return signupStepTwo();

      case 3:
        return signupStepThree();

      default:
        return null;
    }
  };

  // if (isLogin === 2) {
  //   return <singup />;
  // }

  return (
    <Fragment>
      {isLogin === 1 && renderView()}

      {isLogin === 2 && <Signup history={history} />}

      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    loginCandidate: state.LoginSlice.loginCandidate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setContactNumber: (payload) => {
      dispatch(authActions.inputContactNumber(payload));
    },
    setAuthToken: (payload) => {
      dispatch(authActions.setAuthToken(payload));
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setAuth: (payload) => {
      dispatch(authActions.setAuth(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },

    // parent actions

    setParentContactNumber: (payload) => {
      dispatch(inputParentContactNumber(payload));
    },

    setParentUser: (payload) => {
      dispatch(setParentUser(payload));
    },

    setParentAuth: (payload) => {
      dispatch(setParentAuth(payload));
    },

    setLoginFromParentFlag: (payload) => {
      dispatch(setLoginFromParentFlag(payload));
    },

    setLoginCandidate: (payload) => {
      dispatch(setLoginCandidate(payload));
    },
    setBlockedStatusAction: (payload) => {
      dispatch(setBlockedStatus(payload));
    },
    setLoginType: (payload) => {
      dispatch(userProductList.setLoginType(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
