import React, { Fragment } from "react";
import "./QueOptions.scss";

const renderOptionText = (option) => {
  return { __html: option };
};

const renderOptionInitial = (index) => {
  const alphabetArr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabetArr[index];
};

const QuestionOptions = ({
  showSolution,
  getClicked,
  textInputSolution,
  lang,
  questionData,
  currentQuestion,
  sol,
  testQuestionsDetails,
  checkQuestion,
  disableSol,
  handleSingleSelectAnswer,
  handleMultiSelectAnswer,
  handleInputFieldChange,
  setSol,
}) => {
  const renderOptions = (
    questionData,
    currentQuestion,
    lang,
    sol,
    testQuestionsDetails,
    checkQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    disableSol
  ) => {
    if (!questionData) return null;

    const { answer_type: type } = questionData;
    let step;

    if (type.toLowerCase() === "single correct") {
      step = 1;
    } else if (type.toLowerCase() === "integer correct") {
      step = 2;
    } else {
      step = 3;
    }

    switch (step) {
      case 1:
        return renderSingleSelect(
          questionData,
          currentQuestion,
          lang,
          sol,
          testQuestionsDetails,
          checkQuestion,
          handleSingleSelectAnswer,
          disableSol
        );
      case 2:
        return renderInputField(
          questionData,
          currentQuestion,
          checkQuestion,
          handleInputFieldChange,
          disableSol
        );
      case 3:
        return renderMultiSelect(
          questionData,
          currentQuestion,
          sol,
          testQuestionsDetails,
          handleMultiSelectAnswer,
          disableSol
        );
      default:
        return null;
    }
  };

  const config = {
    //loader: { load: ["[tex]/html"] },
    loader: {
      load: [
        "input/asciimath",
        "output/chtml",
        "ui/menu",
        "[mml]/mml3",
        "[tex]/html",
      ],
    },
  };

  const classEditor = (s, wc, optionId, sol) => {
    if (!sol && Number(s) && getClicked()) {
      return "single_option_wrapper classCorrect";
    } else if (
      !sol &&
      wc &&
      wc.indexOf(Number(optionId)) > -1 &&
      getClicked()
    ) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "single_select_option_collection_wrapper show"
              : "single_select_option_collection_wrapper hide"
          }
        >
          {questionData.option && questionData.option.length
            ? questionData.option.map((element, index) => {
                let optionText = element.option;
                if (
                  !lang &&
                  questionData.option_hindi &&
                  questionData.option_hindi[index]
                ) {
                  optionText =
                    questionData.option_hindi[index].option || element.option;
                }

                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      disabled={sol ? false : true}
                      checked={
                        questionData.userAnswerCollection.length
                          ? questionData.userAnswerCollection[0] ===
                            element.option_id
                          : element.is_user_correct_ans === 1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        // handleCurrentOption(element.option_id);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        questionData.wrongAnswerIdCollection,
                        element.option_id,
                        sol
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">
                          {`${renderOptionInitial(index)}.`}
                        </p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={{ __html: optionText }}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (
    questionData,
    currentQuestion,
    sol,
    testQuestionsDetails,
    handleMultiSelectAnswer,
    disableSol
  ) => {
    return (
      <Fragment>
        <div className={"multi_select_option_collection_wrapper show"}>
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      checked={
                        testQuestionsDetails[currentQuestion]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion]
                          .wrongAnswerIdCollection,
                        element.option_id,
                        sol
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = (
    questionData,
    currentQuestion,
    checkQuestion,
    handleInputFieldChange,
    disableSol
  ) => {
    return (
      <Fragment>
        <div className={"input_wrapper show2"}>
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion}
              type="number"
              placeholder="Enter your answer"
              // value={testQuestionsDetails[currentQuestion].integer_answer}
              value={questionData.integer_answer}
              onChange={(event) => {
                textInputSolution(event.target.value);
                handleInputFieldChange(event);
                disableSol();
              }}
              disabled={checkQuestion ? false : true}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      {renderOptions(
        questionData,
        currentQuestion,
        lang,
        sol,
        testQuestionsDetails,
        checkQuestion,
        handleSingleSelectAnswer,
        handleMultiSelectAnswer,
        handleInputFieldChange,
        disableSol
      )}
      {/* {renderOptions(
        questionData,
        currentQuestion,
        lang,
        sol,
        testQuestionsDetails,
        checkQuestion,
        handleSingleSelectAnswer,
        handleMultiSelectAnswer,
        handleInputFieldChange,
        disableSol
      )} */}
    </div>
  );
};

export default QuestionOptions;
