import { Fragment, useState, useEffect, useCallback } from "react";
import "./user_doubts_content_component.scss";

import axios from "axios";
import { url_9, url_21 } from "../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";

////
import DoubtsView from "./doubtsView/doubtsView";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const UserDoubtsContentComponent = (props) => {
  let { history, userPreference, startLoading, finishLoading } = props;
  let { courseDetails, classDetails, batchDetails } = userPreference;
  const [state, setState] = useState({
    subjectCollection: {},
    answeredDoubts: {},
    unAnsweredDoubts: {},
    scannedDoubts: {},
    subjectDetails: {
      subjectId: "",
    },
    isAnswered: 1,
    doubtDetails: {},
    doubtType: "answered",
  });
  useEffect(() => {
    getSubjectList();
  }, []);

  useEffect(() => {
    if (state.subjectDetails.subjectId) {
      getQuestionList();
    }
  }, [state.subjectDetails.subjectId, state.doubtType]);

  const getSubjectList = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };

    startLoading();

    try {
      const response = await axios.post(url_9, data);
      if (response.data.status === 200) {
        if (response.data.data.subject.length) {
          setState((prevState) => ({
            ...prevState,
            subjectCollection: response.data.data,
            subjectDetails: {
              ...prevState.subjectDetails,
              subjectId: response.data.data.subject[0].sub_id,
            },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionList = async () => {
    let subjectDetails = { ...state.subjectDetails };
    let { isAnswered, doubtType } = state;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      subject_id: parseInt(subjectDetails.subjectId),
      is_answered: isAnswered, //1->answerd, 0 ->unanswered
      is_scanned: state.doubtType === "scanned" ? "Y" : "N",
    };

    startLoading();
    try {
      const response = await axios.post(url_21, data);
      if (response.data.status === 200) {
        finishLoading();
        if (doubtType === "answered") {
          setState((prevState) => ({
            ...prevState,
            answeredDoubts: response.data.data,
          }));
        } else if (doubtType === "unanswered") {
          setState((prevState) => ({
            ...prevState,
            unAnsweredDoubts: response.data.data,
          }));
        } else if (doubtType === "scanned") {
          setState((prevState) => ({
            ...prevState,
            scannedDoubts: response.data.data,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleTabChange = (isAnswered, doubtType) => {
    setState((prevState) => ({
      ...prevState,
      isAnswered: isAnswered,
      doubtType: doubtType,
    }));
  };

  const handleCreateDoubtBtn = () => {
    history.push("/create_doubt");
  };

  const handleChangeSubject = (event, sub_id, sub_name, icon) => {
    let { value } = event.target;
    // console.log(value);
    setState((prevState) => ({
      ...prevState,
      subjectDetails: {
        ...prevState.subjectDetails,
        subjectId: value,
      },
    }));
  };

  const handleSaveDoubtDetails = (doubtDetails) => {
    if (!isEmpty(doubtDetails)) {
      setState((prevState) => ({
        ...prevState,
        doubtDetails: doubtDetails,
      }));
    } else {
      alert("You don't have doubt details");
    }
  };

  return (
    <Fragment>
      <div className="userDoubtsContentComponent_wrapper">
        <div className="container">
          <DoubtsView
            subjectCollection={state.subjectCollection}
            answeredDoubts={state.answeredDoubts}
            unAnsweredDoubts={state.unAnsweredDoubts}
            scannedDoubts={state.scannedDoubts}
            subjectDetails={state.subjectDetails}
            handleCreateDoubtBtn={handleCreateDoubtBtn}
            handleChangeSubject={handleChangeSubject}
            handleTabChange={handleTabChange}
            handleSaveDoubtDetails={handleSaveDoubtDetails}
            selectedDetail={state.doubtDetails}
            getQuestionList={getQuestionList}
            doubtType={state.doubtType}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(UserDoubtsContentComponent);
