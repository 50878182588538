import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setOrderId,
  saveTrnsactionDetails,
  setCcAvenueDataInStore,
} from "../../redux/payment_gateway/action";
import "./CcAvenuePayment.scss";
import { url_147 } from "../api_services";
import EmailUpdateModal from "../../containers/logged_user_components/user_edit_profile/user_edit_profile_content_component/update_email/EmailUpdateModal";
import AlreadyPurchasedModal from "./AlreadyPurchasedModal";

const CcAvenuePayment = (props) => {

  const {
    packageDetails,
    amount,
    token,
    tokenType,
    paymentAction,
    saveTransactionAction,
    setCcAvenueDataInStore,
    userData,
    buttonText,
    buttonClass,
    orderId,
    is_amrit,
  } = props;


  const [emailStatus, setEmailStatus] = useState({
    showEmailModal: false,
    emailUpdated: false,
  });

  const [paymentModal, setPaymentModal] = useState(false);

  const handleOpen = () => {
    setEmailStatus((prevState) => ({
      ...prevState,
      showEmailModal: true,
    }));
  };

  const handleClose = () => {
    setEmailStatus((prevState) => ({
      ...prevState,
      showEmailModal: false,
    }));
  };

  const { name, mobile, email, address, batch_id, user_id, UsertargetDetail } =
    userData;

  const [ccAvenueData, setCcAvenueData] = useState({});

  useEffect(() => {
    getCcAvenueData();
  }, []);

  const currentData = new Date();
  let date =
    currentData.getFullYear() +
    "-" +
    (currentData.getMonth() + 1) +
    "-" +
    currentData.getDate();
  let time =
    currentData.getHours() +
    ":" +
    currentData.getMinutes() +
    ":" +
    currentData.getSeconds();
  var dateTime = date + " " + time;

  const getCcAvenueData = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    try {
      const response = await axios.get(
        `${url_147}?mode=web&product_id=${packageDetails.package_id ? packageDetails.package_id : ""
        }&
      product_title=${packageDetails.package_name ? packageDetails.package_name : ""
        }&
      final_price=${amount ? amount : "0"}&
      coupon_code=0&
      ccapplied_id=0&
      email=${email}&
      contact_no=${mobile}`,
        { headers }
      );

      if (response.data.status === 200) {
        setCcAvenueData(response.data);
        paymentAction(response.data.Order_Id);
        setCcAvenueDataInStore(response.data);
        saveTransactionAction({
          course_id: userData.targets[0].course_id,
          batch_id,
          package_id: packageDetails?.package_id
            ? packageDetails?.package_id
            : "",
          txn_amount: amount,
          txn_discount_amount: packageDetails?.discount
            ? packageDetails.discount
            : "",
          txn_date: dateTime,
          package_duration: packageDetails?.duration
            ? packageDetails.duration
            : "",
          txnid: response.data.Order_Id,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfileData = (e) => {
    if (is_amrit || packageDetails.is_subscribe) {
      setPaymentModal(true);
      e.stopPropagation();
      e.preventDefault();
    } else {
      const fieldsToCheck = [
        {
          field: name,
          label: "name",
        },
        {
          field: mobile,
          label: "mobile number",
        },
        {
          field: email,
          label: "email",
        },
        {
          field: address.state,
          label: "state",
        },
        {
          field: address.city,
          label: "city",
        },
      ];

      const updatedfields = fieldsToCheck.filter(
        (element) => element.field === "" || element.field === null
      );

      if (updatedfields.length > 0) {
        let missingFields = updatedfields.map((item) => item.label).join(", ");

        if (missingFields.includes("email")) {
          handleOpen();
        }
      }
      window.onbeforeunload = null;
    }
  };

  const ccAvenueFunction = () => {
    return (
      <>
        <div id="ccav-payment-form">
          <form
            name="frmPayment"
            action="https://learning.motion.ac.in/motioneducation/api/user/get/get_cca_request_handler"
            method="post"
          >
            <input
              type="hidden"
              name="merchant_id"
              value={ccAvenueData.Merchant_ID}
            />
            <input type="hidden" name="language" value="EN" />
            <input type="hidden" name="amount" value={amount} />

            <input type="hidden" name="currency" value="INR" />
            <input
              type="hidden"
              name="redirect_url"
              // value={`${
              //   ccAvenueData.Redirect_Url
              // }?page=${"https://learninguat.motion.ac.in/payment_gateway"}`}

              value={`${ccAvenueData.Redirect_Url
                }?page=${`${'https://learning.motion.ac.in'}/payment_gateway`}`}

            // value={`${ccAvenueData.Redirect_Url
            //   }?page=${"http://localhost:3000/payment_gateway"}`}
            />
            <input
              type="hidden"
              name="cancel_url"
              // value={`${
              //   ccAvenueData?.Redirect_Url
              // }?page=${"https://learninguat.motion.ac.in/payment_gateway"}`}

              // value={`${
              //   ccAvenueData.Redirect_Url
              // }?page=${"https://webtesting.motion.ac.in/payment_gateway"}`}

              value={`${ccAvenueData?.Cancel_Url
                }?page=${`${'https://learning.motion.ac.in'}/payment_gateway`}`}

            // value={`${ccAvenueData.Redirect_Url
            //   }?page=${"http://localhost:3000/payment_gateway"}`}
            />
            <input
              type="hidden"
              name="order_id"
              value={ccAvenueData.Order_Id}
            />
            <input
              type="hidden"
              name="access_code"
              value={ccAvenueData.Access_code}
            />

            <input
              type="hidden"
              name="rsa_key_url"
              value={ccAvenueData.RSA_Url}
            />

            <input
              type="hidden"
              name="merchant_param4"
              value={packageDetails.package_id ? packageDetails.package_id : ""}
            />

            <input
              type="hidden"
              name="duration"
              value={packageDetails?.duration ? packageDetails?.duration : ""}
            />

            <input
              type="hidden"
              name="discountAmount"
              value={packageDetails.discount ? packageDetails.discount : ""}
            />

            <input
              type="hidden"
              name="merchant_param3"
              value={
                packageDetails.package_name ? packageDetails.package_name : ""
              }
            />

            <input
              type="hidden"
              name="merchant_param1"
              value={userData?.class}
            />

            <input
              type="hidden"
              name="packageAmt"
              value={packageDetails.amount ? packageDetails.amount : ""}
            />
            <input
              type="hidden"
              name="courseId"
              value={packageDetails.course_id ? packageDetails.course_id : ""}
            />

            <input type="hidden" name="finalAmount" value={amount} />

            <input type="hidden" name="billing_name" value={name} />
            <input type="hidden" name="billing_tel" value={mobile} />
            <input type="hidden" name="billing_email" value={email} />
            <input
              type="hidden"
              name="billing_address"
              value={address.address_type}
            />
            <input
              type="hidden"
              name="billing_city"
              value={address.city ? address.city : ""}
            />
            <input
              type="hidden"
              name="billing_state"
              value={address.state ? address.state : ""}
            />

            <input type="hidden" name="batchId" value={batch_id} />
            <input type="hidden" name="userId" value={user_id} />
            <input
              type="hidden"
              name="merchant_param2"
              value={UsertargetDetail.target_id}
            />

            {/* <input type="hidden" name="txnId" value={packageDetails.Order_Id} /> */}
            <input type="hidden" name="txnId" value={ccAvenueData.Order_Id} />

            <input
              type="hidden"
              name="order_id"
              value={ccAvenueData.Order_Id}
            />

            {/* <div className="pay_now" onClick={handleProfileData}>
              <button>Pay Now</button>
            </div> */}

            {/* <div
              className={buttonClass?.length !== 0 ? buttonClass : "pay_now"}
              onClick={handleProfileData}
            >
              <button type="submit">
                {buttonText?.length !== 0 ? buttonText : "Pay NowN"}
              </button>
            </div> */}

            <button
              type="submit"
              className={buttonClass?.length !== 0 ? buttonClass : "pay_now"}
            >
              {buttonText?.length !== 0 ? buttonText : "Pay Now"}
            </button>
          </form>
        </div>
      </>
    );
  };

  // const ccAvenueFunction1 = () => {
  //   return (
  //     <div
  //       className={buttonClass?.length !== 0 ? buttonClass : "pay_now"}
  //       onClick={handleProfileData}
  //     >
  //       <button type="submit">{buttonText?.length !== 0 ? buttonText : "Pay Now"}</button>
  //     </div>
  //   );
  // };

  const ccAvenueFunction1 = () => {
    return (
      <button
        type="submit"
        className={buttonClass?.length !== 0 ? buttonClass : "pay_now"}
        onClick={handleProfileData}
      >
        {buttonText?.length !== 0 ? buttonText : "Pay Now"}
      </button>
    );
  };

  return (
    <>
      {Object.keys(ccAvenueData).length &&
        (email.length ? ccAvenueFunction() : ccAvenueFunction1())}

      {emailStatus?.showEmailModal && (
        <EmailUpdateModal
          isEmailModalOpen={emailStatus.showEmailModal}
          closeEmailModal={handleClose}
        />
      )}
      {/* <AlreadyPurchasedModal paymentModal = {paymentModal} setPaymentModal = {setPaymentModal} /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  console.log("stateToProps", state);
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
    userData: state.auth.user,
    orderId: state.paymentReducer.orderId,
    user_id: state.auth.user.user_id,
    is_amrit: state.auth.user.is_amrit,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    paymentAction: (payload) => {
      dispatch(setOrderId(payload));
    },
    saveTransactionAction: (payload) => {
      dispatch(saveTrnsactionDetails(payload));
    },
    setCcAvenueDataInStore: (payload) => {
      dispatch(setCcAvenueDataInStore(payload));
    },
    setCcAvenueDataInStore: (payload) => {
      dispatch(setCcAvenueDataInStore(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(CcAvenuePayment);
