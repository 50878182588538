import React from "react";
import "./RewardModal.scss";
import Modals from "../Modal/CustomModal";
import images from "../../utilities/images/images";
import { Link } from "react-router-dom";

const RewardModal = ({
  show,
  onHide,
  earnMoreHandler,
  earnedCoins,
  reason,
  ...rest
}) => {
  // console.log(
  //   "earnedCoins",
  //   earnedCoins,
  //   "reason-----------",
  //   reason,
  //   "coinPopup-show",
  //   show

  // );
  return (
    <Modals show={show} onHide={onHide} {...rest} className="rewardModal">
      <div className="d-flex justify-content-center align-items-end gap-3">
        <img src={images.new_motionCoin} width={45} alt="coins" />
        <p className="mb-0 coinNum">{earnedCoins}</p>
        <p className="mb-0 coinText">Motion Coins </p>
      </div>
      <p className="text-center coinText1">
        Yay! You have earned {earnedCoins} Motion Coins by <br />
        {reason}.
      </p>

      <div className="text-center mb-4">
        <Link className="coinBtn" to="/wallet">
          Use Motion Coins Now
        </Link>
      </div>
      <p className="coinText3 text-center">
        How to Earn more Motion Coins?{" "}
        <span onClick={earnMoreHandler}>Know More</span>
      </p>
    </Modals>
  );
};

export default RewardModal;
