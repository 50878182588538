import React, { useEffect, useState, useLocation } from "react";
import axios from "axios";
import { isEmpty } from "../../../../../../../../../custom_utilities/custom_useful_functions";
import { connect } from "react-redux";
import SubjectWiseMarkChart from "./ots_subject_wise_charts/OtsSubjectChart";
import { url_130 } from "../../../../../../../../../custom_utilities/api_services";
import LoaderPopUp from "../../../../../../../../../components/loader/loader";
import "./OtsSubjectWise.scss";

const OtsSubjectWise = (props) => {
  const {
    startLoading,
    finishLoading,
    testPaperResultData,
    token,
    tokenType,
    loader,
    target_id,
  } = props;

  const [state, setState] = useState({
    subjectWiseMarks: {},
  });

  useEffect(() => {
    getSbjctWiseMarks();
  }, []);

  const formatTime = (time) => {
    time = time.replace("minutes", "min");
    time = time.replace("seconds", "sec");
    return time;
  };

  const getSbjctWiseMarks = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testPaperResultData?.PaperData.paper_id,
      test_id: testPaperResultData?.PaperData.testid,
    };
    startLoading();
    try {
      const response = await axios.post(url_130, data, { headers });
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          subjectWiseMarks: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  return (
    <>
      <div className="subjectWiseMarksBox">
        <div className="subjectMarksSmry">
          <div className="marksChart">
            {!isEmpty(state.subjectWiseMarks) ? (
              state.subjectWiseMarks.UserSubjectMarksAnalysis.length ? (
                <SubjectWiseMarkChart
                  dataPoints={state.subjectWiseMarks.UserSubjectMarksAnalysis}
                  target_id={target_id}
                />
              ) : null
            ) : null}
          </div>
          <div className="marksSummary">
            <div class="section_heading">Summary</div>
            <ul>
              {state.subjectWiseMarks.Summary &&
                state.subjectWiseMarks.Summary.map((element, index) => {
                  return (
                    <li key={index}>
                      <span>{element.label}</span>
                      <span>
                        {element.label === "Time Spent"
                          ? formatTime(element.value)
                          : element.label === "Average Score"
                          ? element.value.toFixed(2)
                          : element.value}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,

    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    target_id: state.userPreference?.targetDetails[0].target_id,
  };
};

export default connect(mapStateToProps, null)(OtsSubjectWise);
