import React, { useEffect, useState } from "react";
import VideoJsPlayer from "./VideoPlayer/VideoJsPlayer.component";
import { useSelector } from "react-redux";
import axios from "axios";

export default function VIdeoPlayer({ id_video }) {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dType, setDType] = useState(null);
  const [key, setKey] = useState(null);
  const videoDetailsData = useSelector(
    (state) => state.WatchedVideoDetailsSlice
  );

  const DRM = id_video;

  console.log("DRM", DRM);

  const url = "https://api.videocrypt.com/getVideoDetailsDrm";
  const formData = new FormData();
  formData.append("flag", 1);
  formData.append("name", DRM);

  const getSupportedDRMSystems = async () => {
    if (typeof navigator.requestMediaKeySystemAccess !== "function") {
      return [];
    }

    const drmSystems = [
      "com.apple.fps",
      "com.apple.fps.1_0",
      "com.microsoft.playready",
      "com.widevine.alpha",
    ];
    const supportedDRM = [];

    await Promise.all(
      drmSystems.map((drmSystem) =>
        navigator
          .requestMediaKeySystemAccess(drmSystem, [
            {
              initDataTypes: ["cenc"],
              videoCapabilities: [
                { contentType: 'video/mp4;codecs="avc1.42E01E"' },
              ],
            },
          ])
          .then(() => supportedDRM.push(drmSystem))
          .catch(() => {})
      )
    );

    return supportedDRM;
  };

  const fetchVideoSource = (deviceId, drmKey) => {
    axios
      .post(url, formData, {
        headers: {
          // accessKey: "NUIzQVhZRDFGUkhFOU44UTcwTDI=",
          // secretKey: "NS80OFJ5RUl0dz1CbE5VNmczYVRvTVN4dnVLY1haWW43MkhiVnJlVw==",
          // "user-id": "<== YOUR user-id ==>",
          // "device-type": deviceId,
          // "device-id": "2",
          // version: "2",
          // "device-name": "ChromeCDM",
          // "account-id": "10000903",
          // "Content-Type": "multipart/form-data",

          accessKey: "NUIzQVhZRDFGUkhFOU44UTcwTDI=",
          secretKey: "NS80OFJ5RUl0dz1CbE5VNmczYVRvTVN4dnVLY1haWW43MkhiVnJlVw==",
          "user-id": "TESTing_10000903_97",
          "device-type": deviceId,
          "device-id": "71d3548555586126ed7071102e663619",
          version: "2",
          "device-name": "ChromeCDM",
          "account-id": "10000903",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching video:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const checkDRMSupport = async () => {
      setLoading(true);
      const supportedDRM = await getSupportedDRMSystems();

      if (supportedDRM.includes("com.apple.fps")) {
        setDType(2);
        setKey("com.apple.fps.1_0");
        fetchVideoSource(2, "com.apple.fps.1_0");
      } else if (supportedDRM.includes("com.microsoft.playready")) {
        setDType(3);
        setKey("com.microsoft.playready");
        fetchVideoSource(3, "com.microsoft.playready");
      } else {
        setDType(1);
        setKey("com.widevine.alpha");
        fetchVideoSource(1, "com.widevine.alpha");
      }
    };

    checkDRMSupport();
  }, []);
  useEffect(() => {}, [videoDetailsData?.noteAddedVideoTime]);

  const videoOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    playbackRates: [0.5, 1, 1.5, 2],
    aspectRatio: "21:9",
    controlBar: {
      remainingTimeDisplay: true,
    },
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : source ? (
        <VideoJsPlayer
          source={source}
          keySystem={key}
          options={videoOptions}
          dType={dType}
          notesAddedTime={videoDetailsData?.noteAddedVideoTime ?? 0}
        />
      ) : (
        "No video source available."
      )}
    </>
  );
}
