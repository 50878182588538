// export const BASE_URL = "http://quizmastertesting.motion.ac.in";
// export const BASE_URL ="https://quizmastertesting.motion.ac.in/motioneducation"; //development motion client api url
export const BASE_URL = "https://learning.motion.ac.in/motioneducation"; //production motion client api ur
// export const BASE_URL = "https://learninguat.motion.ac.in/motioneducation"; // testing motion client api url
// export const BASE_URL = "http://devlearning.motion.ac.in/motioneducation";
export const PARENT_BASE_URL = "http://mescray001.com/Tracker/api/v1/index.php";
export const PARENT_STUDENT_ATTENDANCE_URL ="https://mis.mescray001.com/Tracker/api/v1/index.php";

// export const BASE_URL = process.env.REACT_APP_BASE_URL;

