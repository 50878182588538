import React, { useEffect, useState } from "react";
import axios from "axios";
import "./OverallStrongAndImproveTopics.scss";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { url_141 } from "../../../../custom_utilities/api_services";

function OverallStrongAndImproveTopics(props) {
  const { startLoading, finishLoading, subjects } = props;
  const [ImproveTopicList, setImproveTopicList] = useState([]);
  const [StrongTopicList, setStrongTopicList] = useState([]);

  const [subject, setSubject] = useState("Physics"); // For "Areas to Improve"
  const [strongSubject, setStrongSubject] = useState("Physics"); // For "Strong Topic"

  useEffect(() => {
    getImproveTopicList(subject, "Improve");
  }, [subject]);

  useEffect(() => {
    getStrongTopicList(strongSubject, "Strong"); // Fixed typo "Storng" to "Strong"
  }, [strongSubject]);

  const getStrongTopicList = async (subject, type) => {
    startLoading();
    try {
      const data = {
        subject, // Use the parameter 'subject' passed to the function
        type,
      };
      const response = await axios.post(url_141, data);
      if (response.data.status === 200) {
        setStrongTopicList([...response.data.data.StrongTopicList]);
        finishLoading();
      }
    } catch (error) {
      console.log(error.message);
      finishLoading();
    }
  };

  const getImproveTopicList = async (subject, type) => {
    startLoading();
    try {
      const data = {
        subject,
        type,
      };
      console.log("shashi", data); // For debugging
      const response = await axios.post(url_141, data);
      if (response.data.status === 200) {
        finishLoading();
        setImproveTopicList([...response.data.data.ImproveTopicList]);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  return (
    <>
      <div className="sec_1_inner_wrapper">
        <div className="strong_topic_dropdown_box">
          <div className="strong_topic_dropdown">
            <div className="heading-text">Strong Topic </div>
            <div>
              <select
                onChange={(event) => {
                  setStrongSubject(event.target.value); // Update strongSubject
                }}
                value={strongSubject} // Bind to strongSubject
              >
                {subjects?.map((element) => (
                  <option key={element.Subject} value={element.Subject}>
                    {element.Subject}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="strong_topic_lists">
          <ol>
            {StrongTopicList.length > 0 &&
              StrongTopicList.map((element) => (
                <div className="strong_topic_list" key={element.topic}>
                  <li>{element.topic}</li>
                </div>
              ))}
          </ol>
        </div>
      </div>

      <div className="sec_1_inner_wrapper">
        <div className="improve_topic_dropdown_box">
          <div className="improve_topic_dropdown">
            <div className="heading-text">Areas to Improve</div>
            <div>
              <select
                onChange={(event) => {
                  setSubject(event.target.value); // Update subject
                }}
                value={subject} // Bind to subject
              >
                {subjects?.map((element) => (
                  <option key={element.Subject} value={element.Subject}>
                    {element.Subject}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="improve_topic_lists">
          <ol>
            {ImproveTopicList.length > 0 &&
              ImproveTopicList.map((element) => (
                <div className="improve_topic_list" key={element.topic}>
                  <li>{element.topic}</li>
                </div>
              ))}
          </ol>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(OverallStrongAndImproveTopics);
