import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "./routes_data";
import PrivateRoute from "./private";
import PublicRoute from "./public";
import InactivityLogout from "../custom_utilities/custom_logout/InactivityLogout";
import { connect } from "react-redux";
import BlockedtoAccessApp from "../containers/blocking_access_to_app/BlockedtoAccessApp";
import Footer from "../containers/homepage/Footer/Footer";
import All_page_content from "../containers/homepage/All_page_tabing/All_page_content";
import { Store } from "../containers/homepage/Store/Store";
import PackagesOverview from "../containers/homepage/Store/PackagesOverview/PackagesOverview";

const basename = "/";

const Routes = ({ isBlocked, remark, isLoginOpen, setIsLoginOpen }) => {
  return (
    <>
      <Router basename={basename}>
        <InactivityLogout />
        <Switch>
          {routes.map((route, i) => {
            const RouteComponent = route.auth ? PrivateRoute : PublicRoute;
            return (
              <RouteComponent
                key={i}
                path={route.path}
                exact={route.exact}
                component={route.component}
                isBlocked={isBlocked}
                remark={remark}
              />
            );
          })}

          {isBlocked && remark && (
            <Route path="/block" component={BlockedtoAccessApp} />
          )}
          <Route path="/aboutUs">
            <All_page_content tab="AboutUs" />
          </Route>
          <Route path="/privacyPolicy">
            <All_page_content tab="privacyPolicy" />
          </Route>
          <Route path="/terms">
            <All_page_content tab="terms" />
          </Route>
          <Route
            path="/store"
            render={(props) => (
              <Store
                {...props}
                setIsLoginOpen={setIsLoginOpen}
                isLoginOpen={isLoginOpen}
              />
            )}
          />
          <Route
            path="/login-store/:target_id/:class_id"
            render={(props) => (
              <Store
                {...props}
                setIsLoginOpen={setIsLoginOpen}
                isLoginOpen={isLoginOpen}
              />
            )}
          />
          <Route
            path="/packages-overview/:package_id"
            component={PackagesOverview}
          />
          <Route path="/" exact />
        </Switch>
      </Router>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isBlocked: state.BlockUserSlice.isBlocked,
    remark: state.BlockUserSlice.remark,
  };
};

export default connect(mapStateToProps, null)(Routes);
