import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { ZoomMtg } from "@zoom/meetingsdk";
import CryptoJS from "crypto-js";
import "./zoom_component.scss";

const Zoom = ({ userData, liveStreamData }) => {
  const [zoomState, setZoomState] = useState({
    isInitialized: false,
    isJoining: false,
    error: null,
  });

  const setError = (message) =>
    setZoomState((prev) => ({ ...prev, error: message }));

  const decrypt = (value) => {
    try {
      if (!value) throw new Error("Empty value passed to decrypt");
      const encrypted = JSON.parse(atob(value));
      const key = CryptoJS.enc.Base64.parse(
        "0CiuKpLCZbX7gBCyYTka4USxTnh7ssXKk8bJ6Y3TXPc="
      );
      const decrypted = CryptoJS.AES.decrypt(encrypted.value, key, {
        iv: CryptoJS.enc.Base64.parse(encrypted.iv),
      });
      return decrypted.toString(CryptoJS.enc.Utf8).split("*")[1];
    } catch (err) {
      console.error("Decryption error:", err);
      return null;
    }
  };

  const generateSignature = (meetingNumber, role, sdkKey, sdkSecret) => {
    try {
      const iat = Math.floor(Date.now() / 1000) - 30;
      const exp = iat + 60 * 60 * 2;
      const payload = {
        sdkKey,
        mn: meetingNumber,
        role,
        iat,
        exp,
        appKey: sdkKey,
        tokenExp: exp,
      };
      const encodedHeader = btoa(JSON.stringify({ alg: "HS256", typ: "JWT" }));
      const encodedPayload = btoa(JSON.stringify(payload));
      const signature = CryptoJS.HmacSHA256(
        `${encodedHeader}.${encodedPayload}`,
        sdkSecret
      )
        .toString(CryptoJS.enc.Base64)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");

      return `${encodedHeader}.${encodedPayload}.${signature}`;
    } catch (err) {
      console.error("Signature generation error:", err);
      return null;
    }
  };

  const handleJoinMeeting = useCallback(async () => {
    if (!zoomState.isInitialized || zoomState.isJoining) return;
    setZoomState((prev) => ({ ...prev, isJoining: true }));

    try {
      const meetingConfig = {
        meetingNumber: decrypt(liveStreamData.zoom_id),
        password: decrypt(liveStreamData.password),
        sdkKey: decrypt(liveStreamData.sdk_key),
        sdkSecret: decrypt(liveStreamData.sdk_secret_key),
        userEmail: decrypt(liveStreamData.zoom_email),
      };

      if (
        !meetingConfig.meetingNumber ||
        !meetingConfig.sdkKey ||
        !meetingConfig.sdkSecret
      ) {
        throw new Error("Missing meeting configuration.");
      }

      const signature = generateSignature(
        meetingConfig.meetingNumber,
        0,
        meetingConfig.sdkKey,
        meetingConfig.sdkSecret
      );
      const userName = `${userData.roll_no || "Not Roll"}-${
        userData.name || "Not Registered"
      }`;

      ZoomMtg.join({
        meetingNumber: meetingConfig.meetingNumber,
        signature,
        sdkKey: meetingConfig.sdkKey,
        userName,
        userEmail: meetingConfig.userEmail,
        passWord: meetingConfig.password,
        success: () => {
          setZoomState((prev) => ({ ...prev, isJoining: false, error: null }));

          document.querySelector(".meeting-info-container")?.remove();
          document.querySelector(".meeting-title-container")?.remove();

          ZoomMtg.inMeetingServiceListener("onChatMessageReceived", (data) =>
            console.log("Chat Message Received:", data)
          );
          ZoomMtg.getAttendeeslist({});
          ZoomMtg.getCurrentUser({});
          document.getElementById("meetingSDKElement").style.display = "block";
        },
        error: (err) => {
          console.error("Failed to join the meeting:", err);
          setZoomState((prev) => ({
            ...prev,
            isJoining: false,
            error: "Failed to join the meeting. Please try again.",
          }));
        },
      });
    } catch (err) {
      console.error("Error during meeting join:", err);
      setZoomState((prev) => ({
        ...prev,
        isJoining: false,
        error: err.message,
      }));
    }
  }, [zoomState.isInitialized, zoomState.isJoining, liveStreamData, userData]);

  useEffect(() => {
    const initializeZoom = async () => {
      try {
        ZoomMtg.setZoomJSLib("https://source.zoom.us/3.11.2/lib", "/av");
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();

        await ZoomMtg.init({
          leaveUrl: "https://learning.motion.ac.in/user_dashboard",
          isSupportAV: true,
          disablePreview: false,
          disableJoinAudio: false,
          webEndpoint: "zoom.us",
          isSupportChat: true,
          success: () =>
            setZoomState((prev) => ({ ...prev, isInitialized: true })),
          error: (err) => setError("Failed to initialize Zoom."),
        });
      } catch (err) {
        setError("Failed to initialize Zoom.");
      }
    };

    initializeZoom();

    return () => {
      try {
        ZoomMtg.leaveMeeting({});
        document.body.style.overflow = "auto"; // Restore scrolling
      } catch (err) {
        console.error("Error during cleanup:", err);
      }
    };
  }, []);

  useEffect(() => {
    if (zoomState.isInitialized && liveStreamData && !zoomState.isJoining) {
      handleJoinMeeting();
    }
  }, [
    zoomState.isInitialized,
    liveStreamData,
    zoomState.isJoining,
    handleJoinMeeting,
  ]);

  return (
    <>
      {zoomState.error && <div className="error-toast">{zoomState.error}</div>}
      <div id="meetingSDKElement" className="zoom-container">
        <div id="zmmtg-root"></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  liveStreamData: state.userActivity.liveStreamData,
  userData: state.auth.user,
});

export default connect(mapStateToProps)(Zoom);
