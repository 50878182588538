import React, { useState, useRef, useEffect } from "react";
import { Overlay, Popover } from "react-bootstrap";
import images from "../../../../../../utilities/images/images";
import "./SortPopover.scss";

const SortPopover = ({ inputValue, setInputValue }) => {
  const [showPopover, setShowPopover] = useState(false);
  const targetRef = useRef(null);
  const popoverRef = useRef(null); 

  const handleRadioChange = (event) => {
    setInputValue(event.target.value);
    setShowPopover(false);
  };

 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        targetRef.current &&
        !targetRef.current.contains(event.target)
      ) {
        setShowPopover(false);
      }
    };

    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover]);

  return (
    <div ref={popoverRef}>
      <span
        ref={targetRef}
        style={{ cursor: "pointer" }}
        onClick={() => setShowPopover(!showPopover)}
      >
        <img src={images.sorIcon} className="hwmk_sort_icon" alt="sort" />
        Sort By
        <img
          src={images.dropDownIcon}
          className="hwmk_sort_dropdown"
          alt="dropdown"
        />
      </span>
      <Overlay target={targetRef.current} show={showPopover} placement="bottom">
        <Popover id="popover-basic">
          <Popover.Body>
            <div className="sort_input">
              <input
                type="radio"
                id="newest"
                name="sortOrder"
                value="newest"
                onChange={handleRadioChange}
                checked={inputValue === "newest"}
              />
              <label htmlFor="newest">Newest First</label>
            </div>
            <div className="sort_input">
              <input
                type="radio"
                id="oldest"
                name="sortOrder"
                value="oldest"
                onChange={handleRadioChange}
                checked={inputValue === "oldest"}
              />
              <label htmlFor="oldest">Oldest First</label>
            </div>
            <div className="sort_input">
              <input
                type="radio"
                id="unattempted"
                name="sortOrder"
                value="unattempted"
                onChange={handleRadioChange}
                checked={inputValue === "unattempted"}
              />
              <label htmlFor="unattempted">Unattempted</label>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default SortPopover;
