import { useEffect, useState } from "react";
import "./SubjectWiseDoughnut.scss";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const SubjectWiseDoughnut = (props) => {
  let { dataPoints } = props;
  const [subjectType, setSubjectType] = useState(
    dataPoints[0]?.type || "Time Spent"
  );
  const [array, setArray] = useState([]);

  // Recalculate the filtered data whenever subjectType changes
  useEffect(() => {
    filterTypeWise();
  }, [subjectType]);

  // Filter dataPoints based on the selected subjectType
  const filterTypeWise = () => {
    const filtered = dataPoints?.filter(
      (element) => element.type === subjectType
    );
    setArray(filtered);
  };

  // Calculate the total value by summing only the numeric values that are greater than zero
  const getTotalValue = () => {
    if (array.length > 0) {
      const subjectValues = Object.keys(array[0])
        .filter((key) =>
          ["Biology", "Chemistry", "Maths", "Physics"].includes(key)
        )
        .map((key) => parseFloat(array[0][key]) || 0);
      const total = subjectValues.reduce((acc, value) => acc + value, 0);
      return total;
    }
    return 0;
  };

  // Calculate the percentage for each subject based on the total
  const calculatePercentage = (value) => {
    const total = getTotalValue();
    return total > 0 ? (value / total) * 100 : 0;
  };

  // Prepare the data for the doughnut chart
  const data = () => {
    const total = getTotalValue();

    // Ensure only subjects with a non-zero value are included
    const subjects = [];
    if (array[0]?.Biology > 0) {
      subjects.push({
        name: "Biology",
        value: array[0]?.Biology,
        color: "#4EE3A4",
        percentage: calculatePercentage(array[0]?.Biology),
      });
    }
    if (array[0]?.Chemistry > 0) {
      subjects.push({
        name: "Chemistry",
        value: array[0]?.Chemistry,
        color: "#FFAFCA",
        percentage: calculatePercentage(array[0]?.Chemistry),
      });
    }
    if (array[0]?.Maths > 0) {
      subjects.push({
        name: "Maths",
        value: array[0]?.Maths,
        color: "#819CFF",
        percentage: calculatePercentage(array[0]?.Maths),
      });
    }
    if (array[0]?.Physics > 0) {
      subjects.push({
        name: "Physics",
        value: array[0]?.Physics,
        color: "#FFC557",
        percentage: calculatePercentage(array[0]?.Physics),
      });
    }

    return subjects;
  };

  const handleChange = (event) => {
    setSubjectType(event.target.value);
  };

  const chartData = {
    labels: data().map((item) => item.name),
    datasets: [
      {
        data: data().map((item) =>
          subjectType === "Time Spent"
            ? Math.floor(item.value / 60)
            : item.value
        ),
        backgroundColor: data().map((item) => item.color),
        hoverBackgroundColor: data().map((item) => item.color),
      },
    ],
  };

  const options = {
    cutout: "80%",
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 50,
        bottom: 50,
        left: 50,
        right: 50,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        enabled: true,
      },
      doughnut: {
        spacing: 0, // Set spacing to 0 to remove the gap
      },
      datalabels: {
        display: true,
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          const percentage = data()[context.dataIndex]?.percentage.toFixed(2); // Get the percentage dynamically
          return `${label}\n${percentage}%`; // Display percentage dynamically
        },
        color: "#000",
        borderWidth: 0,
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 5,
        padding: 6,
        align: "end",
        anchor: "end",
        font: {
          size: 12,
        },
      },
    },
  };

  return (
    <div className="sec_1_inner_wrapper">
      <div className="heading-text">Subject Wise</div>
      <div className="type_collection_wrapper">
        {!isEmpty(dataPoints)
          ? dataPoints.length
            ? dataPoints.map((element, index) => {
                return (
                  <div key={index} className="single_select_type">
                    <input
                      type="radio"
                      id={element.type}
                      value={element.type}
                      checked={element.type === subjectType}
                      onChange={handleChange}
                    />
                    <label htmlFor={element.type}>{element.type}</label>
                  </div>
                );
              })
            : null
          : null}
      </div>

      <div className="Doughnut-chart-container">
        <Doughnut data={chartData} options={options} />
      </div>
    </div>
  );
};

export default SubjectWiseDoughnut;
