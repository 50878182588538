import { Fragment, useState, useEffect } from "react";
import OtsTestInstructionsView from "./OtsTestInstructionsView";
import OtsTestQuestionsView from "./OtsTestQuestionsView";
import "./OtsTestContentComponent.scss";

import {
  url_39,
  url_76,
  url_77,
  url_123,
  url_124,
  url_125,
  url_126,
  url_144,
  url_209,
  url_174,
  url_175,
} from "../../../../../../../custom_utilities/api_services";
import axios from "axios";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import RewardModal from "../../../../../../../components/RewardModal/RewardModal";
import CoinUsesModal from "../../../../../../../components/CoinUsesModal/CoinUsesModal";

//Redux
import { connect } from "react-redux";

import actions from "../../../../../../../redux/ui/action";
import actions2 from "../../../../../../../redux/online-test_series/action";
import { toastifyMessage } from "../../../../../../../custom_utilities/toastify_config";

const OtsTestContentComponent = (props) => {
  let {
    history,
    testData,
    userPreference,
    startLoading,
    finishLoading,
    userData,
    loader,
    token,
    tokenType,
  } = props;

  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testSectionsDetails: {},
    testQuestionsDetails: [],
    currentQuestionDetails: {},
    subjecSectiontWiseTestQuestions: {},
    // for resume/starting from there - when paused and come again
    lastAttempteQuestionQid: "",
    // checkQuestion: true,
    currentQuestion: 0,
    countDownTimer: {
      hours: 3,
      minutes: 0,
      seconds: 0,
    },
    initialTime: 0,
    useTime: 0,
    option: "",
    oldanswer: "",
    lang: true, // lang --> true = english , false = hindi
    earnedCoins: 0,
    coinPopup: false,
    reason: "RewardModal",
    coinDetailsPopup: false,
    coinUsesPoints: [],
    textResultData: {},
  });

  const [selectedSubject, setSubject] = useState("");
  const [selectedSection, setSection] = useState("");
  const [disableInput, setDisableInput] = useState(false);

  // const config = {
  //   loader: {
  //     load: ["input/mathml", "input/tex", "input/asciimath"],
  //   },
  // };

  useEffect(() => {
    if (state.testQuestionsDetails.length) {
      setSubject(state.testQuestionsDetails[state.currentQuestion]["subject"]);
      setSection(
        state.testQuestionsDetails[state.currentQuestion]["section_name"]
      );
    }
  }, [state.currentQuestion, state.testQuestionsDetails]);

  useEffect(() => {
    getTestDetails();
    coinUsesApi();
  }, []);

  useEffect(() => {
    if (state.step === 2) {
      createCountDownTimer(testData.test_duration_min);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [state.step]);

  let formatData =
    state.testDetails.target_type == "JEE (M)"
      ? "MAIN"
      : state.testDetails?.target_type == "NEET"
      ? "NEET"
      : "OTHER";

  let timeInterval;
  const createCountDownTimer = (duration) => {
    let useTime = state.useTime ?? 0;
    useTime = useTime * 1000;

    let countdownTime = duration * 60 * 1000 + new Date().getTime();
    countdownTime = countdownTime - useTime;

    timeInterval = setInterval(() => {
      let now = new Date().getTime();
      let distance = countdownTime - now;
      // if (distance < 0) {
      //   alert("Time is up. Your test will be auto submitted");
      //   handleSubmitTest(state.currentQuestionDetails);
      //   handleLastQuestionSave();
      //   clearInterval(timeInterval);
      // }
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
      );

      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setState((prevState) => ({
        ...prevState,
        countDownTimer: {
          ...prevState.countDownTimer,
          hours,
          minutes,
          seconds,
        },
      }));
    }, 1000);
  };

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: testData }));
  };

  const getTestQuestionsList = async (testDetails) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: testDetails.paper_id,
      test_id: testDetails.test_id,
    };

    startLoading();

    try {
      const response = await axios.post(url_123, data, { headers });

      if (response.data.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };

  const getCurrentQuestionDetails = async (
    paper_id,
    qid,
    subject,
    section,
    format
  ) => {
    const { testDetails } = state;
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      paper_id,
      qid,
      subject,
      section,
      format,
      test_id: testDetails.test_id,
    };
    startLoading();

    try {
      const response = await axios.post(url_124, data, { headers });

      if (response.data.status === 200) {
        finishLoading();

        setState((prevState) => ({
          ...prevState,
          currentQuestionDetails: response.data.data[0],
          oldanswer: response.data.data[0].oldanswer,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.currentQuestionDetails.attempt_count == 0) {
      changeDisableInput();
    }
  }, [state.currentQuestionDetails]);

  const changeDisableInput = () => {
    setDisableInput(false);
  };

  const subjectWiseData = (questions) => {
    const subjectSectionWiseData = {};
    for (let i = 0; i < questions?.length; i++) {
      questions[i]["matchIndex"] = i;
      if (!subjectSectionWiseData[questions[i]["subject"]]) {
        subjectSectionWiseData[questions[i]["subject"]] = {
          [questions[i]["section_name"]]: [questions[i]],
        };
      } else if (
        subjectSectionWiseData[questions[i]["subject"]] &&
        !subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ]
      ) {
        subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ] = [questions[i]];
      } else {
        subjectSectionWiseData[questions[i]["subject"]][
          questions[i]["section_name"]
        ].push(questions[i]);
      }
    }
    return subjectSectionWiseData;
  };

  const getNowTime = () => {
    let d = new Date();
    return d.getTime();
  };
  //exitTimeCurrentOption
  const startBtn = async () => {
    let testSectionsDetails = { ...state.testSectionsDetails };
    let testDetails = { ...state.testDetails };

    if (!isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList(testDetails);
      const _subjecSectiontWiseTestQuestions = subjectWiseData(
        returnedResponse.data.apiData.data
      );

      const response = await checkIsResumeTest(returnedResponse.data.apiData);

      if (returnedResponse.result) {
        getCurrentQuestionDetails(
          returnedResponse?.data?.apiData?.data[response]?.paper_id,
          returnedResponse?.data?.apiData?.data[response]?.qid,
          returnedResponse?.data?.apiData?.data[response]?.subject,
          returnedResponse?.data?.apiData?.data[response]?.section_name,
          formatData
        );

        returnedResponse.data.apiData.data.forEach((element) => {
          element.isReview = false;
          // element.isSkipped = false;
          element.isAttempted = false;
          element.isAnswerGuessed = false;
          element.totalTimeTaken = 0;
          element.isAnswerCorrect = 0;
          element.correctAnswerIdCollection = [];
          element.wrongAnswerIdCollection = [];
          element.userAnswerCollection = [];
          // element.q_text = element.q_text.replaceAll(
          //   "ckfinder",
          //   "http://quizmaster.motion.ac.in/ckfinder"
          // );
          element.q_text = element.q_text
            .replaceAll(
              'src="ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'src="/ckfinder',
              'src="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="/ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC="./ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            )
            .replaceAll(
              'SRC= "ckfinder',
              'SRC="http://quizmaster.motion.ac.in/ckfinder'
            );
          element.option?.forEach((opt) => {
            opt.option = opt.option
              .replaceAll(
                'src="ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'src="/ckfinder',
                'src="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="/ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC="./ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              )
              .replaceAll(
                'SRC= "ckfinder',
                'SRC="http://quizmaster.motion.ac.in/ckfinder'
              );
          });
        });
        let time = getNowTime();
        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails: returnedResponse.data.apiData.data,
          subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
          initialTime: time,
          useTime: returnedResponse.data.apiData.get_used_duration,
          currentQuestion: response,
        }));

        scrollUp(response);
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const checkIsResumeTest = async (test) => {
    if (test.last_attempted_qid !== 0) {
      const indexOfLastAttemptedQue = test.data.findIndex(
        (element) => element.qid == test.last_attempted_qid
      );

      if (indexOfLastAttemptedQue == test.data.length - 1) {
        return indexOfLastAttemptedQue;
      }

      return indexOfLastAttemptedQue + 1;
    } else {
      return 0;
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };

  const handlePreviousQuestionBtn = (cq) => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    if (testQuestionsDetails[currentQuestion].isAttempted) {
      testQuestionsDetails[currentQuestion].isSkipped = false;
    } else {
      testQuestionsDetails[currentQuestion].isSkipped = true;
    }

    // if (state.oldanswer.length > 0) {
    //   testQuestionsDetails[currentQuestion].isAttempted = true;
    //   testQuestionsDetails[currentQuestion].isSkipped = false;
    // }

    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    let time = getNowTime();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        initialTime: time,
      }));

      // handleSaveQuestion(cq);
      getCurrentQuestionDetails(
        testQuestionsDetails[cq].paper_id,
        testQuestionsDetails[cq].qid,
        testQuestionsDetails[cq].subject,
        testQuestionsDetails[cq].section_name,
        formatData
      );

      window.scrollTo(0, 0);
    }
  };

  const handleSubjectChange = (subject) => {
    const section = Object.keys(
      state.subjecSectiontWiseTestQuestions[subject]
    )[0];
    setSubject(subject);
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[subject][section][0]["matchIndex"];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));

    const { testQuestionsDetails } = state;
    let cq = currentQuestion;
    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid,
      testQuestionsDetails[cq].subject,
      testQuestionsDetails[cq].section_name,
      formatData
    );

    scrollUp(currentQuestion);
  };
  const handleSectionChange = (section) => {
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[selectedSubject][section][0][
        "matchIndex"
      ];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));

    const { testQuestionsDetails } = state;
    let cq = currentQuestion;
    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid,
      testQuestionsDetails[cq].subject,
      testQuestionsDetails[cq].section_name,
      formatData
    );

    scrollUp(currentQuestion);
  };

  // const scrollUp = (currentQuestion) => {
  //   var element = document.getElementsByClassName(
  //     "questions_serial_number_collection"
  //   )[0];
  //   element.scrollTo({
  //     top: `${(currentQuestion + 1) * 80}`,
  //     behavior: "smooth",
  //   });
  // };

  const scrollUp = (currentQuestion) => {
    const questionElements = document.getElementsByClassName(
      "single_question_number_wrapper"
    );
    const spaceAtTop = 30; // Adjust the value as needed

    if (
      questionElements.length > 0 &&
      currentQuestion > 0 &&
      currentQuestion < questionElements.length
    ) {
      const targetElement = questionElements[currentQuestion - 1];
      const offsetTop = targetElement.offsetTop - spaceAtTop;
      const containerElement = document.getElementsByClassName(
        "questions_serial_number_collection"
      )[0];
      containerElement.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    } else if (questionElements.length > 0 && currentQuestion === 0) {
      const containerElement = document.getElementsByClassName(
        "questions_serial_number_collection"
      )[0];
      containerElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleLastQuestionSave = async () => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      testDetails,
      oldanswer,
      // currentQuestionDetails,
    } = state;

    let calculatedTime = getCalculatedTime(initialTime);

    let crntQuestionDetail = testQuestionsDetails[currentQuestion];

    // let formatData =
    //   state?.testDetails?.format ===
    //   "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
    //     ? "MAIN"
    //     : state?.testDetails?.target === "NEET"
    //     ? "NEET"
    //       : "OTHER";

    let formatData =
      state.testDetails.target_type == "JEE (M)"
        ? "MAIN"
        : state.testDetails?.target_type == "NEET"
        ? "NEET"
        : "OTHER";

    let data = {};
    if (crntQuestionDetail.answer_type == "Integer Correct") {
      data = {
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_int: oldanswer?.length ? oldanswer : "",

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: formatData,
        section: crntQuestionDetail.section_name,

        test_id: testDetails.test_id,
      };
    } else {
      data = {
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_id: crntQuestionDetail.userAnswerCollection.length
          ? crntQuestionDetail.userAnswerCollection
          : state.oldanswer.length
          ? [state.oldanswer]
          : [],

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: formatData,
        section: crntQuestionDetail.section_name,

        test_id: testDetails.test_id,
      };
    }

    startLoading();

    const response = await axios.post(url_125, data);

    if (response.data.status === 200) {
      finishLoading();
    } else {
      finishLoading();
    }
  };

  //save prticular question
  const handleSaveQuestion = async (cq, nextsave) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      testDetails,
      oldanswer,
      currentQuestionDetails,
      // currentQuestionDetails,
    } = state;

    let calculatedTime = getCalculatedTime(initialTime);

    let crntQuestionDetail = testQuestionsDetails[currentQuestion];

    // let formatData =
    //   state?.testDetails?.format ===
    //   "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
    //     ? "MAIN"
    //     : state?.testDetails?.target === "NEET"
    //     ? "NEET"
    //     : "OTHER";

    let formatData =
      state.testDetails.target_type == "JEE (M)"
        ? "MAIN"
        : state.testDetails?.target_type == "NEET"
        ? "NEET"
        : "OTHER";

    let data = {};
    if (crntQuestionDetail.answer_type == "Integer Correct") {
      data = {
        negative_marks: parseInt(crntQuestionDetail.negative_marks),
        option_int: oldanswer?.length ? oldanswer : "",
        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,
        format: formatData,
        section: crntQuestionDetail.section_name,
        test_id: testDetails.test_id,
        lang_id: state.lang ? 1 : 2,
      };
    } else {
      data = {
        negative_marks: parseInt(crntQuestionDetail.negative_marks),

        option_id: crntQuestionDetail.userAnswerCollection.length
          ? crntQuestionDetail.userAnswerCollection
          : oldanswer.length
          ? [oldanswer]
          : [],

        paper_id: crntQuestionDetail.paper_id,
        partial_marks: parseInt(crntQuestionDetail.partial_marks),
        positive_marks: parseInt(crntQuestionDetail.positive_marks),
        qid: crntQuestionDetail.qid,
        subject: crntQuestionDetail.subject,
        time: calculatedTime ? calculatedTime : 0,
        topic_name: crntQuestionDetail.topic_name,
        toughness: crntQuestionDetail.toughness,

        format: formatData,
        section: crntQuestionDetail.section_name,

        test_id: testDetails.test_id,
        lang_id: state.lang ? 1 : 2,
      };
    }

    startLoading();

    if (nextsave) {
      const response = await axios.post(url_125, data);
      if (response.data.status === 200) {
        getCurrentQuestionDetails(
          testQuestionsDetails[cq].paper_id,
          testQuestionsDetails[cq].qid,
          testQuestionsDetails[cq].subject,
          testQuestionsDetails[cq].section_name,
          formatData
        );
      } else {
        alert("Your Previous Question hasn't been Saved!");
        getCurrentQuestionDetails(
          testQuestionsDetails[cq].paper_id,
          testQuestionsDetails[cq].qid,
          testQuestionsDetails[cq].subject,
          testQuestionsDetails[cq].section_name,
          formatData
        );
      }
    } else {
      getCurrentQuestionDetails(
        testQuestionsDetails[cq].paper_id,
        testQuestionsDetails[cq].qid,
        testQuestionsDetails[cq].subject,
        testQuestionsDetails[cq].section_name,
        formatData
      );
    }
  };

  const handleNextQuestionBtn = (
    cq,
    currentQuestionDetails,
    nextsave = true
  ) => {
    let { currentQuestion, initialTime, testQuestionsDetails } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length > 0) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].isSkipped = false;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].isSkipped = true;
    }

    let type = currentQuestionDetails?.answer_type?.toLowerCase();

    if (type === "integer correct") {
      if (state.oldanswer?.length > 0) {
        testQuestionsDetails[currentQuestion].isAttempted = true;
        testQuestionsDetails[currentQuestion].isSkipped = false;
      } else {
        testQuestionsDetails[currentQuestion].isAttempted = false;
        testQuestionsDetails[currentQuestion].isSkipped = true;
      }
    }

    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));

    handleSaveQuestion(cq, nextsave);

    window.scrollTo(0, 0);
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked, value } = event.target;

    let { currentQuestion, testQuestionsDetails } = state;

    if (checked) {
      // testQuestionsDetails[currentQuestion].isAttempted = true;

      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = optionId;

      testQuestionsDetails[currentQuestion][0] = optionId;

      testQuestionsDetails[currentQuestion].is_option = 1;

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      // testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      testQuestionsDetails[currentQuestion].is_option = 2;
    }

    setState((prevState) => ({
      ...prevState,
      testQuestionsDetails,
      oldanswer: "",
    }));
  };

  const handleMultiSelectAnswer = (_event, optionId, checked) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element === optionId;
    });

    if (findedIndex === -1) {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        optionId
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    // if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
    //   testQuestionsDetails[currentQuestion].isAttempted = true;
    // } else {
    //   testQuestionsDetails[currentQuestion].isAttempted = false;
    // }

    // if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
    //   testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    // }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    value.replace(/[^-\d._e]/g, "");
    let {
      currentQuestion,
      testQuestionsDetails,
      checkQuestion,
      currentQuestionDetails,
    } = state;
    // testQuestionsDetails[currentQuestion].integer_answer = value.trim();
    currentQuestionDetails.oldanswer = value.trim();

    // if (currentQuestionDetails.oldanswer.length) {
    //   testQuestionsDetails[currentQuestion].isAttempted = true;
    // } else {
    //   testQuestionsDetails[currentQuestion].isAttempted = false;
    // }

    setState((prevState) => ({
      ...prevState,
      ...prevState.countDownTimer,
      testQuestionsDetails,
      oldanswer: value,
    }));
  };

  const reviewQuestion = async (currentQuestion) => {
    const { paper_id, qid, isAnswerGuessed } = currentQuestion;
    let data = {
      paper_id: paper_id,
      reference_id: qid,
      status: isAnswerGuessed ? 1 : 0,
      type: 3,
    };
    const response = await axios.post(url_77, data);
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].isAnswerGuessed =
      !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    reviewQuestion(testQuestionsDetails[currentQuestion]);
  };

  const handleSaved = () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;

    testQuestionsDetails[currentQuestion].is_saved =
      !testQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    if (testQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      bookmarkApi(data);
    }
  };

  const bookmarkApi = async (data) => {
    try {
      const response = await axios.post(url_39, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGuessedAnswer = (event) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed = checked;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    const {
      testDetails,
      currentQuestion,
      currentQuestionDetails,
      testQuestionsDetails,
    } = state;

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length > 0) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].isSkipped = false;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].isSkipped = true;
    }

    let type = currentQuestionDetails?.answer_type?.toLowerCase();

    if (type === "integer correct") {
      if (state.oldanswer?.length > 0) {
        testQuestionsDetails[currentQuestion].isAttempted = true;
        testQuestionsDetails[currentQuestion].isSkipped = false;
      } else {
        testQuestionsDetails[currentQuestion].isAttempted = false;
        testQuestionsDetails[currentQuestion].isSkipped = true;
      }
    }

    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
    }));

    // const { testQuestionsDetails } = state;

    // if (!testQuestionsDetails[state.currentQuestion].isAttempted) {
    //   testQuestionsDetails[state.currentQuestion].isSkipped = true;
    // }

    getCurrentQuestionDetails(
      testQuestionsDetails[questionNumber].paper_id,
      testQuestionsDetails[questionNumber].qid,
      testQuestionsDetails[questionNumber].subject,
      testQuestionsDetails[questionNumber].section_name,
      formatData
    );
  };

  // const getNewAnalyticsApi = async (testDetails) => {
  //   const { paper_id, test_id } = testDetails;
  //   const data = {
  //     paper_id,
  //     test_id,
  //   };

  //   const response = await axios.post(url_144, data);
  //   if (response.data.status === 200) {
  //     return 1;
  //   } else return 0;
  // };

  // const getNewAnalyticsApi = async (testDetails) => {
  //   const { paper_id, test_id } = testDetails;
  //   const data = {
  //     paper_id,
  //     test_id,
  //   };

  //   const response = await axios.post(url_144, data);
  //   if (response.data.status === 200) {
  //     return 1;
  //   } else return 0;
  // };

  const handleSubmitTest = async (currentQuestionDetails) => {
    let { testDetails, testQuestionsDetails } = state;

    let element = currentQuestionDetails;

    let questionArr = [];

    if (element?.answer_type?.toLowerCase() === "single correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    } else if (element?.answer_type?.toLowerCase() === "multiple correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    } else if (element?.answer_type?.toLowerCase() === "integer correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    }

    // startLoading();
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );
    if (returnedResponse?.result) {
      getNewAnalyticsApi(testDetails);
      // finishLoading();

      // if (response) {
      //   history.push({
      //     pathname: "/ots_summary",
      //   });
      // }
    } else {
      // finishLoading();
      // alert(returnedResponse?.data?.message);
    }
  };
  const submitTestApi = async (_userPreference, testDetails, _questionArr) => {
    let requestPayload = {
      paper_id: testDetails?.paper_id,
      time: state?.countDownTimer?.minutes,
      test_id: testDetails?.test_id,
    };

    startLoading();

    try {
      const response = await axios.post(url_126, requestPayload);
      if (response?.data?.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: {
            message: response?.data?.message,
          },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: {
            message: response?.data?.message,
          },
        };
      }
    } catch (error) {
      finishLoading();

      // Check if the error status is 400 before showing the toast message
      if (error?.response?.status === 400) {
        toastifyMessage({
          status: 400,
          message: "You must attempt at least 1 question.",
        });
      }

      return null;
    }
  };

  const handleChangeLanguage = () => {
    setState((prevState) => ({
      ...prevState,
      lang: !prevState.lang,
    }));
  };

  const getNewAnalyticsApi = async (testDetails) => {
    const { paper_id, test_id } = testDetails;
    const data = {
      paper_id,
      test_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_144, data);
      if (response.data.status === 200) {
        setState((prev) => {
          return {
            ...prev,
            textResultData: response.data.data1,
          };
        });
        console.log("resultData", response.data.data);
        addCoinsOnSubmission(
          response.data.data1.correct_answer,
          response.data.data1.incorrect_answer
        );
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };
  const addCoinsOnSubmission = async (correct_ans, wrong_ans) => {
    const data = {
      type: "Attending_OTS",
      wrong_ans: wrong_ans,
      correct_ans: correct_ans,
      paper_type: "OTS",
      paper_id: state.testDetails.paper_id,
    };
    try {
      const response = await axios.post(url_174, data);
      console.log("response.data.coints", response.data);
      if (response.data.status === 200) {
        if (response.data.data !== "MCoins Already Credited...!") {
          console.log(
            "------------------------------------",
            response.data.data.coins
          );
          setState((prev) => {
            return {
              ...prev,
              earnedCoins: response.data.data.coins,
              coinPopup: response.data.data.coins > 0 ? true : false,
              reason: response.data.data.description,
            };
          });
          if (response.data.data.coins === 0) {
            console.log(
              "going inside of if response.data.data.coins === 0",
              response.data.data.coins
            );
            console.log("coming inside of coins ===== 0");
            history.push({
              pathname: "/ots_summary",
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const coinUsesApi = async () => {
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        console.log(response.data);
        setState((prevState) => {
          return {
            ...prevState,
            coinUsesPoints: [...response.data.data],
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <OtsTestInstructionsView
            testDetails={state.testDetails}
            testSectionsDetails={state.testSectionsDetails}
            startBtn={startBtn}
          />
        );
      case 2:
        return (
          <OtsTestQuestionsView
            testQuestionsDetails={state.testQuestionsDetails}
            currentQuestionDetails={state.currentQuestionDetails}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            selectedSubject={selectedSubject}
            selectedSection={selectedSection}
            handleSubjectChange={handleSubjectChange}
            handleSectionChange={handleSectionChange}
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleReview={handleReview}
            handleSaved={handleSaved}
            handleGuessedAnswer={handleGuessedAnswer}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={handleSubmitTest}
            // checkQuestion={state.checkQuestion}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
            examFormat={state?.testDetails?.format}
            disableInput={disableInput}
            setDisableInput={setDisableInput}
            handleLastQuestionSave={handleLastQuestionSave}
            testDetails={state?.testDetails}
            oldanswer={state.oldanswer}
            lang={state.lang}
            handleChangeLanguage={handleChangeLanguage}
            setState={setState}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <RewardModal
        show={state.coinPopup}
        onHide={() => {
          history.push({ pathname: "/ots_summary" });
        }}
        earnMoreHandler={() =>
          setState((prev) => {
            return { ...prev, coinDetailsPopup: true, coinPopup: false };
          })
        }
        earnedCoins={state.earnedCoins}
        reason={state.reason}
      />
      <CoinUsesModal
        show={state.coinDetailsPopup}
        onHide={() => {
          history.push({ pathname: "/ots_summary" });
        }}
        data={state.coinUsesPoints}
      />
      <div className="Ots_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
        {loader && <LoaderPopUp />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.otsTestsData.otsTestsData,
    userPreference: state.userPreference,
    userData: state.auth.user,
    loader: state.ui.loader,
    token: state.auth.authToken,
    authType: state.auth.authType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => {
    dispatch(actions.startLoading());
  },

  finishLoading: () => {
    dispatch(actions.finishLoading());
  },

  setOtsTestsResultData: () => {
    dispatch(actions2.setOtsTestsResultData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtsTestContentComponent);
