import React, { useState } from "react";
import "./StudentActivityAttendance.scss";
import images from "../../utilities/images/images";

function StudentActivityAttendance({ activityData, selectedDate }) {
  const {
    attendance_date,
    campus,
    hostel,
    breakfast,
    lunch,
    dinner,
    campus_punches = [],
    hostel_punches = [],
    visual_message,
  } = activityData.allActivityDetails || {};

  const [selectedTab, setSelectedTab] = useState("campus");
  const punchesToShow =
    selectedTab === "campus" ? campus_punches : hostel_punches;

  const getStatus = (value, yesText, noText) => {
    if (value === undefined || value === null) {
      return { text: "Not Available", className: "gray", icon: images.cross };
    }
    return value === 1
      ? { text: yesText, className: "green", icon: images.correct }
      : { text: noText, className: "red", icon: images.cross };
  };

  const campusStatus = getStatus(campus, "Inside Campus", "Outside Campus");
  const hostelStatus = getStatus(hostel, "Inside Hostel", "Outside Hostel");
  const breakfastStatus = getStatus(breakfast, "Yes", "No");
  const lunchStatus = getStatus(lunch, "Yes", "No");
  const dinnerStatus = getStatus(dinner, "Yes", "No");

  return (
    <div className="student-activity-container">
      <div className="student-status-food-intake-section">
        <div className="student-status-section">
          <h2>Student Status</h2>
          <div className="status-grid">
            <div className="status-item">
              <div className="label">Campus</div>
              <div className={`value ${campusStatus.className}`}>
                {campusStatus.text}
              </div>
            </div>
            <div className="status-item">
              <div className="label">Hostel</div>
              <div className={`value ${hostelStatus.className}`}>
                {hostelStatus.text}
              </div>
            </div>
          </div>
        </div>

        <div className="food-intake-section">
          <h2>Food Intake</h2>
          <div className="intake-grid">
            {[
              { label: "Breakfast", status: breakfastStatus },
              { label: "Lunch", status: lunchStatus },
              { label: "Dinner", status: dinnerStatus },
            ].map((meal, index) => (
              <div className="intake-item" key={index}>
                <div className="label">{meal.label}</div>
                <div className={`value ${meal.status.className}`}>
                  <img src={meal.status.icon} alt={meal.status.text} />
                  {meal.status.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="punch-section">
        <div className="punch-tables">
          
          <div className="punch-table-container">
            <div className="table-header">Campus Punch</div>
            <table>
              <thead>
                <tr>
                  <th className="left-col">Punch Number</th>
                  <th className="right-col">Punch Time</th>
                </tr>
              </thead>
              <tbody>
                {campus_punches.length > 0 ? (
                  campus_punches.map((punch, index) => (
                    <tr key={index}>
                      <td className="left-col">
                        {punch["Punch Number"] || `Punch ${index + 1}`}
                      </td>
                      <td className="right-col">
                        {punch["Punch Time"]
                          ? new Date(punch["Punch Time"]).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: false,
                              }
                            )
                          : "Not Available"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="no-data">
                      Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          
          <div className="punch-table-container">
            <div className="table-header">Hostel Punch</div>
            <table>
              <thead>
                <tr>
                  <th className="left-col">Punch Number</th>
                  <th className="right-col">Punch Time</th>
                </tr>
              </thead>
              <tbody>
                {hostel_punches.length > 0 ? (
                  hostel_punches.map((punch, index) => (
                    <tr key={index}>
                      <td className="left-col">
                        {punch["Punch Number"] || `Punch ${index + 1}`}
                      </td>
                      <td className="right-col">
                        {punch["Punch Time"]
                          ? new Date(punch["Punch Time"]).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: false,
                              }
                            )
                          : "Not Available"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="no-data">
                      Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentActivityAttendance;
