// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { connect } from "react-redux";
// import "./HomeWorkContent.scss";
// import HomeWorkList from "./homework_data/HomeWorkList";
// import "react-datepicker/dist/react-datepicker.css";
// import images from "../../../../utilities/images/images";
// import { DateRange } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
// import { Modal } from "react-bootstrap";
// import SortPopover from "./homework_data/popover/SortPopover";
// import { url_83, url_258 } from "../../../../custom_utilities/api_services";

// const HomeWorkContent = ({ userPreference, token, tokenType }) => {
//   const [subject, setSubject] = useState({});
//   const [subjectsList, setSubjectsList] = useState([]);
//   const [inputValue, setInputValue] = useState(""); // Sorting value
//   const [homeworkData, setHomeworkData] = useState([]);
//   const [dateModalOpen, setDateModalOpen] = useState(false);
//   const [dateRange, setDateRange] = useState(null);

//   useEffect(() => {
//     getSubjectList();
//   }, []);

//   useEffect(() => {
//     if (subject?.sub_id) {
//       getHomeWorkList(subject.sub_id, dateRange);
//     }
//   }, [inputValue, subject, dateRange]);

//   const getSubjectList = async () => {
//     const headers = {
//       Authorization: `${tokenType} ${token}`,
//     };

//     let { courseDetails, classDetails } = userPreference;
//     let data = {
//       course_id: courseDetails[0].course_id,
//       class_id: classDetails.class_id,
//     };

//     try {
//       const response = await axios.post(url_83, data, { headers });

//       if (response.status === 200) {
//         setSubjectsList(response.data.data.subject);
//         setSubject(response.data.data.subject[0]); // Set the first subject initially
//       }
//     } catch (err) {
//       console.error("Error fetching subjects:", err);
//     }
//   };

//   const getHomeWorkList = async (subjectId, dateRange) => {
//     const headers = {
//       Authorization: `${tokenType} ${token}`,
//     };

//     let params = new URLSearchParams({
//       subject_id: subjectId,
//       sort_by:
//         inputValue === "newest"
//           ? "NEW"
//           : inputValue === "oldest"
//           ? "OLD"
//           : inputValue === "unattempted"
//           ? "Unattempted"
//           : "",
//     });

//     if (dateRange && dateRange[0].startDate && dateRange[0].endDate) {
//       params.append(
//         "from_date",
//         dateRange[0].startDate.toISOString().split("T")[0]
//       );
//       params.append(
//         "to_date",
//         dateRange[0].endDate.toISOString().split("T")[0]
//       );
//     }

//     try {
//       const response = await axios.get(`${url_258}?${params.toString()}`, {
//         headers,
//       });
//       if (response.status === 200) {
//         setHomeworkData(response.data.data);
//       }
//     } catch (err) {
//       console.error("Error fetching homework:", err);
//     }
//   };

//   const closeDateModal = () => setDateModalOpen(false);

//   const backGroundColorFromBackend = (color_code) => {
//     const colorCode = color_code ? color_code.split(",") : null;
//     return colorCode
//       ? {
//           background: `linear-gradient(277deg, ${colorCode[0]}, ${colorCode[1]} 101.46%)`,
//         }
//       : { background: "#ffffff" };
//   };

//   return (
//     <div className="homework_container">
//       <div className="homework_header">Homework</div>

//       {/* Subject List */}
//       <div className="homework_subjects">
//         {subjectsList.map((sub) => (
//           <div
//             key={sub.sub_id}
//             onClick={() => setSubject(sub)}
//             className={`single_subject ${
//               subject.sub_name === sub.sub_name ? "selected_sub" : ""
//             }`}
//             style={backGroundColorFromBackend(
//               subject.sub_name === sub.sub_name ? sub.color_code : null
//             )}
//           >
//             <div className="sub_icon">
//               <img src={sub.icon1} alt="icon" />
//             </div>
//             <div className="sub_name">{sub.sub_name}</div>
//           </div>
//         ))}
//       </div>

//       {/* Filters */}
//       <div className="homework_filters">
//         {/* Date Filter */}
//         <div className="filter_by_date" onClick={() => setDateModalOpen(true)}>
//           <img src={images.dateIcon} alt="date-icon" />
//           Custom Date
//         </div>

//         {/* Sort Filter */}
//         <div className="filter_by_sort_options">
//           <SortPopover inputValue={inputValue} setInputValue={setInputValue} />
//         </div>
//       </div>

//       {/* Date Range Modal */}
//       <Modal show={dateModalOpen} onHide={closeDateModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Select Date Range</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <DateRange
//             onChange={(ranges) => {
//               setDateRange([ranges.selection]);
//               if (ranges.selection.startDate && ranges.selection.endDate) {
//                 getHomeWorkList(subject.sub_id, [ranges.selection]);
//               }
//             }}
//             editableDateInputs={true}
//             moveRangeOnFirstSelection={false}
//             ranges={
//               dateRange
//                 ? dateRange
//                 : [{ startDate: null, endDate: null, key: "selection" }]
//             }
//             months={1}
//             direction="horizontal"
//             className="calendarElement"
//           />
//         </Modal.Body>
//       </Modal>

//       {/* Homework List */}
//       <div className="homework_content">
//         <HomeWorkList homeworkData={homeworkData} />
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   token: state.auth.authToken,
//   tokenType: state.auth.tokenType,
//   userPreference: state.userPreference,
// });

// export default connect(mapStateToProps, null)(HomeWorkContent);




import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "./HomeWorkContent.scss";
import HomeWorkList from "./homework_data/HomeWorkList";
import "react-datepicker/dist/react-datepicker.css";
import images from "../../../../utilities/images/images";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Modal } from "react-bootstrap";
import SortPopover from "./homework_data/popover/SortPopover";
import { url_83, url_258 } from "../../../../custom_utilities/api_services";

const HomeWorkContent = ({ userPreference, token, tokenType }) => {
  const [subject, setSubject] = useState({});
  const [subjectsList, setSubjectsList] = useState([]);
  const [inputValue, setInputValue] = useState(""); // Sorting value
  const [homeworkData, setHomeworkData] = useState([]);
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const subjectsRef = useRef(null);

  useEffect(() => {
    getSubjectList();
  }, []);

  useEffect(() => {
    if (subject?.sub_id) {
      getHomeWorkList(subject.sub_id, dateRange);
    }
  }, [inputValue, subject, dateRange]);

  const getSubjectList = async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let { courseDetails, classDetails } = userPreference;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };

    try {
      const response = await axios.post(url_83, data, { headers });

      if (response.status === 200) {
        setSubjectsList(response.data.data.subject);
        setSubject(response.data.data.subject[0]); // Set the first subject initially
      }
    } catch (err) {
      console.error("Error fetching subjects:", err);
    }
  };

  const getHomeWorkList = async (subjectId, dateRange) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let params = new URLSearchParams({
      subject_id: subjectId,
      sort_by:
        inputValue === "newest"
          ? "NEW"
          : inputValue === "oldest"
          ? "OLD"
          : inputValue === "unattempted"
          ? "Unattempted"
          : "",
    });

    if (dateRange && dateRange[0].startDate && dateRange[0].endDate) {
      params.append(
        "from_date",
        dateRange[0].startDate.toISOString().split("T")[0]
      );
      params.append(
        "to_date",
        dateRange[0].endDate.toISOString().split("T")[0]
      );
    }

    try {
      const response = await axios.get(`${url_258}?${params.toString()}`, {
        headers,
      });
      if (response.status === 200) {
        setHomeworkData(response.data.data);
      }
    } catch (err) {
      console.error("Error fetching homework:", err);
    }
  };
  const closeDateModal = () => setDateModalOpen(false);

  const scrollSubjects = (direction) => {
    if (subjectsRef.current) {
      const subjectWidth = 300; 
      subjectsRef.current.scrollLeft += direction === "left" ? -subjectWidth : subjectWidth;
    }
  };

  return (
    <div className="homework_container">
      <div className="homework_header">Homework</div>

      
      <div className="homework_subjects_wrapper">
        <button className="arrow left" onClick={() => scrollSubjects("left")}>
          &#10094;
        </button>

        <div className="homework_subjects" ref={subjectsRef}>
          {subjectsList.map((sub) => (
            <div
              key={sub.sub_id}
              onClick={() => setSubject(sub)}
              className={`single_subject ${
                subject.sub_name === sub.sub_name ? "selected_sub" : ""
              }`}
            >
              <div className="sub_icon">
                <img src={sub.icon1} alt="icon" />
              </div>
              <div className="sub_name">{sub.sub_name}</div>
            </div>
          ))}
        </div>

        <button className="arrow right" onClick={() => scrollSubjects("right")}>
          &#10095;
        </button>
      </div>

      <div className="homework_filters">
      
        <div className="filter_by_date" onClick={() => setDateModalOpen(true)}>
          <img src={images.dateIcon} alt="date-icon" />
          Custom Date
        </div>

       
        <div className="filter_by_sort_options">
          <SortPopover inputValue={inputValue} setInputValue={setInputValue} />
        </div>
      </div>

     
      <Modal show={dateModalOpen} onHide={closeDateModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRange
            onChange={(ranges) => {
              setDateRange([ranges.selection]);
              if (ranges.selection.startDate && ranges.selection.endDate) {
                getHomeWorkList(subject.sub_id, [ranges.selection]);
              }
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={
              dateRange
                ? dateRange
                : [{ startDate: null, endDate: null, key: "selection" }]
            }
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        </Modal.Body>
      </Modal>


      
      <div className="homework_content">
        <HomeWorkList homeworkData={homeworkData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.authToken,
  tokenType: state.auth.tokenType,
  userPreference: state.userPreference,
});

export default connect(mapStateToProps, null)(HomeWorkContent);
