import { React, useState, useEffect } from "react";
import "./PyqSummary.scss";
import { url_143 } from "../../../../../../../../custom_utilities/api_services";
import images from "../../../../../../../../utilities/images/images";
import HeaderComponent from "../../../../../../structure_components/desktop_screens/header_component/header_component";
import axios from "axios";
import { useHistory, Link, useLocation } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import Mobile__right_sideBar_component from "../../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const PyqSummary = (props) => {
  let {
    startLoading,
    finishLoading,
    testResultData,
    userData,
    userPreference,
    history,
    jeeMainOrAdvance,
    pyqtopicSubject,
  } = props;

  const [state, setState] = useState();
  let historys = useHistory();
  const location = useLocation();

  const paperId = location.state?.paper_id ?? testResultData?.paper_id;

  useEffect(() => {
    getPyqSummary();
  }, []);

  const getPyqSummary = async () => {
    let data = {};

    if (testResultData?.topic_wise) {
      data = {
        topic_name: testResultData.topic_name,
        topic_id: testResultData.topic_id,
        topic_wise: testResultData.topic_wise,
        format:
          userPreference?.targetDetails[0].target_id == 3
            ? "NEET"
            : jeeMainOrAdvance === "jee-mains"
            ? "MAIN"
            : "ADV",
        subject: pyqtopicSubject,
      };
    } else {
      data = {
        paper_id: paperId, // Use resolved paperId here
        topic_wise: testResultData?.topic_wise,
        format:
          userPreference?.targetDetails[0].target_id == 3
            ? "NEET"
            : jeeMainOrAdvance === "jee-mains"
            ? "MAIN"
            : "ADV",
      };
    }

    startLoading();
    try {
      const response = await axios.post(url_143, data);
      if (response.data.status === 200) {
        setState(response.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    } finally {
      finishLoading();
    }
  };

  const redirect5 = () => {
    historys.push({
      pathname: "/pyq-test-paper-solution",
      state: {
        paperId: paperId, // Pass the resolved paperId
      },
    });
  };

  let data1 = state?.data;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWidth);
    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);

  return (
    <>
      <div className="behaviourWrapper">
        {windowWidth <= 480 ? (
          // Mobile Screens
          <>
            <MobileLeftSideBarComponent />
            <Mobile_header_component
              userPreference={userPreference}
              userData={userData}
            />
            <Mobile__right_sideBar_component history={history} />
          </>
        ) : (
          <HeaderComponent history={history} />
        )}
        <div className="summary_outer">
          <div className="summary_wrapper">
            <div className="d-flex heading_div">
              <Link
                className="backBtn"
                to={{
                  pathname: "/pyq",
                  state: { tab: "pyq" },
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <h3>Test Summary</h3>
            </div>
            <div className="cards_wrapper">
              <div className="summary_card">
                <p>Total Questions</p>
                <p>{data1?.total_questions}</p>
              </div>
              <div className="summary_card">
                <p>Total Marks</p>
                <p>{data1?.total_marks}</p>
              </div>
              <div className="summary_card">
                <p>Correct Answers</p>
                <p>{data1?.correct_answer}</p>
              </div>
              <div className="summary_card">
                <p>Incorrect Answer</p>
                <p>{data1?.incorrect_answer}</p>
              </div>
              <div className="summary_card">
                <p>Score Percentage</p>
                <p>{`${data1?.score_percentage}%`}</p>
              </div>
            </div>
          </div>
          <div className="button_div">
            <button
              onClick={() => {
                history.push({ pathname: "/pyq", state: { tab: "pyq" } });
              }}
            >
              Back to Tests
            </button>
            <button onClick={redirect5}>View Solutions</button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    testResultData: state.pyqTestsData.pyqResultData,
    userData: state.auth.user,
    userPreference: state.userPreference,
    jeeMainOrAdvance: state.pyqTestsData.jeeMainsOrAdvance,
    pyqtopicData: state.userActivity.subjectData,
    pyqtopicSubject: state.userActivity.pyqSelectedSubjectInTopicWise,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PyqSummary);
