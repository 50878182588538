import { Fragment, useEffect, useState } from "react";
import "./user_doubts.scss";

///////////////////Desktop
import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserDoubtsContentComponent from "../user_doubts/user_doubts_content_component/user_doubts_content_component";
import LoaderPopUp from "../../../components/loader/loader";

//Redux
import { connect } from "react-redux";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";

const UserDoubts = (props) => {
  let { loader, history, userData, userPreference } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);
  return (
    <Fragment>
      <div className="user_doubts_wrappe">
        {windowWidth <= 480 ? (
          <>
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
            </Fragment>
            <div className="user_doubts_content_wrapper">
              <div className="user_doubts_content_inner_wrapper">
                <UserDoubtsContentComponent
                  history={history}
                  userPreference={userPreference}
                  userData={userData}
                />
              </div>

              <Footer_component />
            </div>
          </>
        ) : (
          /////for Screens greater than Mobile Screens////////////////////

          <div className="user_doubts_header">
            <HeaderComponent history={history} />
          </div>
        )}

        <div className="user_doubts_content_wrapper">
          <div className="user_doubts_content_inner_wrapper">
            <UserDoubtsContentComponent
              history={history}
              userPreference={userPreference}
              userData={userData}
            />
          </div>

          <Footer_component />
        </div>

        {loader && <LoaderPopUp />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(UserDoubts);
