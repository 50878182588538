import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import MockTestPracticeInstructionView from "./mockTestInstructionView/mockTestPracticeInstructionView";
import MockTestPracticeQuestionView from "./mockTestQuestionView/mockTestPracticeQuestionView";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import {
  url_80,
  url_81,
  url_82,
  url_142,
} from "../../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import mockActions from "../../../../../../redux/mock_test_exercise/action";

const MockPracticeTestContentComponent = (props) => {
  let {
    history,
    testData,
    startLoading,
    finishLoading,
    userData,
    userPreference,
    setMockTestsResultData,
  } = props;
  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testInstructionData: {},
    testSectionsDetails: {},
    testQuestionsDetails: [],
    currentQuestionDetails: {},
    subjecSectiontWiseTestQuestions: {},
    checkQuestion: {},
    currentQuestion: 0,
    currentOptionId: null,
    page: 1,
    countDownTimer: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    initialTime: 0,
    useTime: 0,
    left: 0,
    is_paid: 0,
    hours: 72,
    minutes: 0,
    seconds: 0,
  });

  const [selectedSubject, setSubject] = useState("");
  const [selectedSection, setSection] = useState("");

  const handleSubjectChange = (subject) => {
    const section = Object.keys(
      state.subjecSectiontWiseTestQuestions[subject]
    )[0];
    setSubject(subject);
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[subject][section][0]["matchIndex"];
    console.log({ currentQuestion });
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));

    //setSubject(subject);
  };
  const handleSectionChange = (section) => {
    setSection(section);
    const currentQuestion =
      state.subjecSectiontWiseTestQuestions[selectedSubject][section][0][
        "matchIndex"
      ];
    setState((prevState) => ({
      ...prevState,
      currentQuestion,
    }));
  };

  useEffect(() => {
    if (state.testQuestionsDetails.length) {
      setSubject(state.testQuestionsDetails[state.currentQuestion]["subject"]);
      setSection(
        state.testQuestionsDetails[state.currentQuestion]["section_name"]
      );
    }
  }, [state.currentQuestion, state.testQuestionsDetails]);

  useEffect(() => {
    getTestDetails();
  }, []);

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: testData }));
  };

  const getTestQuestionsList = async (testDetails) => {
    let data = {
      paper_id: testDetails.paper_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_80, data);
      if (response.data.status === 200) {
        finishLoading();
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };

  const getCurrentQuestionDetails = async (paper_id, qid) => {
    const data = {
      paper_id,
      qid,
    };
    startLoading();

    try {
      const response = await axios.post(url_142, data);

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          currentQuestionDetails: response.data.data[0],
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const startBtn = async () => {
    // let testSectionsDetails = { ...state.testSectionsDetails };
    let testDetails = { ...state.testDetails };
    if (!isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList(testDetails);

      if (returnedResponse.result) {
        returnedResponse.data.apiData.data.forEach((element) => {
          element.isReview = false;
          element.isAttempted = false;
          element.isAnswerGuessed = false;
          element.totalTimeTaken = 0;
          element.isAnswerCorrect = 0;
          element.correctAnswerIdCollection = [];
          element.wrongAnswerIdCollection = [];
          element.userAnswerCollection = [];
        });

        // let time = getNowTime();

        setState((prevState) => ({
          ...prevState,
          step: 2,
          testQuestionsDetails: returnedResponse.data.apiData.data,
          //subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
          //   initialTime: time,
          useTime: returnedResponse.data.apiData.total_time,
        }));
        getCurrentQuestionDetails(
          returnedResponse.data.apiData.data[0].paper_id,
          returnedResponse.data.apiData.data[0].qid
        );
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };

  const handlePreviousQuestionBtn = (cq) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;

    if (
      testQuestionsDetails[currentQuestion - 1].isAttempted ||
      currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
        ?.length
    ) {
      testQuestionsDetails[currentQuestion - 1].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion - 1].isReview = true;
    }

    // let time = getNowTime();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        // initialTime: time,
      }));

      handleSaveQuestion();
      window.scrollTo(0, 0);
      getCurrentQuestionDetails(
        testQuestionsDetails[cq].paper_id,
        testQuestionsDetails[cq].qid
      );
    }
  };

  const handleCurrentOption = (optionId) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentOptionId: optionId,
      };
    });
  };

  const handleSaveQuestion = async () => {
    let { currentQuestion, initialTime, testDetails, currentQuestionDetails } =
      state;
    let calculatedTime = getCalculatedTime(initialTime);
    let data = {
      paper_id: currentQuestionDetails.paper_id,
      option_id: state.currentOptionId,
      qid: currentQuestionDetails.qid,
      time: calculatedTime,
      toughness: currentQuestionDetails.toughness_name,
    };

    startLoading();
    try {
      const response = await axios.post(url_82, data);
      if (response.data.status === 200) {
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleNextQuestionBtn = (cq) => {
  //     let {
  //       currentQuestion,
  //       testQuestionsDetails,
  //       currentQuestionDetails,
  //     } = state;
  //     handleSaveQuestion();
  //     //newline add
  //     if (
  //       testQuestionsDetails[currentQuestion].isAttempted ||
  //       currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
  //         ?.length
  //     ) {
  //       testQuestionsDetails[currentQuestion].isReview = false;
  //     }
  //     else {
  //       testQuestionsDetails[currentQuestion].isReview = true;
  //     }

  //     setState((prevState) => ({
  //       ...prevState,
  //       currentQuestion: currentQuestion + 1,
  //       testQuestionsDetails: testQuestionsDetails,
  //     }));

  //     getCurrentQuestionDetails(
  //       testQuestionsDetails[cq].paper_id,
  //       testQuestionsDetails[cq].qid
  //     );

  //     window.scrollTo(0, 0);
  //   };

  // const handleNextQuestionBtn = (cq) => {
  //   let { currentQuestion, testQuestionsDetails, currentQuestionDetails } =
  //     state;
  //   // handleSaveQuestion();
  //   //newline add
  //   // if (
  //   //   testQuestionsDetails[currentQuestion].isAttempted ||
  //   //   currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
  //   //     ?.length
  //   // ) {
  //   //   testQuestionsDetails[currentQuestion].isReview = true;
  //   // }
  //   // else {
  //   //   testQuestionsDetails[currentQuestion].isReview = false;
  //   // }

  //   setState((prevState) => ({
  //     ...prevState,
  //     currentQuestion: currentQuestion + 1,
  //     testQuestionsDetails: testQuestionsDetails,
  //   }));

  //   // if (
  //   //     testQuestionsDetails[currentQuestion].isAttempted ||
  //   //     currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
  //   //       ?.length
  //   //   ) {
  //   //     testQuestionsDetails[currentQuestion].isReview = true;
  //   //   }
  //   //   else {
  //   //     testQuestionsDetails[currentQuestion].isReview = false;
  //   //   }

  //   getCurrentQuestionDetails(
  //     testQuestionsDetails[cq].paper_id,
  //     testQuestionsDetails[cq].qid
  //   );
  //   window.scrollTo(0, 0);
  // };

  const getNowTime = () => {
    let d = new Date();
    return d.getTime();
  };

  const handleNextQuestionBtn = (cq) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;
    let calculatedTime = getCalculatedTime(initialTime);
    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    handleSaveQuestion();
    //newline add

    if (
      testQuestionsDetails[currentQuestion].isAttempted ||
      currentQuestionDetails?.option?.filter((m) => m.is_user_correct_ans)
        ?.length
    ) {
      testQuestionsDetails[currentQuestion].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion].isReview = true;
    }
    let time = getNowTime();

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
    }));

    getCurrentQuestionDetails(
      testQuestionsDetails[cq].paper_id,
      testQuestionsDetails[cq].qid
    );

    window.scrollTo(0, 0);
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    //localStorage.setItem("current", currentQuestion);
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = {
        option_id: optionId,
      };

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element.option_id === optionId;
    });

    if (findedIndex === -1) {
      let Obj = {
        option_id: optionId,
      };
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        Obj
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (
      testQuestionsDetails[currentQuestion].userAnswerCollection.indexOf(
        optionId
      ) === -1
    ) {
      if (
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
            optionId
          ),
          1
        );
      }
    } else {
      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].correctAnswerIdCollection
            .length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[
            currentQuestion
          ].correctAnswerIdCollection.indexOf(optionId),
          1
        );
      }
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    // value = value.replace(/[^0-9]/g, "")
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer = value.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed =
      !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };
  const handleSaved = () => {
    let { currentQuestion, testQuestionsDetails, testDetails } = state;

    testQuestionsDetails[currentQuestion].is_saved =
      !testQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    if (testQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      // bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      // bookmarkApi(data);
    }
  };

  const handleGuessedAnswer = (event) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[currentQuestion].isAnswerGuessed = checked;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    const { testQuestionsDetails } = state;
    //handleSaveQuestion();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      // currentQuestion:0,
    }));

    getCurrentQuestionDetails(
      testQuestionsDetails[questionNumber].paper_id,
      testQuestionsDetails[questionNumber].qid
    );
  };

  const handleSubmitTest = async (currentQuestionDetails) => {
    let { testDetails, testQuestionsDetails } = state;

    let element = currentQuestionDetails;

    let questionArr = [];

    if (element.answer_type.toLowerCase() === "single correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    } else if (element.answer_type.toLowerCase() === "multiple correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    } else if (element.answer_type.toLowerCase() === "integer correct") {
      let data = {
        qid: element.qid,
        type_id: element.type_id,
        is_saved: element.is_saved,
        total_time: element.totalTimeTaken,
        is_attempted: element.isAttempted,
        paragraph_answer: element.paragraph_answer,
        integer_answer: element.integer_answer,
        is_AnswerCorrect: element.isAnswerCorrect,
        is_GessTypeAnswer: element.isAnswerGuessed,
        positive_marks: element.positive_marks,
        negative_marks: element.negative_marks,
        partial_marks: element.partial_marks,
        CorrectAnswerId: element.correctAnswerIdCollection,
        WrongeAnswerId: element.wrongAnswerIdCollection,
        answer: element.userAnswerCollection,
        toughness_id: element.toughness_id,
        subject: element.subject,
        topic_id: element.topic_id,
      };
      if (!data.type_id) {
        data.type_id = 6;
      }
      questionArr.push(data);
    }
    startLoading();
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );
    if (returnedResponse.result) {
      finishLoading();
      setMockTestsResultData(testDetails);
      // history.push({
      //   pathname: "pyq_cps_exam",
      //   state: {
      //     tab: "mock_test",
      //   },
      // });

      history.push({
        pathname: "/mock_test_summary",
      });
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
      // history.push("/user_exercise_test");
    }
  };

  const submitTestApi = async (_userPreference, testDetails, _questionArr) => {
    let requestPayload = {
      paper_id: testDetails.paper_id,
      time: 84,
    };
    try {
      const response = await axios.post(url_81, requestPayload);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <MockTestPracticeInstructionView
            testDetails={state.testDetails}
            testSectionsDetails={state.testSectionsDetails}
            startBtn={startBtn}
            testInstructionData={state.testDetails}
            total_attempts={state.left}
          />
        );
      case 2:
        return (
          <MockTestPracticeQuestionView
            testQuestionsDetails={state.testQuestionsDetails}
            currentQuestionDetails={state.currentQuestionDetails}
            page={state.page}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            selectedSubject={selectedSubject}
            selectedSection={selectedSection}
            handleCurrentOption={handleCurrentOption}
            handleSubjectChange={handleSubjectChange}
            handleSectionChange={handleSectionChange}
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleReview={handleReview}
            handleSaved={handleSaved}
            handleGuessedAnswer={handleGuessedAnswer}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={handleSubmitTest}
            checkQuestion={state.checkQuestion}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
            stateTestDetails={state.testDetails}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="exam_cracker_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testData: state.mockTestsData.mockTestData,
    ExamCrackerTopicData: state.userActivity.ExamCrackerTopicData,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setMockTestsResultData: (payload) => {
      dispatch(mockActions.setMockTestsResultData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MockPracticeTestContentComponent);
