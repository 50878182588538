import { BASE_URL } from "./baseURL";

//////////////for local development//////////////////
// export const url_1 = `/api/user/send-otp`;
// export const url_2 = `/api/user/verify-otp`;
// //////////////////////////////////////////
// export const url_3 = `/api/user/states`;
// export const url_4 = `/api/user/cities`;
// export const url_5 = `/api/user/courses-and-classes`;
// export const url_6 = `/api/user/signup`;
// /////////////////////////////////////////
// export const url_7 = `/api/user/VideoDetail`;
// export const url_8 = `/api/user/get`;
// export const url_9 = `/api/user/UserSubject`;
// export const url_10 = `/api/user/UserSubjectTopic`;
// export const url_11 = `/api/user/VideoList`;
// export const url_12 = `/api/user/LiveClassesList`;
// export const url_13 = `/api/user/NotesList`;
// export const url_14 = `/api/user/CourseBatchTimeTableList`;
// export const url_15 = `/api/user/CourseBatchTimeTableContentData`;
// export const url_16 = `/api/user/PackageList`;
// export const url_17 = `/api/user/PackageDetail`;

// ///////////generating access token/////////////
// export const url_18 = `/api/generate`;
// ////////////////////////////////////////////
// export const url_19 = `/api/user/SaveUserTransaction`;
// export const url_20 = `/api/user/SendDouteQuestion`;
// export const url_21 = `/api/user/DouteQuestionList`;
// export const url_22 = `/api/user/FreeVideoList`;
// export const url_23 = `/api/user/DPPList`;
// export const url_24 = `/api/user/DPPQuestionList`;
// export const url_25 = `/api/user/SaveUserDPPQuestion`;
// export const url_26 = `/api/user/ExerciseQuestionList`;
// export const url_27 = `/api/user/SaveUserExerciseQuestion`;
// export const url_28 = `/api/user/TestList`;
// export const url_29 = `/api/user/TestDetail`;
// export const url_30 = `/api/user/TestQuestionList`;
// export const url_31 = `/api/user/SaveTestQuestion`;
// export const url_32 = `/api/user/UserUpdateClassCourse`;
// export const url_33 = `/api/user/ExerciseList`;
// export const url_34 = `/api/user/content-list`;
// export const url_35 = `/api/user/faq`;
// export const url_36 = `/api/user/transaction`;
// export const url_37 = `/api/user/notification`;
// export const url_38 = `/api/user/OverAllTestList`;
// export const url_39 = `/api/user/saveBookmark`;
// export const url_40 = `/api/user/fetchBookmark`;
// export const url_41 = `/api/user/IndividualTestList`;
// export const url_42 = `/api/user/LearnAnalysis`;
// export const url_43 = `/api/user/SaveUserVideoWatchTime`;
// export const url_44 = `/api/user/DifficultyLevelList`;
// export const url_45 = `/api/user/CreateQuestionBank`;
// export const url_46 = `/api/user/QuestionBankList`;
// export const url_47 = `/api/user/DeleteQuestionBank`;
// export const url_48 = `/api/user/QBankQuestionList`;
// export const url_49 = `/api/user/SaveQuestionBankQuestion`;
// export const url_50 = `/api/user/saveQuery`;
// export const url_51 = `/api/user/lastPlayedVideo`;
// export const url_52 = `/api/user/ViewReportData`;
// export const url_53 = `https://tc.mogiapp.com/ott/liveStream/`;

// ////////////for server//////////////////

export const url_1 = `${BASE_URL}/api/user/send-otp`;

export const url_2 = `${BASE_URL}/api/user/verify-otp`;
//////////////////////////////////////////

export const url_3 = `${BASE_URL}/api/user/states`;
export const url_4 = `${BASE_URL}/api/user/cities`;
export const url_5 = `${BASE_URL}/api/v1/courses_and_classes`;
export const url_6 = `${BASE_URL}/api/user/signup`;
/////////////////////////////////////////
export const url_7 = `${BASE_URL}/api/user/VideoDetail`;
export const url_8 = `${BASE_URL}/api/user/get`;
export const url_8_1 = `${BASE_URL}/api/v1/user_course_details`;
export const url_9 = `${BASE_URL}/api/user/UserSubject`;
export const url_10 = `${BASE_URL}/api/v1/UserSubjectTopic`;

export const url_11 = `${BASE_URL}/api/user/FacultyVideoListNew`;
export const url_140 = `${BASE_URL}/api/user/VideoFacultyList`;

export const url_12 = `${BASE_URL}/api/user/LiveClassesList`;
export const url_12_1 = `${BASE_URL}/api/user/ZoomWeb`;
export const url_13 = `${BASE_URL}/api/user/NotesList`;
export const url_14 = `${BASE_URL}/api/user/CourseBatchTimeTableList`;
export const url_15 = `${BASE_URL}/api/user/CourseBatchTimeTableContentData`;
export const url_15_1 = `${BASE_URL}/api/user/CourseBatchTimeTableContentDataWeb`;
export const url_16 = `${BASE_URL}/api/user/PackageList`;
export const url_17 = `${BASE_URL}/api/user/PackageDetail`;

/////////generating access token/////////////
export const url_18 = `${BASE_URL}/api/generate`;
/////////////////////////////////////////////
export const url_19 = `${BASE_URL}/api/user/SaveUserTransaction`;
export const url_20 = `${BASE_URL}/api/user/SendDouteQuestion`;
export const url_21 = `${BASE_URL}/api/user/DouteQuestionList`;
export const url_22 = `${BASE_URL}/api/user/FreeVideoList`;

export const url_23 = `${BASE_URL}/api/user/DPPList`;

export const url_24 = `${BASE_URL}/api/user/DPPQuestionList`;

export const url_25 = `${BASE_URL}/api/user/SaveUserDPPQuestion`;

export const url_26 = `${BASE_URL}/api/user/ExerciseQuestionList`;

export const url_27 = `${BASE_URL}/api/user/SaveUserExerciseQuestion`;
export const url_28 = `${BASE_URL}/api/user/TestList`;

export const url_29 = `${BASE_URL}/api/user/UserTestDetail`; //new

////////  exams tests

//  to start tests
export const url_30 = `${BASE_URL}/api/user/UserTestQuestionListNew`; //new
export const url_107 = `${BASE_URL}/api/user/UserTestQuestionOnly`; //new
export const url_110 = `${BASE_URL}/api/user/OtsQuestionDetailsAll`; //new
export const url_111 = `${BASE_URL}/api/user/UserTestQuestionOnly`; //new

export const url_31 = `${BASE_URL}/api/user/UserOtsSubmit`; //new
export const url_32 = `${BASE_URL}/api/v1/update/classes_courses`;
export const url_33 = `${BASE_URL}/api/user/ExerciseList`;
export const url_34 = `${BASE_URL}/api/user/content-list`;
export const url_35 = `${BASE_URL}/api/user/faq`;
export const url_36 = `${BASE_URL}/api/user/transaction`;
export const url_37 = `${BASE_URL}/api/user/notification`;
export const url_38 = `${BASE_URL}/api/user/OverAllTestList`;
export const url_39 = `${BASE_URL}/api/user/saveBookmark`;
export const url_40 = `${BASE_URL}/api/user/fetchBookmark`;
export const url_106 = `${BASE_URL}/api/user/ExamfetchBookmark`; //tests fetch bookmark

export const url_41 = `${BASE_URL}/api/user/UserIndividualTestList`;

export const url_42 = `${BASE_URL}/api/user/LearnAnalysis`;
export const url_43 = `${BASE_URL}/api/user/SaveUserVideoWatchTime`;
export const url_44 = `${BASE_URL}/api/user/DifficultyLevelList`;
export const url_45 = `${BASE_URL}/api/user/CreateQuestionBank`;
export const url_46 = `${BASE_URL}/api/user/QuestionBankList`;
export const url_47 = `${BASE_URL}/api/user/DeleteQuestionBank`;
export const url_48 = `${BASE_URL}/api/user/QBankQuestionList`;
export const url_49 = `${BASE_URL}/api/user/SaveQuestionBankQuestion`;
export const url_50 = `${BASE_URL}/api/user/saveQuery`;
export const url_51 = `${BASE_URL}/api/user/lastPlayedVideo`;
export const url_52 = `${BASE_URL}/api/user/ViewReportData`;
export const url_53 = `https://tc.mogiapp.com/ott/liveStream/`;
export const url_54 = `${BASE_URL}/api/user/forcefully_updated`;
export const url_55 = `${BASE_URL}/api/user/Zoom_validation`;
export const url_56 = `${BASE_URL}/api/user/freeCoursesTargetBased`;
export const url_57 = `${BASE_URL}/api/user/PackageList`;
export const url_58 = `${BASE_URL}/api/user/UserSubjectTopic`;
export const url_59 = `${BASE_URL}/api/user/apply_couponweb`;
export const url_60 = `${BASE_URL}/api/v1/user/packages/handbook`;
export const url_61 = `${BASE_URL}/api/user/UserTestList`;
export const url_62 = `${BASE_URL}/api/user/UserOtsDetailsNew`; //new
export const url_63 = `${BASE_URL}/api/user/UserOverAllTestAnalysis`; //new
export const url_64 = `${BASE_URL}/api/user/UserOverAllSubjectList`; //new
export const url_65 = `${BASE_URL}/api/user/UserOverAllSubjectPaperList`; //new
export const url_66 = `${BASE_URL}/api/user/UserPaperSubjectAnalysis`; //new
export const url_67 = `${BASE_URL}/api/user/UserPaperSubjectAnalysis`; //new
export const url_68 = `${BASE_URL}/api/user/UserPaperTimeAnalysis`; //new
export const url_69 = `${BASE_URL}/api/user/UserPaperAnalysis`; //new
export const url_70 = `${BASE_URL}/api/user/UserPaperSubjectAnalysis`; //new
export const url_71 = `${BASE_URL}/api/user/UserPaperTopicSubjectAnalysis`; //new
export const url_72 = `${BASE_URL}/api/user/UserPaperTopicMarksAnalysis`; //new
export const url_73 = `${BASE_URL}/api/user/UserPaperAttemptAnalysis`; //new
export const url_74 = `${BASE_URL}/api/user/UserPaperDifficultyAnalysis`; //new
export const url_75 = `${BASE_URL}/api/user/UserPaperTimeAnalysis`; //new

export const url_76 = `${BASE_URL}/api/user/UserOtsCheckFormat`; //new

export const url_77 = `${BASE_URL}/api/user/savemarkforreview`; //new

export const url_78 = `${BASE_URL}/api/user/WebSliderImages`; //new

export const url_79 = `${BASE_URL}/api/user/pyqpaper`; //new

export const url_109 = `${BASE_URL}/api/ots/get/homeslidelist`; //new

//pyqTest
export const url_80 = `${BASE_URL}/api/user/pyqQuestionDetailsNew`; //new
export const url_142 = `${BASE_URL}/api/user/pyqQuestionDetailsAllNew`; //new

export const url_81 = `${BASE_URL}/api/user/UserPYQSubmit`; //new
export const url_82 = `${BASE_URL}/api/user/UserPYQDetails`; //new
export const url_83 = `${BASE_URL}/api/user/UserSubjectOTS`; //new
export const url_84 = `${BASE_URL}/api/user/pyqSubjectWiseTopics`; //new
export const url_85 = `${BASE_URL}/api/user/UserPyqTopicPaperInstructions`; //new
// export const url_86 = `${BASE_URL}/api/user/UserPyqTopicPaperQuestions`; //new
export const url_86 = `${BASE_URL}/api/user/UserPyqTopicPaperQuestionsData`; //new
export const url_87 = `${BASE_URL}/api/user/pyqTopicPaperDetailsNew`; //new
export const url_88 = `${BASE_URL}/api/user/SaveUserExerciseQuestion`; //new
export const url_89 = `${BASE_URL}/api/user/SaveUserExerciseQuestion`; //new
export const url_90 = `${BASE_URL}/api/user/cpsSubjectWiseTopics`; //new
export const url_91 = `${BASE_URL}/api/user/cpsTopicWiseSubTopicsNew`; //new
export const url_92 = `${BASE_URL}/api/user/FacultyInfo`; //new
export const url_93 = `${BASE_URL}/api/user/cpsPaperInstructions`; //new
export const url_94 = `${BASE_URL}/api/user/cpsQuestionDetailsBySubTopic`; //new
export const url_95 = `${BASE_URL}/api/user/UserCPSSubmit`; //new
export const url_96 = `${BASE_URL}/api/user/UserCPSDetails`; //new

export const url_97 = `${BASE_URL}/api/user/UserCPSAnalysis`; //new

export const url_98 = `${BASE_URL}/api/user/UserTestList`; //new

export const url_99 = `${BASE_URL}/api/user/GetUserProgessDetails`; //new
export const url_100 = `${BASE_URL}/api/user/UserOverAllTestAnalysis`; //new

export const url_101 = `${BASE_URL}/api/reporterror/getoptions`; // for errorData

export const url_102 = `${BASE_URL}/api/reporterror/save`; // f

export const url_105 = `${BASE_URL}/api/user/pyqYear`;

// export const url_106 = `${BASE_URL}/api/ots/get/ongoing/tests`;

//////////////// Exam cracker   /////////////////////
export const url_112 = `${BASE_URL}/api/examcracker/get/lists`;
export const url_113 = `${BASE_URL}/api/examcracker/getusersubjects`;
export const url_114 = `${BASE_URL}/api/examcracker/gettopics`;
export const url_115 = `${BASE_URL}/api/examcracker/getquestions`;
export const url_116 = `${BASE_URL}/api/examcracker/update/userdetails`;
export const url_117 = `${BASE_URL}/api/examcracker/submit`;

////////// Mock test  ///////////////
export const url_146 = `${BASE_URL}/api/user/mocktest`;
// ots
export const url_118 = `${BASE_URL}/api/ots/get/planner/list`;
export const url_119 = `${BASE_URL}/api/ots/get/planner`;

export const url_120 = `${BASE_URL}/api/ots/get/freepaidspt/tests`;

export const url_121 = `${BASE_URL}/api/ots/get/instruction`;

export const url_122 = `${BASE_URL}/api/ots/get/test/syllabus`;

export const url_123 = `${BASE_URL}/api/ots/get/question/new_view`;

export const url_124 = `${BASE_URL}/api/ots/get/question/new_option`;
export const url_125 = `${BASE_URL}/api/ots/solution/saveduplicate`;
export const url_126 = `${BASE_URL}/api/ots/user/final/submitoptimized`;

export const url_127 = `${BASE_URL}/api/ots/get/planner/overview`;

// ots analytics
export const url_128 = `${BASE_URL}/api/ots/get/analytics`;

export const url_129 = `${BASE_URL}/api/ots/get/user/otspaper/analysis`;

export const url_130 = `${BASE_URL}/api/ots/get/user/otspaper/subjectanalysis`;

export const url_131 = `${BASE_URL}/api/ots/get/user/otspaper/attempt/analysis`;

export const url_132 = `${BASE_URL}/api/ots/get/user/ots/subjectlist`;

export const url_133 = `${BASE_URL}/api/ots/get/user/otspaper/difficulty/analysis`;

export const url_134 = `${BASE_URL}/api/ots/get/user/otspaper/time/analysis`;

export const url_135 = `${BASE_URL}/api/ots/get/otsquestionlistsolutionduplicate`;

export const url_136 = `${BASE_URL}/api/ots/get/otssolutionduplicate`;

export const url_137 = `${BASE_URL}/api/ots/get/user/otspapertopic/marksanalysis`;

export const url_138 = `${BASE_URL}/api/ots/get/otstermsandcondition`;

export const url_139 = `${BASE_URL}/api/ots/get/otsrankpredictor`;

export const url_141 = `${BASE_URL}/api/user/UserOverAllSubjectTypeAnalysis`;

export const url_143 = `${BASE_URL}/api/user/UserPYQAnalysis`; //new

export const url_144 = `${BASE_URL}/api/ots/get/user/ots/newanalytics`; //new

export const url_145 = `${BASE_URL}/api/user/Bitsat`;

export const url_147 = `${BASE_URL}/api/user/get/cca/configweb`;

export const url_148 = `${BASE_URL}/api/user/get/cca/response`;

export const url_149 = `${BASE_URL}/api/user/UserQuestionBankList`;

export const url_150 = `${BASE_URL}/api/user/UserQBankQuestionCreate`;

export const url_151 = `${BASE_URL}/api/user/UserSubject`;

export const url_152 = `${BASE_URL}/api/user/UserSubjectTopic`;

export const url_209 = `${BASE_URL}/api/user/userotsnewanalytics`;

// question - bank

export const url_153 = `${BASE_URL}/api/user/UserQBankQuestionInstructions`;

export const url_154 = `${BASE_URL}/api/user/UserQBankQuestionData`;

export const url_155 = `${BASE_URL}/api/user/UserQBankQuestionDetails`;

export const url_156 = `${BASE_URL}/api/user/UserQBankSubmit`;

export const url_157 = `${BASE_URL}/api/user/UserQBankSolutions`;

//  store> handbooks Apis //
export const url_158 = `${BASE_URL}/api/user/Producttype`;
export const url_159 = `${BASE_URL}/api/user/Productlist`;
export const url_160 = `${BASE_URL}/api/user/Productaddcart`;
export const url_161 = `${BASE_URL}/api/user/UpdateWishlist`;
export const url_162 = `${BASE_URL}/api/user/Cartdetails`;
export const url_163 = `${BASE_URL}/api/user/RemoveCartProduct`;
export const url_164 = `${BASE_URL}/api/user/UserWishlist`;
export const url_165 = `${BASE_URL}/api/user/HandbookUserAddress`;
export const url_166 = `${BASE_URL}/api/user/HandbookSaveUserAddress`;
export const url_167 = `${BASE_URL}/api/user/HandbookCoupon`;
export const url_168 = `${BASE_URL}/api/user/HandbookPaymentPage`;
export const url_169 = `${BASE_URL}/api/user/HandbookTransaction`;
export const url_170 = `${BASE_URL}/api/user/UpdateCartProduct`;
export const url_180 = `${BASE_URL}/api/validationreferralcode`;
export const url_181 = `${BASE_URL}/api/settings/whatsapp/template`;

//// ots delete data API
export const url_171 = `${BASE_URL}/api/ots/user/deletedata`;

export const url_172 = `${BASE_URL}/api/feedback_popup`;
export const url_173 = `${BASE_URL}/api/add_faculty_feedback_reports`;

// ots delete data API for start Test

export const url_178 = `${BASE_URL}/api/ots/get/deletedata`;

export const url_179 = `${BASE_URL}/api/ots/get/user/analysis`;

// Coins API
export const url_174 = `${BASE_URL}/api/wallet/update/usercoin/add`;
export const url_175 = `${BASE_URL}/api/wallet/get/coin/settings`;
export const url_176 = `${BASE_URL}/api/v1/coin/transcation`;
export const url_177 = `${BASE_URL}/api/wallet/apply/user/coins/cartweb`;

// refund API

export const url_182 = `${BASE_URL}/api/getrefundparent`;
export const url_183 = `${BASE_URL}/api/getuserrefundsubmit`;

// refer & Earn
export const url_184 = `${BASE_URL}/api/get/referralcode`;
export const url_185 = `${BASE_URL}/api/get/referrallist`;
export const url_186 = `${BASE_URL}/api/settings/whatsapp/template`;

// email update - when payment
export const url_187 = `${BASE_URL}/api/user/Upadte_email`;

// exams module - new api's(existing is there)

export const url_188 = `${BASE_URL}/api/user/examallquestion`;
export const url_189 = `${BASE_URL}/api/user/examsinglequestion`;
export const url_190 = `${BASE_URL}/api/user/examsinglesave`;
export const url_191 = `${BASE_URL}/api/user/examsubmit`;
export const url_192 = `${BASE_URL}/api/user/examanalyticssave`;
export const url_193 = `${BASE_URL}/api/user/examallquestionsolutionlist`;
export const url_194 = `${BASE_URL}/api/user/examsolution`;
export const url_195 = `${BASE_URL}/api/user/examsummary`;

export const url_198 = `${BASE_URL}/api/getrollvalidate`;
export const url_199 = `${BASE_URL}/api/getparentlisting`;
export const url_200 = `${BASE_URL}/api/getsupportlisting`;
export const url_201 = `${BASE_URL}/api/getusersupportsubmit`;

// exam - new api - delete test
export const url_202 = `${BASE_URL}/api/user/delete_all_data`;

//exam-cracker

export const url_203 = `${BASE_URL}/api/examcracker/getexamcrackerquestions`;

export const url_204 = `${BASE_URL}/api/user/get/get_final_amount`;

//pyq-daily-challenge
export const url_205 = `${BASE_URL}/api/user/pyq_challenge_schedule`;
export const url_206 = `${BASE_URL}/api/user/pyq_challenge_subject_syllabus`;
export const url_207 = `${BASE_URL}/api/user/pyq_challenge_insrtuctions`;
export const url_208 = `${BASE_URL}/api/user/pyq_challenge_UserDetails`;

export const url_210 = `${BASE_URL}/api/ots/get/saveregistration`;

// send sms to student to fill detais ( profile)

export const url_211 = "https://mis.mescray001.com/Tracker/api/v1/index.php";

export const url_212 = `${BASE_URL}/api/user/pyqsinglequestion`;

//common banner for attempts left
export const url_213 = `${BASE_URL}/api/v1/subjectlist`;

// recorded live sessions
export const url_214 = `${BASE_URL}/api/v1/get/live/videos`;

// stream recorded live sessions
export const url_215 = `${BASE_URL}/api/v1/streamos`;

// livestream user count
export const url_216 = `${BASE_URL}/api/v1/live/viewers`;

// store list new
export const url_217 = `${BASE_URL}/api/v1/product/list`;

// package offering details new
export const url_218 = `${BASE_URL}/api/v1/product/details`;

// coupon list
export const url_219 = `${BASE_URL}/api/v1/coupon/list`;

// video listing tabs
export const url_220 = `${BASE_URL}/api/v1/study_material_list`;
export const url_221 = `${BASE_URL}/api/v1/Save_rate_comments`;
export const url_222 = `${BASE_URL}/api/v1/ratelist`;
export const url_223 = `${BASE_URL}/api/v1/Save_videolike`;
export const url_224 = `${BASE_URL}/api/v1/timeline`;
export const url_225 = `${BASE_URL}/api/v1/mynoteslist`;
export const url_226 = `${BASE_URL}/api/v1/save_mynotes`;
export const url_227 = `${BASE_URL}/api/v1/edit_mynotes`;
export const url_228 = `${BASE_URL}/api/v1/delete_mynotes`;
export const url_229 = `${BASE_URL}/api/v1/save_video_report`;

// homescreen doubts modal
export const url_230 = `https://mis.mescray001.com/Tracker/api/v1/index.php`;

// attendance page
export const url_231 = `http://mescray001.com/Tracker/api/v1/index.php`;

// new design landing page

export const url_232 = `${BASE_URL}/api/v1/get_target_details`;
export const url_233 = `${BASE_URL}/api/v1/get_course_details`;
export const url_234 = `${BASE_URL}/api/v1/get_testimonials_details`;

export const url_235 = `${BASE_URL}/api/v1/get_motionnews_details`;

// new refer and earn

export const url_236 = `${BASE_URL}/api/v1/get/referral/data`;

export const url_237 = `${BASE_URL}/api/v1/referral/cashreward/list`;

export const url_238 = `${BASE_URL}/api/v1/referral/update/accdata`;

export const url_239 = `${BASE_URL}/api/v1/referral/redeem`;

export const url_240 = `${BASE_URL}/api/v1/referral/cancel`;

export const url_241 = `${BASE_URL}/api/v1/referral/animation`;

export const url_242 = `${BASE_URL}/api/v1/SaveUserVideoWatchTime`;

// new handbook flow

export const url_245 = `${BASE_URL}/api/v1/get_handbook_list`;

export const url_246 = `${BASE_URL}/api/v1/get_handbook_details`;

export const url_247 = `${BASE_URL}/api/v1/handbook_addcart`;

export const url_248 = `${BASE_URL}/api/v1/handbook_cartdetails`;

export const url_249 = `${BASE_URL}/api/v1/remove_cart_handbook`;

export const url_250 = `${BASE_URL}/api/v1/update_cart_handbook`;

export const url_251 = `${BASE_URL}/api/v1/handbook_user_address`;

export const url_252 = `${BASE_URL}/api/v1/handbook_save_user_address`;

export const url_253 = `${BASE_URL}/api/v1/handbook_transaction`;

// https://learning.motion.ac.in/motioneducation/api/v1/handbook_transaction?price=${handleBookDetails?.price}&discount=${handleBookDetails?.discount}&coupon_discount=${handleBookDetails?.coupon_discount}&shipping_charge=${handleBookDetails?.shipping_charge}&total_price=${handleBookDetails?.total_price}&coins_count=${handleBookDetails?.coins_count}
// homework

// https://learning.motion.ac.in/motioneducation/api/v1/homework_instructions?paper_id=936277

export const url_254 = `${BASE_URL}/api/v1/homeworkallquestion`;

export const url_255 = `${BASE_URL}/api/v1/homeworksinglequestion`;

export const url_256 = `${BASE_URL}/api/v1/homework_test_attempt_save`;

export const url_257 = `${BASE_URL}/api/v1/homework_submit_details`;

export const url_258 = `${BASE_URL}/api/v1/homework_list`;

export const url_259 = `${BASE_URL}/api/v1/homework_view_solution`;

export const url_260 = `${BASE_URL}/api/v1/getStreakInfo`;

export const url_261 = `${BASE_URL}/api/v1/streak/redeem/list`;

export const url_262 = `${BASE_URL}/api/v1/getFiloInfo`;
