import React, { useState, useEffect } from "react";
import "./OtsSummary.scss";
import images from "../../../../../../utilities/images/images";
import { url_179 } from "../../../../../../custom_utilities/api_services";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../../../../structure_components/desktop_screens/header_component/header_component";
import uiActions from "../../../../../../redux/ui/action";
import { connect } from "react-redux";
import axios from "axios";
const OtsSummary = (props) => {
  const { startLoading, finishLoading, otsTestsData } = props;
  const [otsSummary, setOtsSummary] = useState({});
  useEffect(() => {
    getOtsSummary();
  }, []);

  const getOtsSummary = async () => {
    let data = {
      paper_id: otsTestsData.paper_id,
      test_id: otsTestsData.test_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_179, data);
      if (response.data.status === 200) {
        setOtsSummary(response.data.data);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  return (
    <div>
      <HeaderComponent />
      <div className="otsSummaryComponentWrapper">
        <div className="summary_outer">
          <div className="summary_wrapper">
            <div className="d-flex heading_div">
              <Link
                className="backBtn"
                to={{
                  pathname: "/ots",
                  state: { tab: "ots" },
                }}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </Link>
              <h3>Test Summary</h3>
            </div>
            <div className="cards_wrapper">
              <div className="summary_card">
                <p>Total Questions</p>
                <p>{otsSummary?.total_questions}</p>
              </div>
              <div className="summary_card">
                <p>Total Marks</p>
                <p>{otsSummary?.total_marks}</p>
              </div>
              <div className="summary_card">
                <p>Correct Answers</p>
                <p>{otsSummary?.correct_answer}</p>
              </div>
              <div className="summary_card">
                <p>Incorrect Answer</p>
                <p>{otsSummary?.incorrect_answer}</p>
              </div>
              <div className="summary_card">
                <p>Score Percentage</p>
                <p>{`${otsSummary?.score_percentage}%`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    otsTestsData: state.otsTestsData.otsTestsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtsSummary);
