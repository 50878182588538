import React from "react";
import VideoJsPlayer1 from "../../../../../custom_utilities/NewVideoPlayer1/videoshow";
import VideoJsPlayer from "../../../../../custom_utilities/NewVideoPlayer/videoshow";

const VideoDrmPlayer = ({ id_video, data }) => {
  return (
    <div>
      {data?.is_free_user ? (
        <VideoJsPlayer1 key={id_video} id_video={id_video} /> // for new user
      ) : (
        <VideoJsPlayer key={id_video} id_video={id_video} /> // for normal(any) video playing use this
      )}
    </div>
  );
};

export default VideoDrmPlayer;
