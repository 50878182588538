import { Fragment } from "react";
import "./ParentHeader.scss";
import ParentHeaderLeft from "./parentHeaderLeft/ParentHeaderLeft";
import ParentHeaderRight from "./parentHeaderRight/ParentHeaderRight";

/////React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Redux

const ParentHeader = () => {
  return (
    <Fragment>
      <div className="parent-header_component_wrapper">
        <div style={{ paddingLeft: "100px", paddingRight: "100px" }}>
          <div className="header_inner_wrapper">
            <div className="header_left_wrapper">
              <ParentHeaderLeft />
            </div>
            <div className="header_right_wrapper">
              <ParentHeaderRight />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ParentHeader;
