import React, { useState, useEffect } from "react";
import "./AdaptiveCps.scss";

import axios from "axios";
import AdaptiveCpsCreatePaper from "./components/home-components/adaptive-cps-create/AdaptiveCpsCreatePaper";
import AdaptiveCpsCustomPapers from "./components/home-components/adaptive-cps-custom-papers/AdaptiveCpsCustomPapers";
import uiActions from "../../../../redux/ui/action";
import { connect } from "react-redux";
import AdaptiveCpsContent from "./components/home-components/adaptiveCpsContent/AdaptiveCpsContent";
import AdaptiveCpsAction from "../../../../redux/adaptive_cps/action";

const AdaptiveCps = ({
  startLoading,
  finishLoading,
  handleStep,
  step,
  setAdaptiveCpsId,
}) => {
  
  const [state, setState] = useState({
    topicSubjectList: [], // Initialize topicSubjectList as an empty array
    // step: 1,
  });
  useEffect(() => {
    getSubjectList();
  }, []);

  // Function to fetch subject list from the API
  const getSubjectList = async () => {
    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/user/UserACPSSubjectData"
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          topicSubjectList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle the step change
  // const handleStep = (newStep) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     step: newStep,
  //   }));
  // };

  // Customized rendering logic
  const renderAdaptiveCpsContent = () => {
    // const { step } = state;

    if (step === 1) {
      return (
        <div className="adaptive-cps-container">
          <div>
            <AdaptiveCpsCustomPapers
              startLoading={startLoading}
              finishLoading={finishLoading}
              setAdaptiveCpsId={setAdaptiveCpsId}
            />
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <AdaptiveCpsContent
          handleStep={handleStep}
          startLoading={startLoading}
          finishLoading={finishLoading}
        />
      );
    }

    // If step is neither 1 nor 2, return null (no content rendered)
    return null;
  };

  return renderAdaptiveCpsContent(); // Call the customized rendering function
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setAdaptiveCpsId: (acps_id) => {
      dispatch(AdaptiveCpsAction.setAdaptiveCpsId(acps_id));
    },
  };
};

export default connect(null, mapDispatchToProps)(AdaptiveCps);
