import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import images from "../../../../../../../utilities/images/images";
import HeaderComponent from "../../../../../structure_components/desktop_screens/header_component/header_component";
import FreeOtsTest from "./free_ots/FreeOtsTest";
import PremiumOtsTest from "./premium_ots/PremiumOtsTest";
import "./OtsTestListComponent.scss";
import { connect } from "react-redux";
import uiActions from "../../../../../../../redux/ui/action";
import LoaderPopUp from "../../../../../../../components/loader/loader";
import otsActions from "../../../../../../../redux/online-test_series/action";
import Footer_component from "../../../../../structure_components/desktop_screens/footer_component/Footer_component";
import Mobile__right_sideBar_component from "../../../../../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";
import Mobile_header_component from "../../../../../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileLeftSideBarComponent from "../../../../../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";

const OtsTestListComponent = (props) => {
  const {
    startLoading,
    finishLoading,
    loader,
    otsPlannerData,
    setOtsPremiumData,
    setOtsFreeData,
    target_id,
    userData,
    userPreference,
  } = props;

  let data;
  if (target_id == 2) {
    data = [
      {
        id: "one",
        category: "All Tests",
        planner_test_type: 0,
      },
      {
        id: "two",
        category: "Unit Test",
        planner_test_type: 1,
      },
      {
        id: "three",
        category: "Major Test",
        planner_test_type: 2,
      },
      {
        id: "four",
        category: "Full Syllabus Test",
        planner_test_type: 3,
      },
    ];
  } else if (target_id == 3) {
    data = [
      {
        id: "one",
        category: "All Tests",
        planner_test_type: 0,
      },
      {
        id: "two",
        category: "Minor Test",
        planner_test_type: 5,
      },
      {
        id: "three",
        category: "Semi Major Test",
        planner_test_type: 4,
      },
      {
        id: "four",
        category: "Major Test",
        planner_test_type: 2,
      },
    ];
  } else if (target_id == 12) {
    data = [
      {
        id: "one",
        category: "All Tests",
        planner_test_type: 0,
      },
      {
        id: "two",
        category: "Full Syllabus Test",
        planner_test_type: 7,
      },
    ];
  } else {
    data = [
      {
        id: "one",
        category: "All Tests",
        planner_test_type: 0,
      },
    ];
  }

  const history = useHistory();

  const [testCategories, setTestCategories] = useState(data);
  const [plannerTestType, setPlannerTestType] = useState(data?.[0]);
  const [upcomingAndpreviousTestTypes, setUpcomingPreviousTestTypes] =
    useState("Upcoming Tests");

  const [activeTab, setActiveTab] = useState(
    otsPlannerData?.is_paid_planner == 0 ? "spsFree" : "spsPremium"
  );

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const renderHeaderText = (planner) => {
    return <p>{planner}</p>;
  };

  const handleChangeCategory = (event, testCategory) => {
    setPlannerTestType(testCategory);
    setOtsPremiumData([]);
    setOtsFreeData([]);
  };

  const handleChangeTestType = (e) => {
    setOtsPremiumData([]);
    setOtsFreeData([]);

    setUpcomingPreviousTestTypes(e.target.value);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);

  return (
    <>
      <div className="OtsTestListWrapper">
        <div className="OtsTestListInnerWrapper">
          {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <Mobile_header_component
                userPreference={userPreference}
                userData={userData}
              />
              <Mobile__right_sideBar_component history={history} />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_dashboard_left_wrapper">
                {/* <LeftSideBarComponent /> */}
              </div>
              <div className="user_dashboard_right_wrapper">
                <HeaderComponent history={history} />
              </div>
            </Fragment>
          )}
          {/* <HeaderComponent history={history} /> */}
          <div className="OtsTestList">
            <div className="container">
              <div className="ots_list_wrapper">
                <div className="ots_list_header_wrapper">
                  <div className="ots_header">
                    <Link
                      className="backBtn"
                      to={{
                        pathname: "/ots",
                        state: { tab: "ots" },
                      }}
                    >
                      <img src={images.m2_backBtn} alt="backBtn" />
                    </Link>

                    <div className="ots_test_list_heading_text">
                      {renderHeaderText(otsPlannerData?.planner_type)}
                    </div>
                  </div>
                  <div className="ots_test-categories">
                    {testCategories?.map((element, index) => {
                      return (
                        <div key={index} className="ots_single_select_category">
                          <input
                            type="radio"
                            id={element.category}
                            name="category"
                            value={element.category}
                            checked={
                              element.category === plannerTestType?.category
                            }
                            onChange={(event) => {
                              handleChangeCategory(event, element);
                            }}
                          />
                          <label htmlFor={element.category}>
                            {element.category}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="ots_detail_wrapper">
                  <div className="otsTabBox">
                    <div className="ots_tab_list">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            // class="nav-link active"
                            class={`nav-link ${
                              activeTab === "spsFree" ? "active" : ""
                            }`}
                            id="spsFree-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#spsFree"
                            type="button"
                            role="tab"
                            aria-controls="spsFree"
                            aria-selected="true"
                            onClick={() => handleTabClick("spsFree")}
                          >
                            Free Test
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            // class="nav-link"
                            class={`nav-link ${
                              activeTab === "spsPremium" ? "active" : ""
                            }`}
                            id="spsPremium-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#spsPremium"
                            type="button"
                            role="tab"
                            aria-controls="spsPremium"
                            aria-selected="false"
                            onClick={() => handleTabClick("spsPremium")}
                          >
                            Premium Test
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="left_side_content_wrapper">
                    <div className="tests_type_collection_wrapper">
                      <div className="single_select_test_type">
                        <input
                          type="radio"
                          id="upcoming_tests"
                          name="test_type"
                          value="Upcoming Tests"
                          onChange={(event) => handleChangeTestType(event)}
                          checked={
                            upcomingAndpreviousTestTypes == "Upcoming Tests"
                          }
                        />
                        <label htmlFor="upcoming_tests">Upcoming Tests</label>
                      </div>
                      <div className="single_select_test_type">
                        <input
                          type="radio"
                          id="completed_tests"
                          name="test_type"
                          value="Completed Tests"
                          onChange={(event) => handleChangeTestType(event)}
                          checked={
                            upcomingAndpreviousTestTypes == "Completed Tests"
                          }
                        />
                        <label htmlFor="completed_tests">Missed Tests</label>
                      </div>
                    </div>
                  </div>

                  <div className="otsTabContent">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class={`tab-pane fade${
                          activeTab === "spsFree" ? "show active" : ""
                        }`}
                        id="spsFree"
                        role="tabpanel"
                        aria-labelledby="spsFree-tab"
                      >
                        {activeTab === "spsFree" && (
                          <FreeOtsTest
                            history={history}
                            startLoading={startLoading}
                            finishLoading={finishLoading}
                            loader={loader}
                            plannerTestType={plannerTestType}
                            otsPlannerData={otsPlannerData}
                            activeTab={activeTab}
                            upcomingAndpreviousTestTypes={
                              upcomingAndpreviousTestTypes
                            }
                          />
                        )}
                      </div>
                      <div
                        // class="tab-pane fade"
                        class={`tab-pane fade${
                          activeTab === "spsPremium" ? "show active" : ""
                        }`}
                        id="spsPremium"
                        role="tabpanel"
                        aria-labelledby="spsPremium-tab"
                      >
                        {activeTab === "spsPremium" && (
                          <PremiumOtsTest
                            history={history}
                            startLoading={startLoading}
                            finishLoading={finishLoading}
                            loader={loader}
                            plannerTestType={plannerTestType}
                            otsPlannerData={otsPlannerData}
                            activeTab={activeTab}
                            upcomingAndpreviousTestTypes={
                              upcomingAndpreviousTestTypes
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer_component />
      {loader && <LoaderPopUp />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    otsPlannerData: state.otsTestsData.otsPlannerData,
    target_id: state.userPreference.targetDetails[0].target_id,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setOtsPremiumData: (payload) => {
      dispatch(otsActions.setOtsPremiumData(payload));
    },
    setOtsFreeData: (payload) => {
      dispatch(otsActions.setOtsFreeData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtsTestListComponent);
