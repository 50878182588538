import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import images from "../../../utilities/images/images";
import "./Footer.scss";
import { connect } from "react-redux";
import uiActions from "../../../redux/ui/action";
import { checkXSSAttackThroughInput } from "../../../custom_utilities/custom_useful_functions";

const Footer = (props) => {
  const { startLoading, finishLoading } = props;

  const [inputValues, setInputValues] = useState({
    userQueryDetails: {
      name: "",
      mail: "",
      mobile_number: null,
    },
  });

  const handleInputChange = async (event) => {
    const { name, value } = event.target;

    const response = await checkXSSAttackThroughInput(value);

    if (!response) {
      return;
    } else {
      setInputValues((prevState) => ({
        ...prevState,
        userQueryDetails: {
          ...prevState.userQueryDetails,
          [name]: value,
        },
      }));
    }
  };

  const url_50 =
    "https://learning.motion.ac.in/motioneducation/api/savecontact_details";

  const saveQueryApi = async (requestPayload) => {
    try {
      const response = await axios.post(url_50, requestPayload);

      if (response.data.status === 200) {
        return {
          result: 1,
          data: { message: response.data.message },
        };
      } else {
        return {
          result: 0,
          data: { message: response.data.message },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: { message: "Something went wrong!!!" },
      };
    }
  };

  const handleSubmitQueryForm = async (event) => {
    event.preventDefault();
    let { name, mail, mobile_number } = inputValues.userQueryDetails;

    let requestPayload = {
      query: name,
      email: mail,
      mobile_number: mobile_number,
    };

    startLoading();
    let returnedResponse = await saveQueryApi(requestPayload);

    if (returnedResponse.result === 1) {
      finishLoading();
      alert(returnedResponse.data.message);
      setInputValues({
        userQueryDetails: {
          name: "",
          mail: "",
          mobile_number: null,
        },
      });
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:18002121799";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@motioniitjee.com";
  };

  const handleMapClick = () => {
    window.open(
      "https://maps.google.com?q=394, Rajeev Gandhi Nagar, Kota-324005"
    );
  };

  return (
    <Fragment>
      <footer id="footer">
        <div className="sec7_wrapper">
          <div className="logo">
            <img src={images.m2_logo} alt="logo" />
          </div>
          <div className="about_contact width20">
            <h5>Motion</h5>
            <Link to="/aboutUs" className="a_link">
              <span>About us</span>
            </Link>
            <Link to="/privacyPolicy" className="a_link">
              <span>Privacy policy</span>
            </Link>
            <Link to="/terms" className="a_link">
              <span>Term and conditions</span>
            </Link>
          </div>
          <div className="about_contact">
            <h5>Get in touch</h5>
            <div className="a_link" onClick={handlePhoneClick}>
              <i className="fas fa-phone-volume phn"></i>
              <span>18002121799</span>
            </div>
            <div className="a_link" onClick={handleEmailClick}>
              <i className="fas fa-envelope"></i>
              <span>info@motioniitjee.com</span>
            </div>
            <div className="a_link" onClick={handleMapClick}>
              <i className="fas fa-map-marker-alt"></i>
              <span> 394, Rajeev Gandhi Nagar, Kota-324005</span>
            </div>
          </div>
          <div className="contact_form about_contact">
            <form onSubmit={handleSubmitQueryForm}>
              <h5>Contact Us</h5>
              <div className="input_wrapper">
                <input
                  type="text"
                  placeholder="Your name"
                  name="name"
                  value={inputValues.userQueryDetails.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input_wrapper">
                <input
                  type="email"
                  placeholder="Share your Email id"
                  name="mail"
                  value={inputValues.userQueryDetails.mail}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input_wrapper">
                <input
                  type="number"
                  placeholder="Mobile Number"
                  name="mobile_number"
                  value={inputValues.userQueryDetails.mobile_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="btn_wrapper">
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
        <div className="copyright">
          Copyright © 2019-2020 motion. All rights reserved.
        </div>
        <a
          className="upArrow"
          href="#navbar"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "/#navbar"; // Simple navigation
          }}
          alt="navbar"
        >
          <img src={images.m2_upArrow} alt="arrow" />
        </a>
      </footer>

      <footer id="mobile-footer">
        <div className="container">
          <div className="footer-content">
            <div className="company-info">
              <h3>Company</h3>
              <Link to="/aboutUs">About us</Link>
              <Link to="/privacy_policy">Privacy policy</Link>
              <Link to="/terms">Term and conditions</Link>
            </div>
            <div className="get-in-touch">
              <h3>Get in touch</h3>
              <div onClick={handlePhoneClick}>
                <i className="fas fa-phone-volume"></i>
                <span>18002121799</span>
              </div>
              <div onClick={handleEmailClick}>
                <i className="fas fa-envelope"></i>
                <span>info@motioniitjee.com</span>
              </div>
              <div onClick={handleMapClick}>
                <i className="fas fa-map-marker-alt"></i>
                <span>394, Rajeev Gandhi Nagar, Kota-324005</span>
              </div>
            </div>
            <div className="contact-form">
              <h3>Contact Us</h3>
              <form onSubmit={handleSubmitQueryForm}>
                <input
                  type="text"
                  placeholder="Your name"
                  name="name"
                  value={inputValues.userQueryDetails.name}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="email"
                  placeholder="Email Id"
                  name="mail"
                  value={inputValues.userQueryDetails.mail}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="number"
                  placeholder="Mobile Number"
                  name="mobile_number"
                  value={inputValues.userQueryDetails.mobile_number}
                  onChange={handleInputChange}
                  required
                />
                <button type="submit">Send</button>
              </form>
            </div>
          </div>
          <div className="logo">
            <img src={images.m2_logo} alt="Motion logo" />
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(Footer);
