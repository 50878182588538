import { Fragment, useEffect, useState } from "react";
import "./subject_collection_view.scss";
import images from "../../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import { backGroundColorFromBackend } from "../../../../../../custom_utilities/custom_useful_functions";
import { url_9 } from "../../../../../../custom_utilities/api_services";
//slick-carousel
import Slider from "react-slick";
//Redux
import { connect } from "react-redux";
import userActivityActions from "../../../../../../redux/user_activity/action";

const SubjectCollectionView = (props) => {
  let {
    history,
    userPreference,
    setSubjectData,
    categoryData,
    iscategorizedsubdata,
  } = props;
  let { courseDetails, classDetails } = userPreference;

  const [state, setState] = useState({
    subjectList: {},
  });

  useEffect(() => {
    getSubjectList();
  }, []);

  const getSubjectList = async () => {
    let data = {
      course_id: courseDetails[0]?.course_id,
      class_id: classDetails?.class_id,
    };
    try {
      const response = await axios.post(url_9, data);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
        props.dispatch(
          userActivityActions.setIsCategorizedSub(response.data.data)
        );

        props.dispatch(
          userActivityActions.setCategoryData({
            category: response.data.category,
            is_categorized_sub: response.data.is_categorized_sub,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderSubjectImage = (icon) => {
    if (icon) {
      return <img src={icon} alt="" />;
    } else {
      return <img src={images.user_dashboard_5} alt="" />;
    }
  };

  const handleRedirect = (type, element) => {
    switch (type.toLowerCase()) {
      case "all":
        history.push("/subject_component");
        break;
      case "subject":
        if (iscategorizedsubdata?.is_categorized_sub === 1) {
          // For categorized subjects, set the selected category
          props.setSelectedCategory(element.id);
          
          setSubjectData({
            categoryId: element.id,
            subjectId: element.subject?.[0]?.sub_id || "",
            subjectName: element.subject?.[0]?.sub_name || "",
            subjectIcon: element.subject?.[0]?.icon || "",
            subjects: element.subject || [],
            categoryName: element.name,
          });
          
          history.push({
            pathname: "/subject_component",
            state: { categoryId: element.id },
          });
        } else {
          // For non-categorized subjects
          setSubjectData({
            subjectId: element.sub_id,
            subjectName: element.sub_name,
            subjectIcon: element.icon,
          });
          
          // Clear any previous category selection
          props.setSelectedCategory("");
          
          history.push({
            pathname: "/subject_component",
          });
        }
        break;
      default:
        break;
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className="subjectCollectionView_wrapper">
        <div className="subjectCollectionView_inner_wrapper">
          <div className="container">
            <div className="subject_collection">
              <div className="subject_collection_desktop">
                <Slider {...settings}>
                  {iscategorizedsubdata?.is_categorized_sub === 1
                    ? categoryData?.map((element, index) => (
                        <div
                          className="home_page_single_subject_box"
                          key={element?.sub_id}
                        >
                          <div
                            className="home_page_single_subject"
                            onClick={() => handleRedirect("subject", element)}
                            style={backGroundColorFromBackend(
                              element.color_code
                            )}
                          >
                            <div className="home_page_image_wrapper">
                              {renderSubjectImage(element?.icon)}
                            </div>
                            <div className="home_page_text_content_wrapper">
                              <p className="home_page_text_content">
                                {element?.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    : !isEmpty(state.subjectList) &&
                      state?.subjectList?.subject?.length > 0 &&
                      state?.subjectList?.subject.map((subject, index) => (
                        <div
                          className="home_page_single_subject_box"
                          key={subject?.sub_id}
                        >
                          <div
                            className="home_page_single_subject"
                            onClick={() => handleRedirect("subject", subject)}
                            style={backGroundColorFromBackend(
                              subject.color_code
                            )}
                          >
                            <div className="home_page_image_wrapper">
                              {renderSubjectImage(subject?.icon)}
                            </div>
                            <div className="home_page_text_content_wrapper">
                              <p className="home_page_text_content">
                                {subject?.sub_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    categoryData: state.userActivity.categoryData,
    iscategorizedsubdata: state.userActivity.is_categorized_sub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubjectData: (payload) => {
      dispatch(userActivityActions.setSubjectData(payload));
    },
    setSelectedCategory: (payload) => {
      dispatch(userActivityActions.setSelectedCategory(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectCollectionView);
