import React, { useEffect, useState } from "react";
import images from "../../../utilities/images/images";
import axios from "axios";
import { url_260 } from "../../../custom_utilities/api_services";
import { useHistory } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import DateRangePicker from "./ReferAndEarnModals/DateRangePicker";
import { connect } from "react-redux";
import uiActions from "../../../redux/ui/action";
import { Button, Modal } from "react-bootstrap";
import { withRupeeSymbol } from "../../../custom_utilities/custom_useful_functions";
import "./Streak.scss";
import { toastifyMessage } from "../../../custom_utilities/toastify_config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header_component from "../structure_components/desktop_screens/header_component/header_component";

const Streak = (props) => {
  const { tokenType, token } = props;
  const [state, setState] = useState({
    streaksData: [],
    encUpi: "",
    encBank: {
      acc_name: "",
      ifsc_code: "",
      acc_no: "",
      cacc_no: "",
    },
  });

  const [openAccordion, setOpenAccordion] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dayWiseStreakData, setDayWiseStreakData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false);
  const [showRedeemConfirmModal, setShowRedeemConfirmModal] = useState(false);
  const [showRedemptionSuccessModal, setShowRedemptionSuccessModal] =
    useState(false);
  const [showFinalSuccessModal, setShowFinalSuccessModal] = useState(false);
  const [upiError, setUpiError] = useState("");
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);

  // Handlers
  const handleShowErrorModal = () => setShowError(true);
  const handleCloseErrorModal = () => setShowError(false);
  const handleShowBankDetailsModal = () => setShowBankDetailsModal(true);
  const handleCloseBankDetailsModal = () => setShowBankDetailsModal(false);
  const history = useHistory();

  useEffect(() => {
    streaksApi();
    getDayWiseStreak();
  }, []);

  useEffect(() => {
    // Only update state if streaksData has values and current state doesn't
    if (state?.streaksData?.payment_account_info) {
      const updates = {};

      // Check for UPI data
      if (
        state.streaksData.payment_account_info.enc_upi?.upi_id &&
        !state.encUpi
      ) {
        updates.encUpi = state.streaksData.payment_account_info.enc_upi.upi_id;
      }

      // Check for bank data
      if (state.streaksData.payment_account_info.enc_bank) {
        const bankData = state.streaksData.payment_account_info.enc_bank;

        // Only update encBank if we have all required bank fields
        if (
          bankData.acc_name &&
          bankData.ifsc_code &&
          bankData.acc_no &&
          bankData.cacc_no
        ) {
          updates.encBank = {
            acc_name: bankData.acc_name,
            ifsc_code: bankData.ifsc_code,
            acc_no: bankData.acc_no,
            cacc_no: bankData.cacc_no,
          };
        }
      }

      // Only update state if we have changes to make
      if (Object.keys(updates).length > 0) {
        setState((prevState) => ({
          ...prevState,
          ...updates,
        }));
      }
    }
  }, [state.streaksData]);
  const streaksApi = async () => {
    try {
      const response = await axios.get(url_260);
      if (response.data.status === 200) {
        setState((prevState) => {
          return {
            ...prevState,
            streaksData: response.data.data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const getDayWiseStreak = async (fromDate, toDate) => {
    const startDateToUse = fromDate || startDate;
    const endDateToUse = toDate || endDate;

    props.startLoading();

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let apiUrl =
      "https://learning.motion.ac.in/motioneducation/api/v1/getDayWiseStreak";

    if (startDateToUse && endDateToUse) {
      apiUrl += `?from_date=${startDateToUse}&to_date=${endDateToUse}`;
    }

    try {
      const response = await axios.get(apiUrl, { headers });
      if (response.data && response.data.status === 200) {
        setDayWiseStreakData(response.data.data || []);
        if (
          response.data.fromDate &&
          response.data.toDate &&
          !fromDate &&
          !toDate
        ) {
          setStartDate(response.data.fromDate);
          setEndDate(response.data.toDate);
        }

        if (response.data.data && response.data.data.day_wise_streak) {
          setState((prevState) => ({
            ...prevState,
            streaksData: {
              ...prevState.streaksData,
              day_wise_streak:
                response.data.data.day_wise_streak ||
                prevState.streaksData.day_wise_streak,
            },
          }));
        }
      }
    } catch (error) {
      console.log("Error fetching day wise streak:", error);
    } finally {
      props.finishLoading();
    }
  };

  const handleShowRedeemConfirmModal = () => {
    if (state.encUpi.trim() === "" && state.encBank.acc_no.trim() === "") {
      handleShowErrorModal();
      return;
    }
    setShowError(false);
    setShowRedemptionSuccessModal(true);
  };

  const handleFinalRedeem = async () => {
    handleCloseRedemptionSuccessModal();
    // setShowFinalSuccessModal(true);
    await getTransactionLists(1);
  };

  const handleShowRedemptionSuccessModal = async () => {
    handleCloseRedeemConfirmModal();
    // setShowFinalSuccessModal(true);
    await getTransactionLists(2);
  };

  const handleCloseRedeemConfirmModal = () => {
    setShowRedeemConfirmModal(false);
  };

  const handleModifyRedemption = () => {
    handleCloseRedemptionSuccessModal();
    setShowBankDetailsModal(true);
  };

  const handleModifyUpiRedemption = () => {
    handleCloseRedemptionSuccessModal();
    setShowError(true);
  };

  const handleCloseRedemptionSuccessModal = () =>
    setShowRedemptionSuccessModal(false);

  const handleCloseFinalSuccessModal = async () => {
    setShowFinalSuccessModal(false);
    await getTransactionLists();
    // window.location.reload();
  };

  const validateUpiId = (value) => {
    // Example regex pattern for UPI ID validation
    const upiIdPattern = /^[\w\.-]+@[a-zA-Z]{2,4}$/;
    return upiIdPattern.test(value);
  };

  const handleUpiChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      encUpi: value,
    }));

    // Validation for the UPI ID
    if (value.trim() === "") {
      setUpiError(""); // Clear error if field is empty
    } else if (validateUpiId(value)) {
      setUpiError(""); // Clear error if valid
    } else {
      setUpiError("Invalid UPI ID format."); // Set error if invalid
    }
  };

  const handleAccountHolderNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setState((prevState) => ({
        ...prevState,
        encBank: {
          ...prevState.encBank,
          acc_name: value,
        },
      }));
    }
  };

  const handleIfscCodeChange = (e) => {
    const value = e.target.value.toUpperCase();
    setState((prevState) => ({
      ...prevState,
      encBank: {
        ...prevState.encBank,
        ifsc_code: value,
      },
    }));
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setState((prevState) => ({
      ...prevState,
      encBank: {
        ...prevState.encBank,
        acc_no: value,
      },
    }));
  };

  const handleConfirmAccountNumberChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setState((prevState) => ({
      ...prevState,
      encBank: {
        ...prevState.encBank,
        cacc_no: value,
      },
    }));
  };

  const isSaveButtonDisabled = () => {
    return upiError?.length > 0 || state?.encUpi?.trim() === "";
  };

  const getTransactionLists = async (type) => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const data = {
      type,
    };

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/streak/redeem",
        data,
        { headers }
      );

      // If response status is success (200 or whatever success code you use)
      if (response.data.status === 200) {
        // Only show modal if success
        setShowFinalSuccessModal(true);
      } else {
        // Show toastify message for any non-successful response
        toastifyMessage({
          status: response.data.status,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      toastifyMessage({
        status: 500,
        message: "An error occurred while fetching data",
      });
    }
  };

  const postBankDetails = async (type) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let data = {};

    if (type === 1) {
      data = {
        upi_id: state?.encUpi,
        type,
        acc_name: "",
        ifsc_code: "",
        acc_no: "",
        cacc_no: "",
      };
    } else if (type === 2) {
      data = {
        type,
        acc_name: state.encBank?.acc_name,
        ifsc_code: state.encBank.ifsc_code,
        acc_no: state.encBank.acc_no,
        cacc_no: state.encBank.cacc_no,
        upi_id: "",
      };
    }

    try {
      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/streak/update/accdata",
        data,
        { headers }
      );
      if (response.data.status && type === 2) {
        setShowRedeemConfirmModal(true);
      } else {
        setShowRedemptionSuccessModal(true);
      }
    } catch (error) {
      console.log(error);
      handleShowErrorModal();
    }
  };

  // Handle withdraw button click
  const handleWithdrawClick = () => {
    handleShowErrorModal();
  };

  return (
    <>
      <ToastContainer />
      <Header_component />
      <div className="streak-container">
        <main className="content">
          <div className="back-button" style={{ marginBottom: "10px" }}>
            <ArrowLeft
              size={20}
              onClick={handleBackClick}
              style={{ cursor: "pointer" }}
            />
            <span style={{ marginLeft: "15px" }}>Streaks</span>
          </div>
          <div className="main-content">
            <div style={{ padding: "13px 43px 12px 24px" }}>
              <div className="stats">
                <div className="stats__card stats__card--streaks">
                  <div className="stats__card__icon">
                    <img src={images.fireIcon} alt="fire_icon" />
                  </div>
                  <div className="stats__card__content">
                    <span className="stats__card__content__value">
                      {state?.streaksData?.total_streak}
                    </span>
                    <span className="stats__card__content__label">Streaks</span>
                  </div>
                </div>

                <div className="stats__card stats__card--earned">
                  <div className="stats__card__icon">
                    <img src={images.moneyIcon} alt="money_icon" />
                  </div>
                  <div className="stats__card__content">
                    <span className="stats__card__content__value">
                      ₹{state?.streaksData?.money_earned}
                    </span>
                    <span className="stats__card__content__label">Earned</span>
                  </div>
                </div>
              </div>
            </div>

            <section className="streaks">
              <h3 className="streaks__title">Day wise streaks</h3>
              <div className="streaks__timeline">
                <div className="streaks__days">
                  {state?.streaksData?.day_wise_streak?.map((streak, index) => {
                    const nextStreak =
                      state?.streaksData?.day_wise_streak[index + 1];

                    const getLineColor = () => {
                      if (!nextStreak) return streak.card_color;

                      if (
                        streak.card_color === "#E23838" &&
                        nextStreak.card_color === "#E23838"
                      ) {
                        return "#E23838"; // Keep the red color
                      }
                      if (
                        streak.card_color === "#C5C5C5" &&
                        nextStreak.card_color === "#C5C5C5"
                      ) {
                        return "#C5C5C5"; // Keep the gray color
                      }
                      return "#E5E7EB";
                    };

                    return (
                      <div key={index} className="streaks__day">
                        <div
                          className="streaks__day-circle"
                          style={{
                            backgroundColor: streak.card_color,
                            color: "#FFFFFF",
                            position: "relative",
                          }}
                        >
                          {streak.day}
                          {nextStreak && (
                            <div
                              style={{
                                position: "absolute",
                                left: "100%",
                                top: "50%",
                                width: "12px",
                                height: "2px",
                                backgroundColor: getLineColor(),
                                transform: "translateY(-50%)",
                              }}
                            />
                          )}
                        </div>
                        <span className="streaks__day-amount">
                          ₹{streak.money}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
          <div className="activity__header">
            <h3 className="activity__header-title mt-4">My Activity</h3>
            <span className="activity__header-subtitle">Till Date</span>
          </div>
          <div className="main-content1">
            <section className="activity">
              <div className="activity__grid">
                {state?.streaksData?.activity &&
                state.streaksData.activity.length > 0 ? (
                  state.streaksData.activity.map((activity, index) => (
                    <div key={index} className="activity__card">
                      <img
                        alt={activity.iconClass}
                        className={`activity__card-icon activity__card-icon--${activity.iconClass}`}
                        src={activity.icon}
                      />
                      <div className="activity__card-content-title">
                        {activity.title}
                      </div>
                      <div className="activity__card-content-value">
                        {activity.count}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="activity__no-data">
                    No activity data available.
                  </div>
                )}
              </div>
            </section>
          </div>
        </main>
        <aside className="sidebar">
          <div className="sidebar__withdraw">
            <div>
              <p className="sidebar__withdraw-amount">
                ₹{state?.streaksData?.withdraw_available}
              </p>
              <p className="sidebar__withdraw-text">will be able to withdraw</p>
            </div>
            <button
              className="sidebar__withdraw-button"
              disabled={state?.streaksData?.withdraw_available <= 0}
              onClick={handleWithdrawClick}
            >
              Withdraw
            </button>
          </div>

          <p className="sidebar__withdraw-info">
            <img src={images.user_activity_19} alt="fire_icon" />
            <span className="sidebar__withdraw-infotxt">
              The withdraw option will be available once a week after completing
              the weekly streak.
            </span>
          </p>

          <button
            className="sidebar__transactions"
            onClick={() => history.push("/transcation")}
          >
            View Transactions
          </button>

          <div className="sidebar__activity">
            <div className="sidebar__activity-header">
              <span className="sidebar__activity-daytext">
                Day wise activity
              </span>
              <DateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onConfirm={(fromDate, toDate) => {
                  getDayWiseStreak(fromDate, toDate);
                }}
              />
            </div>
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="day-wise-activity">
                {dayWiseStreakData.length > 0 ? (
                  dayWiseStreakData.map((streak, index) => (
                    <div
                      key={index}
                      className={`day-wise-activity-cards ${
                        openAccordion === index ? "expanded" : ""
                      }`}
                    >
                      <div
                        className="sidebar__activity-day"
                        onClick={() => toggleAccordion(index)}
                      >
                        <div
                          className={`sidebar__activity-day-label ${
                            streak.is_fulfilled
                              ? "sidebar__activity-day-label--active"
                              : "sidebar__activity-day-label--inactive"
                          }`}
                          style={{
                            backgroundColor: streak.card_color,
                            color: "#fff",
                          }}
                        >
                          Day {streak.day}
                        </div>
                        <div className="sidebar__activity-day-date">
                          Date: {streak.date}
                        </div>
                        <img
                          className={`chevron-icon ${
                            openAccordion === index ? "rotate" : ""
                          }`}
                          src={images.down_ar}
                          alt=""
                        />
                      </div>

                      {openAccordion === index && streak.day_activity && (
                        <div className="activity-content__items">
                          <div className="activity-content__headers">
                            <div className="activity-content-heading">
                              Activity:
                            </div>
                            <div className="activity-content-heading">
                              Amount:
                            </div>
                          </div>

                          <div className="activity-content__row">
                            <div className="activity-content__activities">
                              {streak.day_activity.map(
                                (activity, activityIndex) => (
                                  <div
                                    key={activityIndex}
                                    className="activity-content__item"
                                  >
                                    <img
                                      style={{ width: "14px", height: "14px" }}
                                      src={
                                        activity.is_right === null
                                          ? images.streak_day_wise_null
                                          : activity.is_right
                                          ? images.user_activity_59
                                          : images.user_activity_60
                                      }
                                      alt="Activity Status"
                                    />
                                    <span className="activity-content__item-title">
                                      {activity.title}
                                      {activity.title === "Live Class" && (
                                        <img
                                          style={{
                                            marginLeft: "8px",
                                            height: "16px",
                                            width: "16px",
                                          }}
                                          src={images.user_activity_19}
                                          alt="fire_icon"
                                          onClick={() =>
                                            setShowAttendanceModal(true)
                                          }
                                        />
                                      )}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="activity-content__amount">
                              <span className="activity-content__price">
                                ₹{streak.money}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="no-streaks">No streaks available</p>
                )}
              </div>
            </div>
          </div>
        </aside>
      </div>
      <Modal
        show={showError}
        onHide={handleCloseErrorModal}
        centered
        className="upi-details-modal"
      >
        <Modal.Header>
          <Modal.Title>Enter UPI Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postBankDetails(1);
            }}
          >
            <div className="form-group">
              <label htmlFor="upiId">
                UPI ID <span className="required">*</span>
              </label>

              <input
                type="text"
                id="upiId"
                placeholder="Enter UPI ID"
                value={state.encUpi}
                onChange={handleUpiChange}
                className={upiError ? "error-input" : ""}
              />

              {upiError?.length > 0 && <p className="error-text">{upiError}</p>}
            </div>
            <p>
              Don't have UPI ID?{" "}
              <button
                type="button"
                className="add-bank-details"
                onClick={() => {
                  handleCloseErrorModal();
                  handleShowBankDetailsModal();
                }}
              >
                Add Bank Details
              </button>
            </p>
            <div className="button-group">
              <Button
                variant="outline-primary"
                onClick={() => {
                  if (!isSaveButtonDisabled()) {
                    handleShowRedeemConfirmModal();
                    postBankDetails(1);
                  }
                }}
                disabled={isSaveButtonDisabled()}
              >
                Save & Continue
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showBankDetailsModal}
        onHide={handleCloseBankDetailsModal}
        centered
        className="bankDetailsModal"
      >
        <Modal.Header>
          <Modal.Title>Enter Bank Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postBankDetails(2);
            }}
          >
            <div className="form-group">
              <label htmlFor="accountHolderName">
                Account Holder Name <span className="required">*</span>
              </label>
              <input
                type="text"
                id="accountHolderName"
                placeholder="Full Name"
                value={state?.encBank?.acc_name}
                onChange={handleAccountHolderNameChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="ifscCode">
                IFSC Code <span className="required">*</span>
              </label>
              <input
                type="text"
                id="ifscCode"
                placeholder="ABCD1211336"
                value={state?.encBank?.ifsc_code}
                onChange={handleIfscCodeChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="accountNumber">
                Account Number <span className="required">*</span>
              </label>
              <input
                type="text"
                id="accountNumber"
                placeholder="0000000000"
                value={state?.encBank?.acc_no}
                onChange={handleAccountNumberChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmAccountNumber">
                Confirm Account Number <span className="required">*</span>
              </label>
              <input
                type="text"
                id="confirmAccountNumber"
                placeholder="0000000000"
                value={state?.encBank?.cacc_no}
                onChange={handleConfirmAccountNumberChange}
              />
            </div>
            <div className="button-group">
              <Button
                variant="outline-primary"
                type="button"
                onClick={() => {
                  handleCloseBankDetailsModal();
                  postBankDetails(2);
                }}
              >
                Save & Continue
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRedeemConfirmModal}
        onHide={handleCloseRedeemConfirmModal}
        centered
        className="redeem-confirm-modal"
      >
        <Modal.Header>
          <Modal.Title>Verify your details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="confirm-text">
            Please confirm your cash reward redemption details.
          </p>
          <div className="detail-row p-2">
            <span>Coins Value</span>
            <span className="value">
              {withRupeeSymbol(state?.streaksData?.withdraw_available)}
            </span>
          </div>
          <div className="detail-row p-2">
            <span>Account Number</span>
            <span className="value">
              {state?.streaksData?.payment_account_info?.enc_bank?.acc_no}
            </span>
          </div>
          <div className="detail-row gray p-2">
            <span>IFSC Code</span>
            <span className="value">
              {state?.streaksData?.payment_account_info?.enc_bank?.ifsc_code}
            </span>
          </div>
          <div className="detail-row p-2">
            <span>Name</span>
            <span className="value">
              {state?.streaksData?.payment_account_info?.enc_bank?.acc_name}
            </span>
          </div>
          <div className="button-group">
            <Button variant="outline-primary" onClick={handleModifyRedemption}>
              Modify
            </Button>
            <Button
              variant="primary"
              onClick={handleShowRedemptionSuccessModal}
            >
              Redeem
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRedemptionSuccessModal}
        onHide={handleCloseRedemptionSuccessModal}
        centered
        className="redemption-success-modal"
      >
        <Modal.Header>
          <Modal.Title>Your Redemption is Successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="confirm-text">
            Please confirm your cash reward redemption details.
          </p>
          <div className="detail-row p-2">
            <span>Coins Value</span>
            <span className="value">
              {withRupeeSymbol(state?.streaksData?.withdraw_available)}
            </span>
          </div>
          <div className="detail-row gray p-2">
            <span>UPI ID</span>
            <span className="value">
              {state?.streaksData?.payment_account_info?.enc_upi?.upi_id}
            </span>
          </div>
          <div className="button-group">
            <Button
              variant="outline-primary"
              onClick={handleModifyUpiRedemption}
            >
              Modify
            </Button>
            <Button variant="primary" onClick={handleFinalRedeem}>
              Redeem
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFinalSuccessModal}
        onHide={handleCloseFinalSuccessModal}
        centered
        className="final-success-modal"
      >
        <Modal.Body className="text-center">
          <div className="success-icon">
            <img src={images.successfulIcon} alt="Success" />
          </div>
          <h2>Your Redemption is Successful!</h2>
          <p>
            Your amount will be credited to your bank
            <br />
            account within 48 working hours.
          </p>
          <Button onClick={handleCloseFinalSuccessModal}>Ok</Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAttendanceModal}
        onHide={() => setShowAttendanceModal(false)}
        centered
        className="attendance-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="attendance-info">
            <div className="attendance-section">
              <div className="attendance-header">
                <div className="header-left">
                  <p className="label">Class:</p>
                  <p className="value">Chemical Kinetics_Lec_10</p>
                </div>
                <div className="header-right">
                  <p className="label">Date & Time:</p>
                  <p className="value">
                    03-02-2025, 9:00 AM to 10:00 AM (110 Mins)
                  </p>
                </div>
              </div>
              <div className="attendance-header">
                <div className="header-left">
                  <p className="label">Total Attended Duration:</p>
                  <p className="value">1 hr 20 mins</p>
                </div>
                <div className="header-right">
                  <p className="label">Total Polls Attended:</p>
                  <p className="value">2</p>
                </div>
              </div>
              <table className="attendance-table">
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Join Time</th>
                    <th>Left Time</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Session 1</td>
                    <td>09:10:57</td>
                    <td>09:50:07</td>
                    <td>40m 10s</td>
                  </tr>
                  <tr>
                    <td>Session 2</td>
                    <td>09:10:57</td>
                    <td>09:50:07</td>
                    <td>40m 10s</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* The modal shows the same information twice in the screenshot */}
            <div className="attendance-section">
              <div className="attendance-header">
                <div className="header-left">
                  <p className="label">Class:</p>
                  <p className="value">Chemical Kinetics_Lec_10</p>
                </div>
                <div className="header-right">
                  <p className="label">Date & Time:</p>
                  <p className="value">
                    03-02-2025, 9:00 AM to 10:00 AM (110 Mins)
                  </p>
                </div>
              </div>
              <div className="attendance-header">
                <div className="header-left">
                  <p className="label">Total Attended Duration:</p>
                  <p className="value">1 hr 20 mins</p>
                </div>
                <div className="header-right">
                  <p className="label">Total Polls Attended:</p>
                  <p className="value">2</p>
                </div>
              </div>
              <table className="attendance-table">
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Join Time</th>
                    <th>Left Time</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Session 1</td>
                    <td>09:10:57</td>
                    <td>09:50:07</td>
                    <td>40m 10s</td>
                  </tr>
                  <tr>
                    <td>Session 2</td>
                    <td>09:10:57</td>
                    <td>09:50:07</td>
                    <td>40m 10s</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.authToken,
    tokenType: state.auth.authType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Streak);
