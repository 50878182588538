import { createSlice } from "@reduxjs/toolkit";

const HomeWorkSlice = createSlice({
  name: "homeworkData",

  initialState: {
    homeworkTestInstruction: {},
    homeworkData: [],
    homeworkTestDetails: {},
  },
  reducers: {
    setHomeworkTestInstruction: (state, action) => {
      console.log("action.payload", action.payload);
      state.homeworkTestInstruction = action.payload;
    },

    setHomeworkData: (state, action) => {
      console.log("action.payload-homework-data", action.payload);
      state.homeworkData = action.payload;
    },

    setHomeworkTestDetails: (state, action) => {
      console.log("action.payload-homeworkTestDetails", action.payload);
      state.homeworkTestDetails = action.payload;
    },
  },
});

export const {
  setHomeworkTestInstruction,
  setHomeworkData,
  setHomeworkTestDetails,
} = HomeWorkSlice.actions;
export default HomeWorkSlice.reducer;
