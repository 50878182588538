import React, { useState } from "react";
import QuestionStatusLabel, {
  QuestionsGridView,
} from "./questionStatusLabel/QuestionStatusLabel";
import "./QuestionListPanel.scss";
import GridIcon from "../../../../../../../../utilities/images/test-screens/GridIcon";
import ListIcon1 from "../../../../../../../../utilities/images/test-screens/ListIcon1";
import GridIcon1 from "../../../../../../../../utilities/images/test-screens/GridIcon1";
import ListIcon from "../../../../../../../../utilities/images/test-screens/ListIcon";
import { useLocation } from "react-router-dom";

const QuestionListPanel = ({
  handleSaveQuestion,
  handleShow,
  questionsCollectionRef,
  testQuestionsDetails,
  currentQuestion,
  handleQuestionJump,
  handlePurticularQuestion2,
  lang,
  ConvertStringToHTML,
  toggleMenu,
  setToggleMenu,
}) => {
  const [isList, setisList] = useState(true);
  const location = useLocation();
  let package_name = location.state.package_name;

  return (
    <div
      className={`allQuestionList ${toggleMenu ? "allQuestionListToggle" : ""}`}
    >
      <div className="sub_section_1_wrapper">
        <div className="sub_section_heading">
          <div className="text_content_wrapper">
            <p className="text_content"> {package_name ?? "Test"}</p>
            <button
              onClick={() => {
                handleSaveQuestion();
                handleShow();
              }}
            >
              Submit
            </button>
          </div>

          <div className="sub_section_2_wrapper">
            <div className="section_2_1">
              <p className="allQuestionsText">All Questions</p>
              <div className="gridviewIcons">
                <button onClick={() => setisList(!isList)}>
                  {isList ? <ListIcon1 /> : <ListIcon />}
                </button>
                <button onClick={() => setisList(!isList)}>
                  {isList ? <GridIcon /> : <GridIcon1 />}
                </button>
              </div>
            </div>
            <div className="question_marking_wrapper">
              <div className="single_marking">
                <label className="box_pic box_pick_background_1"></label>
                <label className="question_marking_text">Completed</label>
              </div>
              <div className="single_marking">
                <label className="box_pic box_pick_background_3"></label>
                <label className="question_marking_text">Pending</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="questions_serial_number_collection"
        ref={questionsCollectionRef}
      >
        <div
          className={` 
                  ${isList ? "" : "questions_wrapper"}`}
        >
          {testQuestionsDetails.length
            ? testQuestionsDetails.map((element, index) => {
                return (
                  <div
                    key={index}
                    className={` 
                  ${
                    isList
                      ? "excr_single_question_number_wrapper1"
                      : "excr_single_question_number_wrapper"
                  }`}
                    id="myDiv"
                  >
                    {isList ? (
                      <QuestionStatusLabel
                        question={element}
                        index={index}
                        currentQuestion={currentQuestion}
                        handleQuestionJump={handleQuestionJump}
                        handlePurticularQuestion2={handlePurticularQuestion2}
                        ConvertStringToHTML={ConvertStringToHTML}
                        lang={lang}
                        toggleMenu={toggleMenu}
                        setToggleMenu={setToggleMenu}
                      />
                    ) : (
                      <QuestionsGridView
                        question={element}
                        index={index}
                        currentQuestion={currentQuestion}
                        handleQuestionJump={handleQuestionJump}
                        handlePurticularQuestion2={handlePurticularQuestion2}
                        ConvertStringToHTML={ConvertStringToHTML}
                        lang={lang}
                        toggleMenu={toggleMenu}
                        setToggleMenu={setToggleMenu}
                      />
                    )}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default QuestionListPanel;
