import React, { useState, useEffect } from "react";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import userMockTestsAction from "../../../../../redux/mock_test_exercise/action";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import { url_146 } from "../../../../../custom_utilities/api_services";

const MockTestMain = (props) => {
  let {
    setMockTestData,
    startLoading,
    finishLoading,
    userPreference,
    setMockTestsResultData,
  } = props;

  const [state, setState] = useState({
    mockMainTestPaperList: [],
    mainsFlag: "jee-mains",
  });

  let history = useHistory();

  let { targetDetails } = userPreference;

  useEffect(() => {
    getMainTest();
  }, [state.mainsFlag]);

  const onHandleTakeTest = (testData) => {
    history.push("/mock_test_component");
    setMockTestData(testData);
  };

  const onHandleSummary = (testData) => {
    setMockTestsResultData(testData);
    history.push("/mock_test_summary");
  };

  const onHandleTakeTest2 = (testData) => {
    setMockTestData(testData);
    history.push("/mock_practice_test");
  };

  const getMainTest = async () => {
    startLoading();
    try {
      const response = await axios.post(url_146);
      if (response.data.status === 200) {
        finishLoading();
        if (targetDetails[0].target_id == "2") {
          let filteredArray = [];
          for (let i = 0; i < response.data.data.length; i++) {
            if (
              state.mainsFlag == "jee-mains" &&
              response.data.data[i].format.toLowerCase().includes("main")
            ) {
              filteredArray[i] = response.data.data[i];
            }
          }
          setState((preState) => ({
            ...preState,
            mockMainTestPaperList: filteredArray,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <>
      <div className="mockTestWrapper">
        {!isEmpty(state.mockMainTestPaperList)
          ? state.mockMainTestPaperList.length
            ? state.mockMainTestPaperList.map((element, index) => {
                let date = new Date(element.to_be_held_on);
                let day = date.getDate().toString().padStart(2, "0");
                let month = (date.getMonth() + 1).toString().padStart(2, "0");
                let year = date.getFullYear();

                let formattedDate = `${day}-${month}-${year}`;
                return (
                  <div key={index} className="mockTestBox">
                    <div className="mockTestNameType">
                      <div className="mockTestName">
                        {element.icon ? (
                          <img src={element.icon} alt="img" />
                        ) : null}
                        <span>{element.paper_name}</span>
                      </div>
                      <div className="examDate">
                        Exam Date : {formattedDate}
                      </div>
                    </div>

                    <div className="mockTestPara">
                      <p>{element.format}</p>
                    </div>

                    <div className="attemBtn">
                      {!element.is_attempted ? (
                        <button
                          className="attempt"
                          onClick={() => onHandleTakeTest(element)}
                        >
                          Take Test
                        </button>
                      ) : (
                        <>
                          <button
                            className="practice"
                            onClick={() => onHandleSummary(element)}
                          >
                            Summary
                          </button>

                          <button
                            className="practice"
                            onClick={() => onHandleTakeTest(element)}
                          >
                            Try Again
                          </button>
                        </>
                      )}
                      <button
                        className="practice"
                        onClick={() => onHandleTakeTest2(element)}
                      >
                        Practice
                      </button>
                    </div>
                  </div>
                );
              })
            : null
          : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setMockTestData: (payload) => {
      dispatch(userMockTestsAction.setMockTestsData(payload));
    },

    setMockTestsResultData: (payload) => {
      dispatch(userMockTestsAction.setMockTestsResultData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDipatchToProps)(MockTestMain);
