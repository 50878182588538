////homepage
import Homepage from "../containers/homepage/homepage";
import NewHomePage from "../containers/homepage/NewHomePage";
////login
import Login from "../containers/login/login";
////signUp
import SignUp from "../containers/signUp/signUp";
////signUp
import SignUp2 from "../containers/signUp2/login";

/// parentLogin
import ParentLoginLayout from "../containers/parent_login/ParentLoginLayout";
////////Logged User Components
import UserDashboard from "../containers/logged_user_components/user_dashboard/user_dashboard";
import UserProfile from "../containers/logged_user_components/user_profile/user_profile";
import UserEditProfile from "../containers/logged_user_components/user_edit_profile/user_edit_profile";
import UserTransactions from "../containers/logged_user_components/user_transactions/user_transactions";
import UserNotifications from "../containers/logged_user_components/user_notifications/user_notifications";
import UserAnalytics from "../containers/logged_user_components/user_analytics/user_analytics";
/////////////User Activity
import SubjectComponent from "../containers/logged_user_components/user_activity/subject_component/subject_component";
import TopicComponent from "../containers/logged_user_components/user_activity/topic_component/topic_component";
import RecordedVideoWatchComponent from "../containers/logged_user_components/user_activity/topic_component/recorded_video_watch_component";
////////features
import VideoFeature from "../containers/logged_user_components/features/video_feature/video_feature";
import LiveStreamFeature from "../containers/logged_user_components/features/live_stream_feature/live_stream_feature";
import NotesFeature from "../containers/logged_user_components/features/notes_feature/notes_feature";
import Zoom from "../containers/logged_user_components/zoom_component/zoom_component";
import LiveWebChatFeature from "../containers/logged_user_components/features/live_web_chat_feature/live_web_chat_feature";
import LiveStreamVideoFeature from "../containers/logged_user_components/features/live_stream_video_feature/live_stream_video_feature";
///////User Store
import UserStore from "../containers/logged_user_components/user_store/user_store";
import PackageOverview from "../containers/logged_user_components/user_store/user_store_content_component/store_components/package_details_component/package_overview/PackageOverview";
//////User Doubts
import UserDoubts from "../containers/logged_user_components/user_doubts/user_doubts";
import UserCreateDoubt from "../containers/logged_user_components/user_create_doubt/user_create_doubt";

////Evaluation
import ExerciseComponent from "../containers/logged_user_components/evaluation_components/exercise_related_components/exercise_Test_component/exercise_test_component";
import ExerciseComponent1 from "../containers/logged_user_components/evaluation_components/exercise_related_components/exercise_Test_component/exercise_test_component1";
import ExerciseResultComponent from "../containers/logged_user_components/evaluation_components/exercise_related_components/exercise_result_component/exercise_result_component";

import TestComponent from "../containers/logged_user_components/evaluation_components/test_related_components/test_component/test_component";
import TestResultComponent from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_component";
//pyqTest
import PyqTestComponent from "../containers/logged_user_components/evaluation_components/test_related_components/pyq_test_component/pyq_test_component";
import PyqTestComponent2 from "../containers/logged_user_components/evaluation_components/test_related_components/pyq_test__TopicWise_component/pyq_test_component";
import PyqTestComponent3 from "../containers/logged_user_components/evaluation_components/test_related_components/pyq_practiseTest_component/pyq_test_component";
import PyqQuestionPaperSolution from "../containers/logged_user_components/evaluation_components/test_related_components/question_paper_solution_components/pyq_solution_components/PyqQuestionPaperSolution";

//User Exercise Test
import UserExerciseTest from "../containers/logged_user_components/user_exercise_test/user_exercise_test";
//PYQ CPS EXAM
import PyqCpsExamComponent from "../containers/logged_user_components/PYQ_CPS_Exam/pyqCpsExamComponent/PyqCpsExam";
import PyqSubjectTopic from "../containers/logged_user_components/PYQ_CPS_Exam/pyqSubjectTopicComponent/pyqSubjectTopicContent/Pyq_subject_topic";
import CpsSubjectTopic from "../containers/logged_user_components/PYQ_CPS_Exam/cpsSubjectTopicComponent/cpsSubjectTopicContent/Cps_subject_topic";
import CpsSubjectSubTopic from "../containers/logged_user_components/PYQ_CPS_Exam/cpsSubjectSubTopicComponent/cpsSubjectSubTopicContent/Cps_subject_SubTopic";
import PyqCpsExamTest from "../containers/logged_user_components/PYQ_CPS_Exam/pyqCpsExamTestScreen/PyqCpsExamTest";
import CpsTestComponent from "../containers/logged_user_components/evaluation_components/test_related_components/cps_test_component/cps_test_component";
import CpsQuestionPaperSolution from "../containers/logged_user_components/evaluation_components/test_related_components/question_paper_solution_components/cps_solution_components/CpsQuestionPaperSolution";
//PYQ daily chalenge
import PyqDailyChallenge from "../containers/logged_user_components/Pyq_daily_challenge/Pyq_daily_challenge";
import PyqDailyTestComponent from "../containers/logged_user_components/Pyq_daily_challenge/PyqDailyTestComponent";

// OTS Tests

import OtsTestComponent from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_tests/OtsTestComponent";
import OtsTestList from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_tests/ots_test_content_component/ots_test_sps/OtsTestListComponent";
import OtsAnalytics from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/OtsAnalytics";
import OtsTestResultComponent from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots_analytics_components/OtsTestResultComponent";
import OtsSubjectTopicWise from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots_analytics_components/ots_result_components/ots_subject_topic_wise/OtsSubjectTopicWise";
import OtsPaymentComponent from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_tests/ots_test_content_component/ots_payment_components/OtsPaymentComponent";

import OtsTestContentComponent from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_tests/ots_test_content_component/ots_test_sps/OtsTestContentComponent";
import OtsAccuracyAttempted from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots_analytics_components/ots_result_components/ots_attempted_accuracy/OtsAccuracyAttempted";
import OtsDifficultyLevel from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots_analytics_components/ots_result_components/ots_difficultylevel/OtsDifficultyLevel";

import OtsBehaviour from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots_analytics_components/ots_result_components/ots_behaviour/OtsBehaviour";
import OtsQuestionPaperSolution from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots_analytics_components/ots_result_components/ots_question_paper_solution/OtsQuestionPaperSolution";

import All_page_content from "../containers/logged_user_components/static_pages/all_pageTabing/All_page_content";
import AboutUs from "../containers/logged_user_components/static_pages/about_us/about_us";
import PrivacyPolicy from "../containers/logged_user_components/static_pages/privacy_policy/privacy_policy";
import TermsNCondition from "../containers/logged_user_components/static_pages/terms_n_condition/terms_n_condition";
import Faqs from "../containers/logged_user_components/static_pages/faqs/faqs";
import ContactUs from "../containers/logged_user_components/static_pages/contact_us/contact_us";
// User Bookmarks
//////User Bookmarks///////////////////
import UserBookmarks from "../containers/logged_user_components/user_bookmarks/user_bookmarks";
import BookmarkViewComponent from "../containers/logged_user_components/user_bookmarks/user_bookmarks_content_component/bookmark_view_component/bookmark_view_component";
import BookmarkTestViewComponent from "../containers/logged_user_components/user_bookmarks/user_bookmarks_content_component/bookmark_view_component/bookmark_view_content_component/bookmark_view_questions_view/bookmarkTestQuestionsView/BookmarkTestViewComponent";

/////////Custom Question Bank////////////////////////////////
import UserCreateCustomQuestionBank from "../containers/logged_user_components/user_create_custom_question_bank/user_create_custom_question_bank";

import SubjectTopicWise from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_content_component/test_summary_page/testWise/SubjectTopicWiseMarks";
import AttemtedAccuracy from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_content_component/test_summary_page/Attempt_Accuracy/AccuracyAttempt";
import DifficultyLevel from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_content_component/test_summary_page/Difficulty_Level/DifficultyLevel";
import Behaviour from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_content_component/test_summary_page/Behaviour/Behaviour";
import Question_paper_solution from "../containers/logged_user_components/evaluation_components/test_related_components/Question_paper_solution/QuesPaperSol_content";
import CpsSummary from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_content_component/test_summary_page/CpsSummary/CpsSummary";
import PyqSummary from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_content_component/test_summary_page/PyqSummary/PyqSummary";
// Exam Cracker
import ExamCrackerTestComponent from "../containers/logged_user_components/user_exercise_test/user_exam_cracker/examCrackerTestComponent/examCrackerTestComponent";
import ExamCrackerPaymentPage from "../containers/logged_user_components/user_exercise_test/user_exam_cracker/exam_cracker_payment_page/exam_cracker_payment_page";
import ExamCrackerSubjectTopicLists from "../containers/logged_user_components/user_exercise_test/user_exam_cracker/examCrackerSubjectTopicLists/examCrackerSubjectTopicLists";
import test_result_component from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_result_component";

// Mock tests
import Mock_test from "../containers/logged_user_components/user_exercise_test/user_mock_test/mock_test";
import MockTestComponent from "./../containers/logged_user_components/user_exercise_test/user_mock_test/mockTestComponent/mockTestComponent";
import MockTestSummary from "../containers/logged_user_components/user_exercise_test/user_mock_test/mockTestComponent/mockTestContentComponent/mockTestSummary/mockTestSummary";
import MockQuestionPaperSolution from "../containers/logged_user_components/evaluation_components/test_related_components/question_paper_solution_components/mock_solution_components/MockQuestionPaperSolution";
// import MockPracticeTest from "../containers/logged_user_components/user_exercise_test/user_mock_test/mockTestComponent/mockTestContentComponent/mockPracticeTest/mockPracticeTest";

import MockPracticeTestComponent from "../containers/logged_user_components/user_exercise_test/user_mock_test/mockPracticeTestComponent/mockPracticeTestComponent";
/////////////// Bit_sat ////////////////////////////////////
import BitSatTestComponent from "./../containers/logged_user_components/user_exercise_test/user_bit_sat/bitSatTestComponent/bitSatTestComponent";
import BitSatSummary from "../containers/logged_user_components/user_exercise_test/user_bit_sat/bitSatTestComponent/bitSatTestContentComponent/bitSatSummary/bitSatSummary";
import BitSatQuestionPaperSolution from "../containers/logged_user_components/user_exercise_test/user_bit_sat/bitSatTestComponent/bitSatTestContentComponent/bitSatQuestionPaperSolution/bitSatQuestionPaperSolution";
import CcAvenueResponse from "../custom_utilities/ccavence_payment/CcAvenueResponse";

//////////////// Question Bank //////////////////////////////////
import QuestionBank from "../containers/logged_user_components/user_questions_bank/QuestionBank";
import QuestionBankCreateForm from "../containers/logged_user_components/user_questions_bank/QuestionBankCreatePaper/QuestionBankCreateForm";
import QuestionBankTestComponent from "../containers/logged_user_components/user_questions_bank/question_bank_test_components/QuestionBankTestComponent";
import QuestionBankSolution from "../containers/logged_user_components/user_questions_bank/question_bank_solution/QuestionBankSolution";
import OtsSummary from "../containers/logged_user_components/user_exercise_test/user_ots_test_series/ots_analytics/ots-summary/OtsSummary";

////////////////  Adaptive Cps /////////////////////////////////////
// import AdaptiveCpsInstruction from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/test-components/instruction-components/AdaptiveCpsTestInstructionView";
// import AdaptiveCps from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/AdaptiveCps";
import AdaptiveCpsTest from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/test-components/AdaptiveCpsTest";
import AdaptiveCpsTestResults from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/test-components/test-results-components/AdaptiveCpsTestResults";
// import AdaptiveCpsSubjects from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/home-components/AdaptiveCpsSubjects";
// import AdaptiveCpsContent from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/AdaptiveCpsContent";
import AdaptiveCpsContent from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/home-components/adaptiveCpsContent/AdaptiveCpsContent";
import AdaptiveCpsViewSolution from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/home-components/adaptiveCpsViewSolution/AdaptiveCpsViewSolution";

// attendance
// import StudentAttendance from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/components/user_attendance/StudentAttendance.js";
import StudentAttendanceContent from "../parent/user-attendance/StudentAttendanceContent";
import StudentActivityContent from "../parent/studentActivity/StudentActivityContent";
/// store new
import CartCollection from "../containers/logged_user_components/user_store/user_store_content_component/handBooks/cart_collection/CartCollection";
import CartDetails from "../containers/logged_user_components/user_store/user_store_content_component/handBooks/cart_details/CartDetails";

import WishListCollection from "../containers/logged_user_components/user_store/user_store_content_component/handBooks/cart_details/wish_list_collection/WishListCollection";

import QuestionViewComponent from "../parent/tests/testsContentComponent/questionWiseAnalysis/questionViewComponent/QuestionViewComponent";

import Pdf from "../parent/studentTestReportPdf/Pdf";

/////////////////////////  PARENT ROUTES //////////////////////////

import Parent from "../parent/Parent";

import Tests from "../parent/tests/Tests";

/////////////////////////  PARENT ROUTES //////////////////////////

// import Parent from "../parent/containers/parent_dashboard_components/Parent";
// // import Tests from "../parent/containers/parent_dashboard_components/tests/Tests";
// import Tests from "../parent/containers/parent_dashboard_components/tests/Tests";
import Wallet from "../containers/logged_user_components/Wallet/WalletComponent/Wallet";
import ReferAndEarn from "../containers/logged_user_components/user_refer_earn/ReferAndEarn";
import ExamTestSummary from "../containers/logged_user_components/evaluation_components/test_related_components/test_result_component/test_summary/ExamTestSummary";
import AdaptiveCps from "../containers/logged_user_components/user_exercise_test/user_adaptive_cps/AdaptiveCps";
import StreamingVideo from "../containers/logged_user_components/user_dashboard/user_dashboard_content_component/dashboard_utlility_components/schedule_class/streaming_video/StreamingVideo";
import LoginSignupModal from "../containers/homepage/LoginSignupModal/LoginSignupModal";

import PrivacyPolicyComponent from "../containers/homepage/Footer/PrivacyPolicyComponent";

import PackageDetailsComponent from "../containers/logged_user_components/user_store/user_store_content_component/store_components/package_details_component/package_details_component";
import PackagesOverview from "../containers/homepage/Store/PackagesOverview/PackagesOverview";
import HandBookDetail from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/handbookdetail/HandBookDetail";
import CartAndPaymentPage from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/handbookcart/CartAndPaymentPage";
import HandbookAddress from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/handbookaddress/HandbookAddress";
import Address from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/handbookaddress/Address";
import OrderStatus from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/orderStatus/OrderStatus";
import PaymentWebView from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/payment/PaymentWebView";
import CcAvenueHandbookResponse from "../containers/logged_user_components/user_store/user_store_content_component/store_components/handbook/payment/CcAvenueHandbookResponse";
import Streak from "../containers/logged_user_components/user_refer_earn/Streak";
import Transaction from "../containers/logged_user_components/user_refer_earn/Transcation";
import HomeWork from "../containers/logged_user_components/user_exercise_test/user_homework/HomeWork";
import HomeworkInstruction from "../containers/logged_user_components/user_exercise_test/user_homework/homework_data/homework_test/HomeworkInstruction";
import HomeworkTest from "../containers/logged_user_components/user_exercise_test/user_homework/homework_data/homework_test/HomeworkTest";
import HomeworkSolution from "../containers/logged_user_components/user_exercise_test/user_homework/homework_data/homework_solution/HomeworkSolution";

import MtsResultPage from "../containers/logged_user_components/user_edit_profile/mts_result_page/MtsResultPage";

const routes = [
  {
    path: "/CpsTestComponent",
    exact: true,
    auth: false,
    component: CpsTestComponent,
  },
  // {
  //   path: "/cps-summary",
  //   exact: true,
  //   auth: false,
  //   component: CpsSummary,
  // },

  {
    path: "/cps-test-paper-solution",
    exact: true,
    auth: false,
    component: CpsQuestionPaperSolution,
  },
  {
    path: "/pyq-summary",
    exact: true,
    auth: false,
    component: PyqSummary,
  },
  {
    path: "/SubjectTopicWise",
    exact: true,
    auth: false,
    component: SubjectTopicWise,
  },
  {
    path: "/Attemted_Accuracy",
    exact: true,
    auth: false,
    component: AttemtedAccuracy,
  },
  {
    path: "/Question_paper_solution",
    exact: true,
    auth: false,
    component: Question_paper_solution,
  },
  {
    path: "/DifficultyLevels",
    exact: true,
    auth: false,
    component: DifficultyLevel,
  },
  {
    path: "/Behaviour",
    exact: true,
    auth: false,
    component: Behaviour,
  },
  {
    path: "/create_custom_question_bank",
    exact: true,
    auth: false,
    component: UserCreateCustomQuestionBank,
  },
  {
    path: "/bookmark_questions",
    exact: true,
    auth: true,
    component: BookmarkViewComponent,
  },
  {
    path: "/bookmark_test_questions",
    exact: true,
    auth: true,
    component: BookmarkTestViewComponent,
  },

  {
    path: "/user_bookmarks",
    exact: true,
    auth: true,
    component: UserBookmarks,
  },
  {
    path: "/user_analytics",
    exact: true,
    auth: true,
    component: UserAnalytics,
  },
  {
    path: "/test_analytics",
    exact: true,
    auth: true,
    component: UserAnalytics,
  },
  {
    path: "/notifications",
    exact: true,
    auth: true,
    component: UserNotifications,
  },
  {
    path: "/transactions",
    exact: true,
    auth: true,
    component: UserTransactions,
  },
  {
    path: "/contact_us",
    exact: true,
    auth: true,
    component: ContactUs,
  },
  {
    path: "/faqs",
    exact: true,
    auth: true,
    component: Faqs,
  },
  {
    path: "/terms_n_condition",
    exact: true,
    auth: true,
    component: TermsNCondition,
  },
  {
    path: "/privacy_policy",
    exact: true,
    auth: true,
    component: PrivacyPolicy,
  },
  {
    path: "/All_page_content",
    exact: true,
    auth: true,
    component: All_page_content,
  },
  {
    path: "/about_us",
    exact: true,
    auth: true,
    component: AboutUs,
  },
  {
    path: "/user_exercise_test",
    exact: true,
    auth: true,
    component: UserExerciseTest,
  },
  {
    path: "/pyq",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/cps",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/exam",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/ots",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/exam_cracker",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/bit_sat",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/mock_test",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },
  {
    path: "/Pyq_subjectTopic",
    exact: true,
    auth: true,
    component: PyqSubjectTopic,
  },
  {
    path: "/Pyq_dailyChallenge",
    exact: true,
    auth: true,
    component: PyqDailyChallenge,
  },
  {
    path: "/Pyq_daily_test_component",
    exact: true,
    auth: true,
    component: PyqDailyTestComponent,
  },
  {
    path: "/Cps_subjectTopic",
    exact: true,
    auth: true,
    component: CpsSubjectTopic,
  },
  {
    path: "/CpsSubjectSubTopic",
    exact: true,
    auth: true,
    component: CpsSubjectSubTopic,
  },
  {
    path: "/PyqCpsExamTest",
    exact: true,
    auth: true,
    component: PyqCpsExamTest,
  },
  {
    path: "/test_result",
    exact: true,
    auth: true,
    component: TestResultComponent,
  },
  {
    path: "/test",
    exact: true,
    auth: true,
    component: TestComponent,
  },
  {
    path: "/exam_summary",
    exact: true,
    auth: true,
    component: ExamTestSummary,
  },
  {
    path: "/PYQ_Test",
    exact: true,
    auth: true,
    component: PyqTestComponent,
  },
  {
    path: "/PYQ_TopicWise_Test",
    exact: true,
    auth: true,
    component: PyqTestComponent2,
  },

  {
    path: "/PYQ_Practice_Test",
    exact: true,
    auth: true,
    component: PyqTestComponent3,
  },

  {
    path: "/pyq-test-paper-solution",
    exact: true,
    auth: true,
    component: PyqQuestionPaperSolution,
  },

  {
    path: "/homework",
    exact: true,
    auth: true,
    component: HomeWork,
  },

  {
    path: "/homework-test",
    exact: true,
    auth: true,
    component: HomeworkTest,
  },

  {
    path: "/homework-solution",
    exact: true,
    auth: false,
    component: HomeworkSolution,
  },

  {
    path: "/evaluation_result",
    exact: true,
    auth: true,
    component: ExerciseResultComponent,
  },
  {
    path: "/evaluation",
    exact: true,
    auth: true,
    component: ExerciseComponent,
  },

  {
    path: "/evaluation1",
    exact: true,
    auth: true,
    component: ExerciseComponent1,
  },
  {
    path: "/exam_cracker_subject_topic",
    exact: true,
    auth: true,
    component: ExamCrackerSubjectTopicLists,
  },
  {
    path: "/exam_cracker_test_component",
    exact: true,
    auth: true,
    component: ExamCrackerTestComponent,
  },
  {
    path: "/exam_cracker_payment_page",
    exact: true,
    auth: true,
    component: ExamCrackerPaymentPage,
  },

  {
    path: "/mock_test",
    exact: true,
    auth: true,
    component: Mock_test,
  },

  {
    path: "/mock_test_component",
    exact: true,
    auth: true,
    component: MockTestComponent,
  },

  {
    path: "/mock_test_summary",
    exact: true,
    auth: true,
    component: MockTestSummary,
  },

  {
    path: "/mock_test_paper_solution",
    exact: true,
    auth: true,
    component: MockQuestionPaperSolution,
  },

  {
    path: "/mock_practice_test",
    exact: true,
    auth: true,
    component: MockPracticeTestComponent,
  },

  // {
  //   path: "/ots_planner_list",
  //   exact: true,
  //   auth: true,
  //   component: OtsTestComponent,
  // },

  {
    path: "/ots_tests",
    exact: true,
    auth: true,
    component: OtsTestList,
  },

  {
    path: "/ots_test",
    exact: true,
    auth: true,
    component: OtsTestContentComponent,
  },

  {
    path: "/ots_payment",
    exact: true,
    auth: true,
    component: OtsPaymentComponent,
  },

  {
    path: "/ots_analytics",
    exact: true,
    auth: true,
    component: OtsAnalytics,
  },

  {
    path: "/ots_test_result",
    exact: true,
    auth: true,
    component: OtsTestResultComponent,
  },
  {
    path: "/ots_subject_topic_wise",
    exact: true,
    auth: true,
    component: OtsSubjectTopicWise,
  },

  {
    path: "/ots_accuracy_attempted",
    exact: true,
    auth: true,
    component: OtsAccuracyAttempted,
  },

  {
    path: "/ots_difficultylevel",
    exact: true,
    auth: true,
    component: OtsDifficultyLevel,
  },

  {
    path: "/ots_behaviour",
    exact: true,
    auth: true,
    component: OtsBehaviour,
  },

  {
    path: "/ots_question_paper_solution",
    exact: true,
    auth: true,
    component: OtsQuestionPaperSolution,
  },

  {
    path: "/ots_summary",
    exact: true,
    auth: true,
    component: OtsSummary,
  },

  {
    path: "/bit_sat_test_component",
    exact: true,
    auth: true,
    component: BitSatTestComponent,
  },

  {
    path: "/bit_sat_summary",
    exact: true,
    auth: true,
    component: BitSatSummary,
  },

  {
    path: "/bit_sat_question_paper_solution",
    exact: true,
    auth: true,
    component: BitSatQuestionPaperSolution,
  },

  {
    path: "/create_doubt",
    exact: true,
    auth: true,
    component: UserCreateDoubt,
  },
  {
    path: "/doubts",
    exact: true,
    auth: true,
    component: UserDoubts,
  },
  {
    path: "/store",
    exact: true,
    auth: true,
    component: UserStore,
  },

  {
    path: "/package_overview",
    exact: true,
    auth: true,
    component: PackagesOverview,
  },

  {
    path: "/package/:id",
    exact: true,
    auth: true,
    component: PackageDetailsComponent,
  },

  {
    path: "/store/handbook/:id",
    exact: true,
    auth: true,
    component: HandBookDetail,
  },

  {
    path: "/handbook/cart-list",
    exact: true,
    auth: true,
    component: CartAndPaymentPage,
  },

  {
    path: "/store/address-form",
    exact: true,
    auth: true,
    component: Address,
  },

  {
    path: "/handbook_payment_gateway",
    exact: true,
    auth: true,
    component: CcAvenueHandbookResponse,
  },

  {
    path: "/refer-and-earn",
    exact: true,
    auth: true,
    component: ReferAndEarn,
  },
  {
    path: "/streak",
    exact: true,
    auth: true,
    component: Streak,
  },
  {
    path: "/transcation",
    exact: true,
    auth: true,
    component: Transaction,
  },

  ///// handbooks ( new store) ////////

  {
    path: "/orderStatus",
    exact: true,
    auth: true,
    component: OrderStatus,
  },
  {
    path: "/cart_collection",
    exact: true,
    auth: true,
    component: CartCollection,
  },
  {
    path: "/cart_details",
    exact: true,
    auth: true,
    component: CartDetails,
  },
  {
    path: "/wishList_collection",
    exact: true,
    auth: true,
    component: WishListCollection,
  },

  {
    path: "/paymentWebView",
    exact: true,
    auth: true,
    component: PaymentWebView,
  },

  // {
  //   path: "/live_web_chat",
  //   exact: true,
  //   auth: true,
  //   component: LiveWebChatFeature,
  // },
  {
    path: "/notes",
    exact: true,
    auth: true,
    component: NotesFeature,
  },
  {
    path: "/live_stream_video",
    exact: true,
    auth: false,
    component: LiveStreamVideoFeature,
  },
  {
    path: "/zoom",
    exact: true,
    auth: true,
    component: Zoom,
  },

  {
    path: "/streaming",
    exact: true,
    auth: true,
    component: StreamingVideo,
  },

  {
    path: "/live_stream",
    exact: true,
    auth: true,
    component: LiveStreamFeature,
  },
  {
    path: "/videos",
    exact: true,
    auth: true,
    component: VideoFeature,
  },
  {
    path: "/topic_component",
    exact: true,
    auth: true,
    component: TopicComponent,
  },
  {
    path: "/recorded",
    exact: true,
    auth: true,
    component: RecordedVideoWatchComponent,
  },
  {
    path: "/subject_component",
    exact: true,
    auth: true,
    component: SubjectComponent,
  },
  {
    path: "/user_edit_profile",
    exact: true,
    auth: true,
    component: UserEditProfile,
  },
  {
    path: "/user_profile",
    exact: true,
    auth: true,
    component: UserProfile,
  },
  {
    path: "/user_dashboard",
    exact: true,
    auth: true,
    component: UserDashboard,
  },
  {
    path: "/signUp",
    exact: true,
    auth: false,
    component: SignUp,
  },
  {
    path: "/signUp2",
    exact: true,
    auth: false,
    component: SignUp2,
  },
  {
    path: "/login",
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path: "/",
    exact: true,
    auth: false,
    component: NewHomePage,
  },

  {
    path: "/modal",
    exact: true,
    auth: false,
    component: LoginSignupModal,
  },

  {
    path: "/parent-login",
    exact: true,
    auth: false,
    component: ParentLoginLayout,
  },
  {
    path: "/payment_gateway",
    exact: true,
    auth: true,
    component: CcAvenueResponse,
  },

  ////////     Question Bank /////////////////

  {
    path: "/question_bank",
    exact: true,
    auth: true,
    component: QuestionBank,
  },
  {
    path: "/question_bank_form",
    exact: true,
    auth: true,
    component: QuestionBankCreateForm,
  },
  {
    path: "/question_bank_test",
    exact: true,
    auth: true,
    component: QuestionBankTestComponent,
  },
  {
    path: "/question_bank_solution",
    exact: true,
    auth: true,
    component: QuestionBankSolution,
  },
  {
    path: "/create-adaptive-cps",
    exact: true,
    auth: true,
    component: PyqCpsExamComponent,
  },

  // {
  //   path: "/create-adaptive-cps",
  //   exact: true,
  //   auth: true,

  //   component: PyqCpsExamComponent,
  // },
  {
    path: "/wallet",
    exact: true,
    auth: true,
    component: Wallet,
  },

  {
    path: "/adaptive_cps_solution",
    exact: true,
    auth: true,
    component: AdaptiveCpsViewSolution,
  },

  {
    path: "/adaptive_cps_test",
    exact: true,
    auth: true,
    component: AdaptiveCpsTest,
  },

  {
    path: "/adaptive_cps_result",
    exact: true,
    auth: true,
    component: AdaptiveCpsTestResults,
  },

  {
    path: "/mts-result",
    exact: true,
    auth: true,
    component: MtsResultPage,
  },

  /////////////////////////////////  PARENT ROUTES \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  {
    path: "/parent-dashboard",
    exact: true,
    // auth: true,
    component: Parent,
  },

  {
    path: "/attendance",
    exact: true,
    // auth: true,
    component: StudentAttendanceContent,
  },
  {
    path: "/student-activity",
    exact: true,
    // auth: true,
    component: StudentActivityContent,
  },

  {
    path: "/questions-view",
    exact: true,
    // auth: true,
    component: QuestionViewComponent,
  },

  {
    path: "/student-report-pdf",
    exact: true,
    // auth: true,
    component: Pdf,
  },

  {
    path: "/student-tests",
    exact: true,
    // auth: true,
    component: Tests,
  },
];

export default routes;
