import React, { Fragment } from "react";
// import { MathJax, MathJaxContext } from "better-react-mathjax";
import "./QuestionOptions.scss";
const QuestionOptions = ({
  showSolution,
  textInputSolution,
  currentQuestionDetails,
  lang,
  testQuestionsDetails,
  classEditor,
  disableSol,
  currentQuestion,
  sol,
  handleSingleSelectAnswer,
  handleCurrentOption,
  handleMultiSelectAnswer,
  handleInputFieldChange,
  checkQuestion,
}) => {
  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    const renderOptionInitial = (index) => {
      const alphabetArr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return alphabetArr[index];
    };

    return (
      <Fragment>
        <div
          className={
            currentQuestion2 === currentQuestion
              ? "examcrcker_single_select_option_collection_wrapper show"
              : "examcrcker_single_select_option_collection_wrapper hide"
          }
        >
          {questionData.option && questionData.option.length
            ? questionData.option.map((element, index) => {
                let optionText = element.option;
                if (
                  !lang &&
                  questionData.option_hindi &&
                  questionData.option_hindi[index]
                ) {
                  optionText =
                    questionData.option_hindi[index].option || element.option;
                }

                return (
                  <div key={index} className="excrker_single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      disabled={sol ? false : true}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : element.is_user_correct_ans == 1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        handleSingleSelectAnswer(event, element.option_id);
                        handleCurrentOption(element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">
                          {`${renderOptionInitial(index)}.`}
                        </p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={{ __html: optionText }}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData, currentQuestion2) => {
    const renderOptionInitial = (index) => {
      const alphabetArr = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return alphabetArr[index];
    };
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "examcracker_multi_select_option_collection_wrapper show"
              : "examcracker_multi_select_option_collection_wrapper hide"
          }
        >
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="excrker_single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion2}`}
                      checked={
                        testQuestionsDetails[currentQuestion2]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion2
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) => {
                        handleMultiSelectAnswer(event, element.option_id);
                        showSolution(currentQuestion);
                        disableSol();
                      }}
                    />
                    <label
                      htmlFor={element.option_id}
                      className={classEditor(
                        element.is_correct,
                        testQuestionsDetails[currentQuestion2]
                          .wrongAnswerIdCollection,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = (currentQuestionDetails, currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              type="number"
              placeholder="Enter your answer"
              // value={testQuestionsDetails[currentQuestion2].integer_answer}
              value={currentQuestionDetails.integer_answer}
              onChange={(event) => {
                handleInputFieldChange(event);
                textInputSolution(event.target.value);
                disableSol();
              }}
              disabled={checkQuestion ? false : true}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderOptions = (currentQuestionDetails, lang) => {
    if (currentQuestionDetails) {
      let step,
        type = currentQuestionDetails.answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(
            currentQuestionDetails,
            currentQuestion,
            lang
          );
        case 2:
          return renderInputField(
            currentQuestionDetails,
            currentQuestion,
            lang
          );
        case 3:
          return renderMultiSelect(
            currentQuestionDetails,
            currentQuestion,
            lang
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="questionOptions">
      {currentQuestionDetails !== null &&
      currentQuestionDetails?.option !== undefined
        ? renderOptions(currentQuestionDetails, lang)
        : ""}
    </div>
  );
};

export default QuestionOptions;
