import { React, useEffect, useState } from "react";
import "./Our_course_package.scss";
import axios from "axios";
import { url_57 } from "../../../../../../custom_utilities/api_services";
import LoaderPopUp from "../../../../../../components/loader/loader";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import uiActions from "../../../../../../redux/ui/action";
import packageListActions from "../../../../../../redux/auth/action";
import Slider from "react-slick";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";
import { setStoreCurrentPackageDetails } from "../../../../../../redux/packages_store/PackageSlice";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const Our_course_package = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    setPaidCoursePackages,
    setStoreCurrentPackageDetailsAction,
  } = props;
  let { courseDetails, targetDetails, batchDetails } = props.userPreference;
  let history = useHistory();
  const [state, setState] = useState([]);


  const getCourseDetails = async (type) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      target_id: targetDetails[0].target_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_57}?course_id=${data.course_id}&batch_id=${data.batch_id}`
      );
      if (response.data.status === 200) {
        setState(response.data.data.Package);
        setPaidCoursePackages(response.data.data.Package);
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  useEffect(() => {
    getCourseDetails("Paid Course Packages");
  }, []);

  const storePage = (packageDetails) => {
  
    setStoreCurrentPackageDetailsAction(packageDetails);
    history.push(`/package/${packageDetails.package_id}`);
  };

  const settings = {
    dots: false,
    // infinite: state.length > 1, // Only allow infinite looping if more than 1 item
    infinite:false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="slick-next" />,
    prevArrow: <SamplePrevArrow className="slick-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="our_course_wrapper" style={{ marginBottom: "100px" }}>
      <div className="course_title">
        <h4>Our Course Packages</h4>
      </div>

      {loader && <LoaderPopUp />}
      <div className="course_box_wrapper">
        <Slider {...settings}>
          {state &&
            state.map((curElem) => (
              <div className="course_bx_wrap" key={curElem.package_id}>
                <div className="course_box">
                  <div className="course_img">
                    <img
                      src={`${BASE_URL}/${curElem.web_thumbnail}`}
                      alt="Course_img"
                    />
                  </div>
                  <div className="course_details_wrapper">
                    <div className="course_detail">
                      <h4>
                        {curElem && curElem.package_name
                          ? curElem.package_name
                          : "N/A"}
                      </h4>
                      <h6>
                        {curElem && curElem.course ? curElem.course : "N/A"}
                      </h6>
                      <div className="course_price">
                        <span>
                          {curElem && curElem.amount
                            ? `₹ ${curElem.final_amount}`
                            : "View"}
                        </span>
                        <s>
                          {curElem && curElem.amount
                            ? `₹ ${curElem.amount}`
                            : "View"}
                        </s>
                      </div>
                      <p>
                        {curElem && curElem.description
                          ? curElem.description
                          : "N/A"}
                      </p>
                    </div>
                    <div className="enroll_btn">
                      <button
                        className="button"
                        onClick={() => storePage(curElem)}
                      >
                        {curElem && curElem.amount ? "Enroll Now" : "Free"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userPreference: state.userPreference,
  loader: state.ui.loader,
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(uiActions.startLoading()),
  finishLoading: () => dispatch(uiActions.finishLoading()),
  setPaidCoursePackages: (payload) =>
    dispatch(packageListActions.setPaidCoursePackages(payload)),
  setFreeCoursePackages: (payload) =>
    dispatch(packageListActions.setFreeCoursePackages(payload)),

  setStoreCurrentPackageDetailsAction: (payload) => {
    dispatch(setStoreCurrentPackageDetails(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Our_course_package);
