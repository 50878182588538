import { Fragment, useEffect, useState } from "react";
import "./user_dashboard.scss";

/////////Desktop////////////////////

import HeaderComponent from "../structure_components/desktop_screens/header_component/header_component";

///////Mobile////////////////////
import MobileLeftSideBarComponent from "../structure_components/mobile_screens/mobile_left_sideBar_component/mobile_left_sideBar_component";
import MobileHeaderComponent from "../structure_components/mobile_screens/mobile_header_component/mobile_header_component";
import MobileRightSideBarComponent from "../structure_components/mobile_screens/mobile_right_sideBar_component/mobile__right_sideBar_component";

import UserDashboardContentComponent from "./user_dashboard_content_component/user_dashboard_content_component";
import LoaderPopUp from "../../../components/loader/loader";
import CustomModal from "../../../components/Modal/CustomModal";

//Redux
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import images from "../../../utilities/images/images";
import { isEmpty } from "../../../custom_utilities/custom_useful_functions";
import {
  url_174,
  url_175,
  url_230,
} from "../../../custom_utilities/api_services";
import axios from "axios";
import Footer_component from "../structure_components/desktop_screens/footer_component/Footer_component";
import uiActions from "../../../redux/ui/action";
import { setBlockedStatus } from "../../../redux/blocking_user/BlockUserSlice";
import initSingular from "../../../singular";
import userActivityActions from "../../../redux/user_activity/action";

const UserDashboard = (props) => {
  let {
    loader,
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setBlockedStatusAction,
    rollNo,
    setCategoryData,
    setIsCategorizedSub,
  } = props;
  const location = useLocation();
  const [state, setState] = useState({
    coins: 0,
    coinDetailsPopup: false,
    coinUsesPoints: [],
    facultyReviewList: [],
    facultyReviewPopup: false,
    ratings: {},
    hover: {},
    facultyFeedback: {},
  });

  useEffect(() => {
    initSingular();
  }, []);

  const addCoinsOnSignup = async () => {
    const data = { type: "NewUser_Registration" };
    try {
      const response = await axios.post(url_174, data);
      if (response.data.status === 200) {
        if (response.data.data !== "MCoins Already Credited...!") {
          console.log(response.data);
          setState((prev) => {
            return {
              ...prev,
              coins: Math.floor(response.data.coins),
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!isEmpty(location.state)) {
      if (location.state.reg_mcoin == 0) {
        addCoinsOnSignup();
        coinUsesApi();
      }
    }
    doubtsModal();
  }, []);

  const doubtsModal = async () => {
    const data = {
      method_name: "get_student_dc",
      rollno: `${rollNo}`,
    };
    try {
      startLoading();
      const response = await axios.post(url_230, data);
      if (response.data.status === "success") {
        finishLoading();
        console.log(response.data.data);
        setState((prevState) => {
          return {
            ...prevState,
            facultyReviewList: response.data.data,
            facultyReviewPopup: true,
          };
        });
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const convertTime = (time24) => {
    const [hour, minute] = time24.split(":");
    let hour12 = hour % 12 || 12; // Convert hour from 24-hour to 12-hour format
    let period = hour >= 12 ? "PM" : "AM"; // Determine AM/PM
    return `${hour12}:${minute} ${period}`;
  };

  const handlefacultyFeedbackInput = (e, id) => {
    setState((prevState) => ({
      ...prevState,
      facultyFeedback: { ...prevState.facultyFeedback, [id]: e.target.value },
    }));
  };

  const handleFeedbackSubmit = () => {
    console.log(state.ratings, state.facultyFeedback);
  };

  const coinUsesApi = async () => {
    try {
      const response = await axios.get(url_175);
      if (response.data.status === 200) {
        console.log(response.data.Uses);
        setState((prevState) => {
          return {
            ...prevState,
            coinUsesPoints: response.data.data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setState((prev) => {
      return {
        ...prev,
        coinDetailsPopup: false,
      };
    });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [windowWidth]);
  return (
    <Fragment>
      {state.facultyReviewPopup && (
        <div
          className="feedback_modal report_modal modal_outer_package"
          onClick={(e) => {
            if (e.target.classList.contains("modal_outer_package")) {
              setState((prevState) => ({
                ...prevState,
                facultyReviewPopup: !prevState.facultyReviewPopup,
              }));
            }
          }}
        >
          <div className="modal_content_feedback">
            <button
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  reportModal: !prevState.reportModal,
                }));
              }}
              className="close"
            >
              <img src={images.close_btn} alt="close" />
            </button>
            <div className="heading d-flex align-items-center">
              <img src={images.thumbs_up} alt="thumbs up" />
              <p>
                Faculty feedback<span>{` (Doubt counter)`}</span>
              </p>
            </div>
            <div className="feedback_card_container">
              {state.facultyReviewList?.map((element) => {
                const date = element.created_on.split(" ")[0];
                const [year, month, day] = date.split("-");
                const formattedDate = `${month}/${day}/${year}`;
                const fromTime = convertTime(element.from_time);
                const toTime = convertTime(element.to_time);
                const currentRating = state.ratings[element.dc_id] || 0;
                const currentHover = state.hover[element.dc_id] || 0;
                return (
                  <div className="feedback_card">
                    <div className="card_heading d-flex align-items-center justify-content-between">
                      <p>{element.subject}</p>
                      <p>{element.faculty_name}</p>
                    </div>
                    <div className="date_time">
                      <p>{`${formattedDate} | ${fromTime} to ${toTime}`}</p>
                    </div>
                    <div className="rating_star_div">
                      <div className="stars">
                        {[...Array(5)].map((star, index) => {
                          const starValue = index + 1;
                          return (
                            <label>
                              <input
                                type="radio"
                                value={starValue}
                                onClick={() =>
                                  setState((prevState) => ({
                                    ...prevState,
                                    ratings: {
                                      ...prevState.ratings,
                                      [element.dc_id]: starValue,
                                    },
                                  }))
                                }
                              />
                              <span
                                onMouseEnter={() =>
                                  setState((prevState) => ({
                                    ...prevState,
                                    hover: {
                                      ...prevState.hover,
                                      [element.dc_id]: starValue,
                                    },
                                  }))
                                }
                                onMouseLeave={() =>
                                  setState((prevState) => ({
                                    ...prevState,
                                    hover: {
                                      ...prevState.hover,
                                      [element.dc_id]: 0,
                                    },
                                  }))
                                }
                              >
                                {starValue <=
                                (currentHover || currentRating) ? (
                                  <img src={images.new_full_star} alt="star" />
                                ) : (
                                  <img src={images.new_empty_star} alt="star" />
                                )}
                              </span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                    <input
                      type="text"
                      className="comments"
                      placeholder="Write Comments"
                      value={state.facultyFeedback[element.dc_id]}
                      onChange={(e) =>
                        handlefacultyFeedbackInput(e, element.dc_id)
                      }
                    />
                  </div>
                );
              })}
            </div>
            <div className="btn_container">
              <button onClick={() => handleFeedbackSubmit()}>
                Submit feedback
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        show={state.coinDetailsPopup}
        onHide={handleClose}
        className="coinDetailsPopup"
      >
        <p className="coinHeading">How to earn Motion Coins?</p>
        <p>
          You can earn Motion Coins by performing various activities on Motion
          App.
        </p>
        <ul>
          {state.coinUsesPoints.map((point) => {
            return <li key={point.id}>{point.description}</li>;
          })}
          {/* <li>Watching Subject Videos</li>
      <li>Playing Motion Daily and Weekly Quizzes</li>
      <li>Finishing Practice Sheets</li>
      <li>Attending Motion Online Tests</li>
      <li>Posting Doubts in Doubts Section</li>
      <li>Purchasing Motion Products from Store</li>
      <li>Referring your friends </li>
      <li>Topping the weekly leaderboard</li> */}
        </ul>
        <p className="text-center mb-0">Earn While You Learn!</p>
      </CustomModal>
      <div className="user_dashboard_wrapper">
        <div className="user_dashboard_inner_wrapper">
          {windowWidth <= 480 ? (
            /////for Mobile Screens////////////////////
            <Fragment>
              <MobileLeftSideBarComponent />
              <MobileHeaderComponent
                userPreference={userPreference}
                userData={userData}
              />
              <MobileRightSideBarComponent history={history} />
              <UserDashboardContentComponent
                history={history}
                setCategoryData={setCategoryData}
                setIsCategorizedSub={setIsCategorizedSub}
              />
            </Fragment>
          ) : (
            /////for Screens greater than Mobile Screens////////////////////
            <Fragment>
              <div className="user_dashboard_left_wrapper">
                {/* <LeftSideBarComponent /> */}
              </div>
              <div className="user_dashboard_right_wrapper">
                <HeaderComponent history={history} />
                <UserDashboardContentComponent
                  history={history}
                  startLoading={startLoading}
                  finishLoading={finishLoading}
                  setBlockedStatusAction={setBlockedStatusAction}
                  setCategoryData={setCategoryData}
                  setIsCategorizedSub={setIsCategorizedSub}
                  userPreference={userPreference}                
                />
              </div>
            </Fragment>
          )}
        </div>
        <Footer_component />
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
    rollNo: state.auth.user.roll_no,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },

    setBlockedStatusAction: (payload) => {
      dispatch(setBlockedStatus(payload));
    },
    setCategoryData: (payload) => {
      dispatch(userActivityActions.setCategoryData(payload));
    },
    setIsCategorizedSub: (payload) => {
      dispatch(userActivityActions.setIsCategorizedSub(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
