import { Fragment, useState, useEffect } from "react";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../../utilities/images/images";
import "./HandBookList.scss";
import { setStoreCurrentHandbookDetails } from "../../../../../../../redux/handbook/HandbookSlice";
import { connect } from "react-redux";
import useGetHandbookProducts from "../hooks/products/useGetHandbookProducts";

const HandBookList = (props) => {
  const { history, setStoreCurrentHandbookDetailsAction, learnMoreBtn } = props;
  const [activeTab, setActiveTab] = useState(""); // Default to empty string

  const { handbookProducts, mutate } = useGetHandbookProducts();

  useEffect(() => {
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab && storedTab !== activeTab) {
      setActiveTab(storedTab);
    }
  }, []);

  useEffect(() => {
    if (activeTab !== "") {
      mutate({ productId: activeTab });
    }
  }, [activeTab, mutate]);

  const handleTabClick = (tabId) => {
    const tabIdStr = String(tabId);
    setActiveTab(tabIdStr);
    localStorage.setItem("activeTab", tabIdStr);
    mutate({ productId: tabIdStr });
  };

  useEffect(() => {
    const handleRefresh = (event) => {
      if (event.persisted) {
        return;
      }
      localStorage.removeItem("activeTab");
    };

    window.addEventListener("pagehide", handleRefresh);

    return () => {
      window.removeEventListener("pagehide", handleRefresh);
    };
  }, []);

  const handbookDetailPage = (handbook) => {
    setStoreCurrentHandbookDetailsAction(handbook);
    history.push({
      pathname: `/store/handbook/${handbook?.p_id}`,
    });
  };

  const category_list = [
    { category_name: "ALL", id: "" },
    { category_name: "NEET", id: "1" }, // Use strings for consistency
    { category_name: "JEE", id: "6" },
    { category_name: "Foundation", id: "7" },
  ];

  return (
    <Fragment>
      <div className="package_offering_component_wrapper">
        <div className="store_banner">
          <img src={images.new_store_banner} alt="store banner" />
        </div>
        <div className="package_filter_div d-flex">
          {!isEmpty(category_list) &&
            category_list.map((element, key) => (
              <div key={key}>
                <button
                  onClick={() => handleTabClick(element.id)}
                  className={activeTab === element.id ? "active" : ""}
                >
                  {element.category_name}
                </button>
              </div>
            ))}
        </div>
        <div className="package_offering_component_inner_wrapper">
          <div className="handbook_container">
            {handbookProducts?.map((element) => (
              <div
                className="single_handbook_wrapper"
                onClick={() => handbookDetailPage(element)}
                key={element.p_id}
              >
                <div style={{ background: "#F9F9F9" }}>
                  <div className="handbook_banner">
                    <img src={element.image} alt="handbook-banner" />
                  </div>
                </div>
                <div className="handbook_inner_wrapper">
                  <div className="handbook_product_name">
                    {element.product_name}
                  </div>
                  <div className="handbook_type">{element.type}</div>
                  <div className="handbook_price_and_discount">
                    <div className="handbook_price">
                      ₹{element.discounted_price}
                    </div>
                    {parseFloat(element.price) -
                      parseFloat(element.discounted_price) !==
                      0 && (
                      <div className="handbook_discount_div d-flex align-items-center">
                        <img src={images.store_offer} alt="offer" />
                        <p>
                          <span>
                            {100 -
                              Math.round(
                                (parseFloat(element.discounted_price) /
                                  parseFloat(element.price)) *
                                  100
                              )}
                            %
                          </span>{" "}
                          Off
                        </p>
                      </div>
                    )}
                  </div>
                  {parseFloat(element.price) -
                    parseFloat(element.discounted_price) !==
                    0 && (
                    <div className="handbook_discount_price">
                      ₹{element.price}
                    </div>
                  )}
                </div>
                <div className="package_button_div">
                  <button
                    className="details"
                    style={{ width: element.is_subscribe && "100%" }}
                    onClick={() => learnMoreBtn(element)}
                  >
                    View Details
                  </button>
                  {!element.is_subscribe && (
                    <button
                      onClick={() => learnMoreBtn(element)}
                      className="buy"
                    >
                      Buy Now
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setStoreCurrentHandbookDetailsAction: (payload) =>
    dispatch(setStoreCurrentHandbookDetails(payload)),
});

export default connect(null, mapDispatchToProps)(HandBookList);
