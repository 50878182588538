import React from "react";
import images from "../../../../../../utilities/images/images";
import "./OtherTabs.scss";

const OtherTabs = ({ handleTabClick }) => {
  return (
    <div className="parent-tabs-container">
      <div className="single-tab" onClick={() => handleTabClick("qwa")}>
        <div className="tab-name">Question wise analysis</div>
        <img src={images.parentRightIcon} alt="arrow-icons" />
      </div>

      <div className="single-tab" onClick={() => handleTabClick("twa")}>
        <div className="tab-name">Topic wise analysis</div>
        <img src={images.parentRightIcon} alt="arrow-icons" />
      </div>

      <div className="single-tab" onClick={() => handleTabClick("tc")}>
        <div className="tab-name">Compare with top 10 students</div>
        <img src={images.parentRightIcon} alt="arrow-icons" />
      </div>
{/* 
      <div className="single-tab" onClick={() => handleTabClick("pc")}>
        <div className="tab-name">Predict College</div>
        <img src={images.parentRightIcon} alt="arrow-icons" />
      </div> */}
    </div>
  );
};

export default OtherTabs;
