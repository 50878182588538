import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ParentHeader from "../parentHeader/ParentHeader";
import images from "../../utilities/images/images";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format, addDays, subDays } from "date-fns";
import uiActions from "../../redux/ui/action";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "./StudentActivityContent.scss";
import { PARENT_STUDENT_ATTENDANCE_URL } from "../../../src/custom_utilities/baseURL";
import StudentActivityAttendance from "./StudentActivityAttendance";
import LoaderPopUp from "../../components/loader/loader";

function StudentActivityContent(props) {
  let { rollNo, startLoading, finishLoading, loginFromParent, loader } = props;

  const [activityData, setActivityData] = useState({ allActivityDetails: {} });
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [dateRange, setDateRange] = useState([]);
  const [visibleButtons, setVisibleButtons] = useState(11);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  useEffect(() => {
    generateDateRange(selectedDate);
    updateVisibleButtons();
    window.addEventListener("resize", updateVisibleButtons);
    return () => window.removeEventListener("resize", updateVisibleButtons);
  }, []);

  useEffect(() => {
    generateDateRange(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    getActivityDetails();
  }, [selectedDate]);

  const getActivityDetails = async () => {
    const formattedDate = format(new Date(selectedDate), "yyyy-MM-dd");
    const data = {
      method_name: "get_mess_meal_attendance_status",
      rollno: Number(rollNo),
      attendance_date: formattedDate,
    };
    startLoading();

    try {
      const response = await axios.post(PARENT_STUDENT_ATTENDANCE_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("ResponseData : ", response);

      if (response.status === 200) {
        setActivityData({
          allActivityDetails: response.data.data || {},
        });
      } else {
        setActivityData({ allActivityDetails: {} });
      }
    } catch (error) {
      console.error("API Error:", error.message || error);
      setActivityData({ allActivityDetails: {} });
    } finally {
      finishLoading();
    }
  };

  const generateDateRange = (date) => {
    const selectedDateObj = new Date(date);
    const startDate = subDays(selectedDateObj, 5);
    const range = Array.from({ length: 11 }, (_, i) => addDays(startDate, i));
    setDateRange(range);
  };
  const updateVisibleButtons = () => {
    if (window.innerWidth <= 400) {
      setVisibleButtons(5);
    } else if (window.innerWidth <= 576) {
      setVisibleButtons(5);
    } else if (window.innerWidth <= 768) {
      setVisibleButtons(8);
    } else if (window.innerWidth <= 992) {
      setVisibleButtons(8);
    } else if (window.innerWidth <= 1200) {
      setVisibleButtons(9);
    } else {
      setVisibleButtons(11);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };

  return (
    <>
      {loginFromParent && <ParentHeader />}
      <div className="student-activity-wrapper" onClick={handleClickOutside}>
        <div className="container">
          <div className="activity-wrapper">
            <div className="container_grey_box">
              <div className="bckBtn-title">
                <Link
                  className="backBtn"
                  to={{
                    pathname: "/parent-dashboard",
                  }}
                >
                  <img src={images.m2_backBtn} alt="backBtn" />
                </Link>
                <div className="content-head">
                  <h1>Student Activity</h1>

                  <div className="right">
                    <img
                      src={images.calender}
                      alt="Choose the Date"
                      className="calendar-icon"
                      onClick={() => setShowDatePicker(!showDatePicker)}
                    />
                    <span className="text">Change Date</span>
                    {showDatePicker && (
                      <div className="datepicker-container">
                        <DatePicker
                          selected={new Date(selectedDate)}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          inline
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="date-range">
                {dateRange.slice(0, visibleButtons).map((date, index) => (
                  <button
                    key={index}
                    onClick={() => handleDateClick(date)}
                    className={
                      selectedDate.toDateString() === date.toDateString()
                        ? "active"
                        : ""
                    }
                  >
                    {format(date, "dd MMM")}
                  </button>
                ))}
              </div>
            </div>
            <StudentActivityAttendance
              activityData={activityData}
              selectedDate={selectedDate}
            />
          </div>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </>
  );
}

const mapStateToProps = (state) => {
  // console.log("state.auth.user.roll_no", state.auth);
  return {
    loader: state.ui.loader,
    rollNo: state.parentrootReducer.parentAuthReducer.user.st_rollno,
    loginFromParent: state.parentrootReducer.parentAuthReducer.loginFromParent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentActivityContent);
