import { Fragment } from "react";
import "./header_component.scss";
import HeaderLeft from "./header_left_new/Header_left_new";
import HeaderRight from "./header_right/header_right";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const HeaderComponent = (props) => {
  let { userPreference, userData, history } = props;
  return (
    <Fragment>
      <div className="header_component_wrapper">
        <div className="headerWrapper">
          <div className="container-lg container-fluid">
            <div className="header_inner_wrapper">
              <div className="header_left_wrapper">
                <HeaderLeft
                  userPreference={userPreference}
                  userData={userData}
                />
              </div>
              <div className="header_right_wrapper">
                <HeaderRight
                  userData={userData}
                  history={history}
                  userPreference={userPreference}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(HeaderComponent);
