import React, { memo } from "react";
import "./QuesitonDetailPanel.scss";
import images from "../../../../../../../../utilities/images/images";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

import ProgressIndicator from "./progressIndicator/ProgressIndicator";
import QuestionInfoSection from "./questionInfoSection/QuestionInfoSection";
import QuesitonOptions from "./questionOptions/QuestionOptions";
import QuestionSwitcher from "./questionSwitcher/QuestionSwitcher";
import QuestionSolution from "./questionSolution/QuestionSolution";
import QuestionVideoSolution from "./questionVideoSolution/QuestionVideoSolution";
import useReportAndError from "../../../../../../../../hooks/useReportAndError";

const QuestionDetailPanel = memo(
  ({
    updateSolution,
    showSolution,
    solDisabled,
    textInputSolution,
    getClicked,
    progressCount,
    currentQuestion,
    testQuestionsDetails,
    setLang,
    lang,
    currentQuestionDetails,
    ConvertStringToHTML,
    handleClose,
    handleSubmitTest,
    showError,
    show,
    handleCloseReportError,
    reportErrorData,
    setSaveReportErrorData,
    saveReportErrorData,
    isDisable,
    handlePostReportErrorData,
    handlePreviousQuestionBtn,
    page,
    scrollUp2,
    handlePurticularQuestion,
    sol,
    handleSolution,
    disabled1,
    handleSaveQuestion,
    handleShow,
    handleNextQuestionBtn,
    scrollUp,
    handlePurticularQuestion3,
    handleGetReportErrorOptions,
    handleSingleSelectAnswer,
    handleCurrentOption,
    disableSol,
    classEditor,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    checkQuestion,
    handleVideoShow,
    videoShow,
    userId,
    toggleMenu,
    setToggleMenu,
  }) => {
    const { ReactAndErrorText, ReportAndErrorModal, showModal } =
      useReportAndError();
    return (
      <div className="questionDetailPanel">
        <div className="right_btn_wrapper">
          <button
            className="togglebtn"
            onClick={() => {
              setToggleMenu(true);
            }}
          >
            Questions
          </button>
          <button
            className="togglebtn submit"
            onClick={() => {
              //handleSaveQuestion();
              handleShow();
            }}
          >
            Submit Test
          </button>
        </div>
        <div className="questionDetailPanel_wrapper">
          <ProgressIndicator
            progressCount={progressCount}
            currentQuestion={currentQuestion}
            testQuestionsDetails={testQuestionsDetails}
            lang={lang}
            setLang={setLang}
          />

          <div
            className="questionAndSolutionWrapper"
            style={{ overflowY: "auto", height: "67vh", padding: "8px" }}
          >
            <QuestionInfoSection
              currentQuestionDetails={currentQuestionDetails}
              lang={lang}
              ConvertStringToHTML={ConvertStringToHTML}
              testQuestionsDetails={testQuestionsDetails}
              currentQuestion={currentQuestion}
              setLang={setLang}
            />
            <QuesitonOptions
              showSolution={showSolution}
              textInputSolution={textInputSolution}
              currentQuestionDetails={currentQuestionDetails}
              lang={lang}
              testQuestionsDetails={testQuestionsDetails}
              classEditor={classEditor}
              disableSol={disableSol}
              currentQuestion={currentQuestion}
              sol={sol}
              handleSingleSelectAnswer={handleSingleSelectAnswer}
              handleCurrentOption={handleCurrentOption}
              handleMultiSelectAnswer={handleMultiSelectAnswer}
              handleInputFieldChange={handleInputFieldChange}
              checkQuestion={checkQuestion}
            />
            {/* toaster for  successfull submission of Report error  */}
            {!sol && (
              <QuestionSolution
                currentQuestionDetails={currentQuestionDetails}
                testQuestionsDetails={testQuestionsDetails}
                currentQuestion={currentQuestion}
                lang={lang}
                getClicked={getClicked}
              />
            )}
            {!sol && (
              <QuestionVideoSolution
                currentQuestionDetails={currentQuestionDetails}
                lang={lang}
                handleVideoShow={handleVideoShow}
                videoShow={videoShow}
                userId={userId}
                getClicked={getClicked}
              />
            )}
          </div>

          <div className="qstnSwitcherAndReprtAndErr">
            <div className="reportAndError">
              <span>
                <img src={images.user_activity_14_1} alt=""></img>
              </span>
              <span
                onClick={handleGetReportErrorOptions}
                className="reportAndErrText"
              >
                Report an error
              </span>

              {/* <span style={{ marginRight: "5px" }}>
                  <img src={images.user_activity_14_1} alt=""></img>
                </span>{" "} */}
              {/* <span style={{ textDecoration: "underline" }}>
                  Report an error
                </span> */}
            </div>

            <QuestionSwitcher
              updateSolution={updateSolution}
              showSolution={showSolution}
              solDisabled={solDisabled}
              currentQuestion={currentQuestion}
              currentQuestionDetails={currentQuestionDetails}
              handlePreviousQuestionBtn={handlePreviousQuestionBtn}
              page={page}
              scrollUp2={scrollUp2}
              handlePurticularQuestion={handlePurticularQuestion}
              testQuestionsDetails={testQuestionsDetails}
              sol={sol}
              disabled1={disabled1}
              handleSolution={handleSolution}
              handleSaveQuestion={handleSaveQuestion}
              handleShow={handleShow}
              handleNextQuestionBtn={handleNextQuestionBtn}
              scrollUp={scrollUp}
              handlePurticularQuestion3={handlePurticularQuestion3}
            />
          </div>
        </div>
        <ToastContainer />
        <Modal
          show={show}
          onHide={handleClose}
          className="onSubmitModal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="submit_Popup">
              <div className="exit_img">
                <img src={images.m2_exit} alt="" />
              </div>
              <h5>Are you Sure?</h5>
              <p>Do you want to continue the exam or you want to Submit.</p>
              <div className="submitCancelBtn">
                <button className="cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button onClick={() => handleSubmitTest()}>Submit</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showError}
          onHide={handleCloseReportError}
          className="errorModal"
        >
          <Modal.Header closeButton>
            {" "}
            <h3>Report an Error</h3>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="error_Popup">
              <p className="text_content">
                You can just write to us, If there is any incorrect or error in
                question and given options.
              </p>
              <ul className="errorList">
                {reportErrorData?.map((element, index) => {
                  return (
                    <li className="errorListItem" key={element.id}>
                      <input
                        type="radio"
                        id={element.id}
                        name="error"
                        value={element.option_name}
                        onChange={(event) => {
                          setSaveReportErrorData({
                            ...saveReportErrorData,
                            option_id: event.target.id,
                          });
                        }}
                      />{" "}
                      <label htmlFor={element.id}>{element.option_name}</label>
                    </li>
                  );
                })}
              </ul>
              <textarea
                className="textarea_content"
                type="text"
                onChange={(event) =>
                  setSaveReportErrorData({
                    ...saveReportErrorData,
                    description: event.target.value,
                  })
                }
                placeholder="Write to Us..."
              />{" "}
              <br />
              <Button disabled={isDisable} onClick={handlePostReportErrorData}>
                Submit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
);

export default QuestionDetailPanel;
