import HeaderComponent from "../../structure_components/desktop_screens/header_component/header_component";
import Footer_component from "../../structure_components/desktop_screens/footer_component/Footer_component";
import HomeWorkContent from "./HomeWorkContent";
import "./HomeWork.scss";

const HomeWork = () => {
  return (
    <>
      <HeaderComponent />

      <div className="homework_wrapper">
        <div className="homework_inner_wrapper">
          <HomeWorkContent />
        </div>
      </div>
      <Footer_component />
    </>
  );
};

export default HomeWork;
