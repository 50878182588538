import React, { useEffect } from "react";
import "./MoreDetails.scss";

const MoreDetails = () => {
  useEffect(() => {
    const counters = document.querySelectorAll(".counter");
    counters.forEach((counter) => {
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;

        const increment = target / 200;

        if (count < target) {
          counter.innerText = Math.ceil(count + increment);
          setTimeout(updateCounter, 75);
        } else {
          counter.innerText = target + "+";
        }
      };

      updateCounter();
    });
  }, []);

  return (
    <>
      <div className="more-details-inner-wrapper">
        <div className="desktop-layout">
          <div id="growth">Our Growth</div>
          <div id="years-wrapper">
            <div className="years">
              <span className="counter" data-target="18">
                0
              </span>{" "}
              <span className="year"> Years </span>
            </div>
            <div className="years-text">Of Excellence</div>
          </div>
          <div id="centers-wrapper">
            <div className="center-count">
              <span className="counter" data-target="70">
                0
              </span>
            </div>
            <div className="center-text">Centers</div>
          </div>
          <div id="students-count-wrapper">
            <div className="student-count">
              <span className="counter" data-target="165000">
                0
              </span>
            </div>
            <div className="student-count-text">Students Nurtured</div>
          </div>
        </div>
      </div>
      <div className="mobile-layout">
        <div className="growth"> Our Growth</div>
        <div className="mobile-layout-growth">
          <div className="stat-item">
            <div
              className="stat-number"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <span className="counter" data-target="17">
                0
              </span>
              <div className="stat-label">Years</div>
            </div>
            <div className="stat-sublabel">Of Excellence</div>
          </div>
          <div className="stat-item">
            <div className="stat-number">
              <span className="counter" data-target="50">
                0
              </span>
            </div>
            <div className="stat-label">Centers</div>
          </div>
          <div className="stat-item">
            <div className="stat-number">
              <span className="counter" data-target="165">
                0
              </span>
              <span className="k">K</span>
            </div>
            <div className="stat-label">Students Nurtured</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreDetails;
