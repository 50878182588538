import { useEffect, useState } from "react";
import { connect } from "react-redux";
import images from "../../../../../../../../utilities/images/images";
import "./CouponCoinSingleProduct.scss";
import HandbookAddress from "../../handbookaddress/HandbookAddress";
import CcAvenueHandbook from "../../payment/CcAvenueHandbook";
import HandbookCouponList from "../modals/handbookcoupon/HandbookCouponList";
import axios from "axios";
import { toastifyMessage } from "../../../../../../../../custom_utilities/toastify_config";

const CouponCoin = ({ token, tokenType, user_id, product }) => {
  const {
    discounted_price: finalPrice,
    eligible_coins: eligibleMotionCoins,
    shipping_charge: shippingCharge,
    motion_coins: motionCoins,
    price: productsPrice,
    discounted_price: productsDiscountPrice,
  } = product;

  const [finalAmount, setFinalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [showCouponList, setShowCouponList] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  useEffect(() => {
    if (finalPrice != null && !isNaN(finalPrice)) {
      setFinalAmount(finalPrice);
    }
  }, [finalPrice]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;

    setIsChecked(checked);

    if (checked) {
      // Call the API if the checkbox is checked
      setFinalAmount(finalAmount - eligibleMotionCoins);
    } else {
      setFinalAmount(finalPrice);
    }
  };

  const applyCoupon = async () => {
    if (couponCode.length === 0) {
      alert("Please Enter the Coupon Code");
      return;
    }

    // startLoading();
    try {
      const payloadData = {
        amount: finalPrice,
        coupon_code: couponCode,
      };

      const response = await axios.post(
        "https://learning.motion.ac.in/motioneducation/api/v1/handbook_coupon",
        payloadData
      );

      if (response.data.status === 200) {
        // finishLoading();
        toastifyMessage({
          status: response.data.data.status,
          message: response.data.data.message,
        });

        if (isCouponApplied) {
          // Coupon is removed
          setFinalAmount(finalPrice);
          setCouponDiscount(0);
          setIsCouponApplied(false);
          setCouponCode("");
        } else {
          // Coupon is applied
          setFinalAmount(response.data.data.updated_amount);
          setCouponDiscount(finalAmount - response.data.data.updated_amount);
          setIsCouponApplied(true);
        }

        handleCloseCoupon();
      }

      console.log("response of coupon", response);
    } catch (error) {
      console.error("Error applying coupon", error);
      handleCloseCoupon();
    }
  };

  const formatTwoDigits = (value) => {
    return value != null ? String(value).padStart(1, "0") : "00";
  };

  const openCouponList = () => {
    setShowCouponList(true);
  };

  const handleCloseCoupon = () => {
    setShowCouponList(false);
  };

  const checkApplyCouponBtnDisabled = () => {
    if (couponCode.length === 0) {
      return "disableapplybtn";
    }

    return "";
  };

  const handleOnChangeCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  return (
    <div className="handbook_coupon_coin_wrapper">
      <HandbookAddress />

      <div className="header">
        <div className="title">
          <img src={images.coupon} alt="Coupon icon" />
          <span>Apply Coupon</span>
          <button className="view-coupons" onClick={openCouponList}>
            View Coupons
          </button>

          {/* <button className="view-coupons">View Coupons</button> */}
        </div>
      </div>

      <div className="coupon-input">
        <input
          type="text"
          placeholder=" Enter Coupon Code"
          value={couponCode}
          onChange={(e) => handleOnChangeCouponCode(e)}
        />
        <button onClick={applyCoupon} className={checkApplyCouponBtnDisabled}>
          {isCouponApplied ? "Remove" : "Apply"}
        </button>
      </div>

      {/* <div className="coins-reedem-wrapper">
        <div className="coins-reedem-inner-wrapper">
          <img src={images.coinImg} alt="coins" />
          <div className="coins-reedem-content">
            <div className="reedem-heading">Redeem Motion Coins</div>
            <div className="coins-count">
              Available :<span>{motionCoins || 0}</span>
            </div>
          </div>
        </div>
  

        <div className="coins-input">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={motionCoins === 0}
          />
         
        </div>
      </div> */}

      <div className="price-details">
        <h3>Price details</h3>

        <div className="price-row">
          <span>Total products({"1"})</span>
          <span> -₹{productsPrice}</span>
        </div>
        <div className="price-row">
          <span>Discount</span>
          <span className="price-value">
            ₹{productsPrice - productsDiscountPrice}
          </span>
        </div>
        <div className="price-row">
          <span>Coupon Discount</span>
          <span className="price-value">- ₹{couponDiscount}</span>
        </div>
        <div className="price-row">
          <span>Motion Coins</span>
          <span className="price-value">
            -₹{isChecked ? formatTwoDigits(eligibleMotionCoins) : "0"}
          </span>
        </div>

        <div className="price-row">
          <span>Delivery Charge</span>
          <span>+₹{shippingCharge}</span>
        </div>

        <div className="price-row total">
          <span>Total Price</span>
          <span>₹{finalAmount}</span>
        </div>
      </div>

      {productsPrice && finalAmount && (
        <CcAvenueHandbook
          buttonText={"Proceed to Pay"}
          handleBookDetails={{
            price: productsPrice,
            discount: productsDiscountPrice,
            coupon_discount: 0,
            shipping_charge: shippingCharge,
            total_price: finalAmount,
            coins_count: isChecked ? eligibleMotionCoins : 0,
            user_id,
            from_cart: true,
          }}
          finalAmount={finalAmount}
        />
      )}

      {showCouponList && (
        <HandbookCouponList
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          applyCoupon={applyCoupon}
          handleCloseCoupon={handleCloseCoupon}
          checkApplyCouponBtnDisabled={checkApplyCouponBtnDisabled}
          handleOnChangeCouponCode={handleOnChangeCouponCode}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    user_id: state.auth.user.user_id,
  };
};

export default connect(mapStateToProps, null)(CouponCoin);
