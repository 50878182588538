import React, { useState } from "react";
import { format } from "date-fns";
import { Calendar, ChevronLeft, ChevronRight, X } from "lucide-react";
import "./DateRangePicker.scss";

const DateRangePicker = ({ setStartDate, setEndDate, onConfirm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startDate, setStartDateState] = useState(null);
  const [endDate, setEndDateState] = useState(null);

  const weekDays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 15 }, (_, i) => currentYear - 5 + i);

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = [];
    for (let i = 0; i < firstDay.getDay(); i++) {
      daysInMonth.push({
        date: new Date(year, month, -firstDay.getDay() + i + 1),
        disabled: true,
      });
    }

    for (let i = 1; i <= lastDay.getDate(); i++) {
      daysInMonth.push({
        date: new Date(year, month, i),
        disabled: false,
      });
    }

    return daysInMonth;
  };

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDateState(date);
      setEndDateState(null);
    } else {
      if (date < startDate) {
        setStartDateState(date);
        setEndDateState(null);
      } else {
        setEndDateState(date);
      }
    }
  };

  const isInRange = (date) => {
    return startDate && endDate && date > startDate && date < endDate;
  };

  const isSelected = (date) => {
    return (
      (startDate && date.getTime() === startDate.getTime()) ||
      (endDate && date.getTime() === endDate.getTime())
    );
  };

  const handleConfirm = () => {
    if (startDate && endDate) {
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);

      if (onConfirm) {
        onConfirm(formattedStartDate, formattedEndDate);
      }

      setIsOpen(false);
    }
  };

  const handleYearChange = (e) => {
    const newDate = new Date(currentMonth);
    newDate.setFullYear(parseInt(e.target.value));
    setCurrentMonth(newDate);
  };

  return (
    <div className="date-picker-wrapper">
      <button onClick={() => setIsOpen(true)}>
        <Calendar size={20} />
      </button>

      {isOpen && (
        <div className="calendar-modal">
          <button
            className="close-button"
            style={{ float: "right" }}
            onClick={() => setIsOpen(false)}
          >
            <X size={20} />
          </button>
          <div className="modal-header">
            <h2>Select Date</h2>
          </div>

          <div className="date-inputs">
            <div className="date-input">
              {startDate ? format(startDate, "MMM dd, yyyy") : "Start Date"}
            </div>
            <div>To</div>
            <div className="date-input">
              {endDate ? format(endDate, "MMM dd, yyyy") : "End Date"}
            </div>
          </div>

          <div className="calendar-header">
            <div className="month-nav">
              <button
                onClick={() => {
                  const newDate = new Date(currentMonth);
                  newDate.setMonth(currentMonth.getMonth() - 1);
                  setCurrentMonth(newDate);
                }}
              >
                <ChevronLeft style={{ width: "13px", height: "13px" }} />
              </button>
              <span className="month">{format(currentMonth, "MMMM yyyy")}</span>
              <button
                onClick={() => {
                  const newDate = new Date(currentMonth);
                  newDate.setMonth(currentMonth.getMonth() + 1);
                  setCurrentMonth(newDate);
                }}
              >
                <ChevronRight style={{ width: "13px", height: "13px" }} />
              </button>
            </div>
            <select
              value={currentMonth.getFullYear()}
              onChange={handleYearChange}
              className="year-select"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="weekday-header">
            {weekDays.map((day) => (
              <span key={day}>{day}</span>
            ))}
          </div>

          <div className="calendar-grid">
            {getDaysInMonth(currentMonth).map((day, index) => (
              <button
                key={index}
                className={`calendar-day ${
                  day.disabled
                    ? "disabled"
                    : isSelected(day.date)
                    ? "selected"
                    : isInRange(day.date)
                    ? "in-range"
                    : ""
                }`}
                onClick={() => !day.disabled && handleDateClick(day.date)}
                disabled={day.disabled}
              >
                {day.date.getDate()}
              </button>
            ))}
          </div>

          <button className="confirm-button" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
