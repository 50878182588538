import React, { Fragment, useState } from "react";
import images from "../../../utilities/images/images";
import "./Navbar.scss";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll"; // Import Link from react-scroll

const Navbar = (props) => {
  const { setIsLoginOpen, isLoginOpen } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const isOnStorePage = location.pathname.startsWith("/login-store/");

  return (
    <Fragment>
      <navbar>
        <div className="navbar-container">
          <div className="motion-logo">
            <img
              src={images.landingPageLogo}
              alt="motion-logo"
              onClick={() => (window.location.href = "/")} // Navigate to home
            />
          </div>

          <div className="details">
            <div className="features">
              <div className="feature">
                <a
                  href="#courses-id"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/#courses-id"; // Simple navigation
                  }}
                  alt="courses-id"
                >
                  Courses
                </a>
              </div>
              <div className="feature">
                <a
                  href="#benefits-id"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/#benefits-id"; // Simple navigation
                  }}
                  alt="benefits-id"
                >
                  Benefits
                </a>
              </div>
            </div>

            <div className="get-in-touch-wrapper">
              <img src={images.callIcon} alt="call-icon" />
              <div>
                <div className="get-in-touch-text">Get in touch with us</div>
                <a href="tel:18002121799" className="get-in-touch-mobile-no">
                  18002121799
                </a>
              </div>
            </div>

            <div
              className="login-button"
              onClick={() => setIsLoginOpen(!isLoginOpen)}
            >
              Login
            </div>
          </div>
        </div>
      </navbar>
      <navbar>
        <div className="mobile-navbar-container">
          <div className="mobile-nav">
            <div className="mobile-nav-row">
              <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? "✕" : "☰"}
              </div>
              <div className="motion-logo">
                <img
                  src={images.landingPageLogo}
                  alt="motion-logo"
                  onClick={() => window.open("https://motion.ac.in/", "_blank")}
                />
              </div>
            </div>
            <div
              className="login-button"
              onClick={() => setIsLoginOpen(!isLoginOpen)}
            >
              Login
            </div>
          </div>

          {menuOpen && (
            <div className="mobile-menu">
              <div className="mobile-nav-menu">
                <div>
                  <div className="mobile-nav-icon">
                    <div className="feature">
                      <a href="#courses-id">Courses</a>
                    </div>
                    <div className="right-arrow">
                      <img src={images.nextImg} alt="next-img" />
                    </div>
                  </div>
                  <div className="mobile-nav-icon">
                    <div className="feature">
                      <a href="#benefits-id">Benefits</a>
                    </div>
                    <div className="right-arrow">
                      <img src={images.nextImg} alt="next-img" />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="get-in-touch-wrapper">
                    <img src={images.callIcon} alt="call-icon" />
                    <div>
                      <div className="get-in-touch-text">
                        Get in touch with us
                      </div>
                      <a
                        href="tel:18002121799"
                        className="get-in-touch-mobile-no"
                      >
                        18002121799
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </navbar>
    </Fragment>
  );
};

export default Navbar;
