import React, { useState, useEffect } from "react";
import axios from "axios";
import "./QuestionBankCustomPapers.scss";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import questionBankActions from "../../../../redux/question_bank/action";
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import { url_149 } from "../../../../custom_utilities/api_services";
import images from "../../../../utilities/images/images";

const QuestionBankCustomPapers = (props) => {
  const {
    setQuestionBankIdAction,
    history,
    startLoading,
    finishLoading,
    setQuestionBankNameAction,
  } = props;

  const [state, setState] = useState({
    questionBankTestList: [],
    show: false,
    topics: [],
  });

  useEffect(() => {
    getQuestionBankTestList();
  }, []);

  //  to get list of test papers which are already created
  const getQuestionBankTestList = async () => {
    try {
      startLoading();
      const response = await axios.post(url_149);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => {
          return {
            ...prevState,
            questionBankTestList: response.data.data.Qbank,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderTopicsWithLimit = (topics, limit) => {
    let totalCharacters = 0;

    return topics.map((topic, index) => {
      if (totalCharacters + topic.topic.length <= limit) {
        totalCharacters += topic.topic.length;
        return (
          <span className="qb_topic" key={topic.topic}>
            {topic.topic}
            {topics.length > 1 && index < topics.length - 1 ? "," : ""}
          </span>
        );
      } else {
        return null;
      }
    });
  };

  const calculateTotalCharacters = (topics) => {
    const totalCharacters = topics.reduce(
      (acc, topic) => acc + topic.topic.length,
      0
    );
    return totalCharacters;
  };

  const handleStart = (qBank) => {
    setQuestionBankIdAction(qBank.question_bank_id);
    setQuestionBankNameAction(qBank.name);

    history.push("/question_bank_test");
  };

  const handleViewSolution = (questionBankId) => {
    setQuestionBankIdAction(questionBankId);
    history.push("/question_bank_solution");
  };

  const handleRenderTopics = (topics) => {
    setState((prevState) => ({
      ...prevState,
      topics,
    }));
  };

  const handleCloseTopics = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  };

  let tab = "created_tests";

  return (
    <div className="QuestionBankCustomPaperWrppr">
      <div className="QuestionBankCustomPaperInrWrppr">
        {/* <div className="heading">Your Custom papers</div> */}

        <div className="qBankTabBox">
          <div className="qBankTab">
            <ul class="nav nav-tabs qBankExamUl" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class={
                    tab == "created_tests" ? "nav-link active" : "nav-link"
                  }
                  id="created_tests-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#created_tests"
                  type="button"
                  role="tab"
                  aria-controls="created_tests"
                  aria-selected="false"
                >
                  Created Tests
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={
                    tab == "completed_tests" ? "nav-link active" : "nav-link"
                  }
                  id="completed_tests-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#completed_tests"
                  type="button"
                  role="tab"
                  aria-controls="completed_tests"
                  aria-selected="false"
                >
                  Completed Tests
                </button>
              </li>
            </ul>
          </div>

          <div className="qBankTabContent">
            <div class="tab-content" id="myTabContent">
              <div
                class={
                  tab == "created_tests"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="created_tests"
                role="tabpanel"
                aria-labelledby="created_tests-tab"
              >
                <div className="QuestionBankCustomPaperContent">
                  {!isEmpty(state.questionBankTestList) ? (
                    state.questionBankTestList.map((qBank, index) => {
                      if (!qBank.is_attempt) {
                        return (
                          <div
                            className="questionBankCustomPaperBox"
                            key={qBank.question_bank_id}
                          >
                            <div className="questionBankCustomPaperName">
                              <div className="paperName">{qBank.name}</div>
                              <div className="created_date">
                                Created on: <br />
                                <strong>
                                  {new Date(qBank.create_on)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")}
                                </strong>
                              </div>
                            </div>
                            <div className="qb_topics">
                              <div className="qb_topics_list">
                                {renderTopicsWithLimit(qBank.topic, 60)}
                                {calculateTotalCharacters(qBank.topic) > 60 && (
                                  <>
                                    <span>...</span>
                                    <button
                                      type="button"
                                      className="topic_content"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop"
                                      onClick={() =>
                                        handleRenderTopics(qBank.topic)
                                      }
                                    >
                                      View all
                                    </button>
                                  </>
                                )}
                              </div>
                              <div
                                class="modal fade"
                                id="staticBackdrop"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog modal-dialog-scrollable">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <div className="modal-header-left">
                                        <p
                                          class="modal-title fs-5"
                                          id="staticBackdropLabel"
                                        >
                                          Topics
                                        </p>
                                      </div>
                                      <div className="modal-header-right">
                                        <button
                                          type="button"
                                          class="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    </div>
                                    <div class="modal-body">
                                      <div>
                                        {/* {index} */}
                                        {/* {qBank.indexOf(qBank.topic) == index} */}
                                        {state.topics.map((element) => {
                                          return (
                                            <div className="qb_topic">
                                              {element.topic}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button
                                        type="button"
                                        className="done_btn"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bottom_div d-flex justify-content-between align-items-center">
                              <div className="num_of_questions">
                                <span className="question_tex">
                                  <strong>{qBank.no_of_question} </strong> Ques
                                </span>
                                <span
                                  className="subject_tex"
                                  style={{
                                    color: `${qBank.text_color}`,
                                    backgroundColor: `${
                                      qBank.color_code.split(",")[0]
                                    }`,
                                  }}
                                >
                                  {qBank.subject}{" "}
                                </span>
                              </div>
                              <div className="date_startBtn">
                                <div
                                  className="startBtn"
                                  onClick={() => handleStart(qBank)}
                                >
                                  <div
                                    className="start_pract_btn"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Start Practice
                                    <img
                                      src={images.new_m2_rgtArow}
                                      alt="arrow"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="no_custom_tests_img_div">
                      <div className="no_custom_tests_img">
                        <img src={images.new_user_activity_37}></img>
                      </div>
                      <p>
                        You have not created any tests
                        <br /> yet.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                class={
                  tab == "completed_tests"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="completed_tests"
                role="tabpanel"
                aria-labelledby="completed_tests-tab"
              >
                <div className="QuestionBankCustomPaperContent">
                  {!isEmpty(state.questionBankTestList) ? (
                    state.questionBankTestList.map((qBank, index) => {
                      if (qBank.is_attempt) {
                        return (
                          <div
                            className="questionBankCustomPaperBox"
                            style={{ paddingBottom: "20px" }}
                            key={qBank.question_bank_id}
                          >
                            <div className="questionBankCustomPaperName">
                              <div className="paperName">{qBank.name}</div>
                              <div className="created_date">
                                Created on: <br />
                                <strong>
                                  {new Date(qBank.create_on)
                                    .toLocaleDateString("en-GB")
                                    .replace(/\//g, "-")}
                                </strong>
                              </div>
                            </div>
                            <div className="qb_topics">
                              <div className="qb_topics_list">
                                {renderTopicsWithLimit(qBank.topic, 60)}
                                {calculateTotalCharacters(qBank.topic) > 60 && (
                                  <>
                                    <span>...</span>
                                    <button
                                      type="button"
                                      className="topic_content"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop1"
                                      onClick={() =>
                                        handleRenderTopics(qBank.topic)
                                      }
                                    >
                                      View all
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="questions_data_count">
                              <ul>
                                <li className="qb_total_q">
                                  <span>{qBank.no_of_question}</span> Ques
                                </li>
                              </ul>
                              <div className="d-flex justify-content-between align-items-center">
                                <ul style={{ marginBottom: "0px" }}>
                                  <li className="qb_unattempt">
                                    <span>
                                      {qBank.no_of_question -
                                        (qBank.right_questions +
                                          qBank.wrong_questions)}
                                    </span>{" "}
                                    Unattempted
                                  </li>{" "}
                                  <li className="qb_wrong">
                                    <span>
                                      {" "}
                                      {qBank.right_questions === "" &&
                                      qBank.wrong_questions === ""
                                        ? "0"
                                        : qBank.wrong_questions}{" "}
                                    </span>
                                    Wrong
                                  </li>{" "}
                                  <li className="qb_correct">
                                    <span>
                                      {" "}
                                      {qBank.right_questions === "" &&
                                      qBank.wrong_questions === ""
                                        ? "0"
                                        : qBank.right_questions}{" "}
                                    </span>
                                    Correct
                                  </li>{" "}
                                </ul>
                                <div className="date_startBtn">
                                  <div
                                    className="startBtn"
                                    onClick={() =>
                                      handleViewSolution(qBank.question_bank_id)
                                    }
                                  >
                                    <button className="start_pract_btn">
                                      View Solution
                                      <img
                                        src={images.new_m2_rgtArow}
                                        alt="arrow"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="modal fade"
                              id="staticBackdrop1"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabindex="-1"
                              aria-labelledby="staticBackdrop1Label"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-scrollable">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <div className="modal-header-left">
                                      <p
                                        class="modal-title fs-5"
                                        id="staticBackdrop1Label"
                                      >
                                        Topics
                                      </p>
                                    </div>
                                    <div className="modal-header-right">
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                  <div class="modal-body">
                                    <div>
                                      {state.topics.map((element) => {
                                        return (
                                          <div className="qb_topic">
                                            {element.topic}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      className="done_btn"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="no_of_quests_startBtn">
                            <div className="startBtn">
                              <div
                                onClick={() =>
                                  handleViewSolution(qBank.question_bank_id)
                                }
                                className="view_sol_btn"
                              >
                                View Solution{" "}
                              </div>{" "}
                              <span>{">"}</span>
                            </div>
                          </div> */}
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className="no_custom_tests_img_div">
                      <div className="no_custom_tests_img">
                        <img src={images.new_user_activity_37}></img>
                      </div>
                      <p>
                        You have not completed any
                        <br /> tests yet.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setQuestionBankIdAction: (payload) => {
      dispatch(questionBankActions.setQuestionBankId(payload));
    },
    setQuestionBankNameAction: (payload) => {
      dispatch(questionBankActions.setQuestionBankName(payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(QuestionBankCustomPapers);
