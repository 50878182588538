import React from "react";
import HomeWorkListItem from "./HomeWorkListItem";
import "./HomeWorkList.scss";
import { connect } from "react-redux";

const HomeWorkList = ({ homeworkData }) => {
  return (
    <div className="homework_list_container">
      {homeworkData.map((item) => {
        return <HomeWorkListItem item={item} />;
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps, null)(HomeWorkList);
