import { React, useState, useEffect } from "react";
import HomeworkSolutionData from "./HomeworkSolutionData";

import {
  url_254,
  url_259,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";
import LoaderPopUp from "../../../../../../components/loader/loader";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";

const HomeworkSolution = (props) => {
  let {
    startLoading,
    finishLoading,
    loader,
    userData,
    token,
    tokenType,
    //   testResultData,
    homeworkTestPaperId,
  } = props;
  const [state, setState] = useState({
    testQuestionsDetails: [],
    currentQuestion: 0,
    currentSolutionDetails: {},
  });
  useEffect(() => {
    getQuestionList();
  }, []);

  const getQuestionList = async () => {
    startLoading();

    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    let data = {
      paper_id: homeworkTestPaperId,
    };
    try {
      const response = await axios.post(url_259, data, { headers });
      if (response.data.status === 200) {
        // getCurrentSolutionDetails(
        //   homeworkTestPaperId,
        //   response.data.data[state.currentQuestion].qid
        // );

        setState((preState) => ({
          ...preState,
          testQuestionsDetails: response.data.data,
          currentSolutionDetails: response.data.data[0],
        }));
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleReview = () => {
    let { currentQuestion, testQuestionsDetails } = state;

    testQuestionsDetails[
      currentQuestion
    ].isAnswerGuessed = !testQuestionsDetails[currentQuestion].isAnswerGuessed;

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
    // reviewQuestion(testQuestionsDetails[currentQuestion]);
  };

  //nextBtn
  const handleNextQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    const { paper_id, qid } = testQuestionsDetails[currentQuestion2];

    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      currentSolutionDetails: testQuestionsDetails[currentQuestion + 1],
    }));
    window.scrollTo(0, 0);
  };
  //previous btn
  const handlePreviousQuestionBtn = (currentQuestion2) => {
    let { currentQuestion, testQuestionsDetails } = state;

    const { paper_id, qid } = testQuestionsDetails[currentQuestion2];

    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        currentSolutionDetails: testQuestionsDetails[currentQuestion - 1],
      }));

      window.scrollTo(0, 0);
    }
  };
  //handleJump
  const handleQuestionJump = (questionNumber) => {
    const { paper_id, qid } = state.testQuestionsDetails[questionNumber];

    const { testQuestionsDetails } = state;


    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      currentSolutionDetails: testQuestionsDetails[questionNumber],
    }));
  };
  return (
    <div>
      <HomeworkSolutionData
        testQuestionsDetails={state.testQuestionsDetails}
        currentQuestion={state.currentQuestion}
        currentSolutionDetails={state.currentSolutionDetails}
        handlePreviousQuestionBtn={handlePreviousQuestionBtn}
        handleNextQuestionBtn={handleNextQuestionBtn}
        handleQuestionJump={handleQuestionJump}
        userData={userData}
        // testResultData={testResultData}
        homeworkTestPaperId={homeworkTestPaperId}
        handleReview={handleReview}
      />
      {loader && <LoaderPopUp />}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
    userData: state.auth.user,
    // testResultData: state.userTestsExercisesData.testResultData,
    homeworkTestPaperId: state.HomeWorkSlice.homeworkTestDetails.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeworkSolution);
