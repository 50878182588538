import * as actionTypes from "./action_type";

const initialState = {
  cpsTestsData: {},
  cpsResultData: {},
  topicName:""
};

const CpsReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.SET_CPS_TESTS_DATA:
      return setCpsTestsData(state, payload);

    case actionTypes.SET_CPS_TESTS_RESULT_DATA:
      return setCpsTestsResultData(state, payload);

      case actionTypes.SET_CPS_TOPIC_NAME:
        return setCpsTopicName(state, payload);
  

    default:
      return state;
  }
};

const setCpsTestsData = (state, payload) => {
  return (state = { ...state, cpsTestsData: payload });
};

const setCpsTestsResultData = (state, payload) => {
  state = { ...state, cpsResultData: payload };
  return state;
};

const setCpsTopicName = (state, payload) => {
  return (state = { ...state, topicName: payload });
};

export default CpsReducer;
