import { Fragment, useEffect, useState } from "react";
import "./PyqDailyTestInstructionView.scss";

import images from "../../../utilities/images/images";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import uiActions from "../../../redux/ui/action";
import { url_207 } from "../../../custom_utilities/api_services";
import axios from "axios";
import PyqDailyChallengeActions from "../../../redux/pyq_daily_challenge/action";

const PyqDailyTestInstructionView = (props) => {
  let {
    startBtn,
    subject,
    startLoading,
    finishLoading,
    setQuestionsList,
    tokenType,
    token,
  } = props;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getTestDetails();
  }, []);

  const [state, setState] = useState({
    testQuestionsList: [],
  });

  const handleBackBtn = () => {
    history.push({
      pathname: "/Pyq_dailyChallenge",
    });
  };

  const getTestDetails = async () => {
    startLoading();
    const data = {
      schedule: subject.schedule,
      subject: subject.subject,
    };
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.post(url_207, data, headers);
      finishLoading();
      setQuestionsList(response.data);
      setState((prevState) => ({
        ...prevState,
        testQuestionsList: response.data,
      }));
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="test_intructions_view_wrapper">
        <div className="container">
          <div className="test_intructions_view_inner_wrapper">
            <div className="instructionScreenBox">
              <button
                className="backBtn mt-3 ms-4 mb-0"
                onClick={handleBackBtn}
              >
                <img src={images.m2_backBtn} alt="backBtn" />
              </button>

              <div className="instruction_title">Test Instructions</div>
              {/* <div className="instruction_topicNam">{state.testQuestionsList.instruction_title}</div> */}

              <div className="instruction_detailBox">
                <div className="instructions">
                  <h3>{state.testQuestionsList.instruction_title}</h3>
                  <div className="instPoint">
                    <h5>Set of Instructions:</h5>
                    <ul>
                      <li>
                        This Section contains a set of questions for practice
                      </li>
                      <li>
                        Users can go to previous question by clicking on the
                        previous button
                      </li>
                      <li>
                        Users can go to next question by clicking on the next
                        button
                      </li>
                    </ul>
                    <h6>Best of luck</h6>
                  </div>
                </div>

                <div className="startTestBtn">
                  <button onClick={() => startBtn()}>Start Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    subject: state.PyqDailyChallengeReducer.subject,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setQuestionsList: (payload) => {
      dispatch(PyqDailyChallengeActions.setQuestionsList(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(PyqDailyTestInstructionView);
