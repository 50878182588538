import { Fragment, useState, useEffect } from "react";
import "./login_step_two.scss";

import images from "../../../utilities/images/images";
const LoginStepTwo = (props) => {
  const {
    handleInputOtpNumbers,
    handleParentResendOtp,
    handleSubmitOtpNumber,
    handlePreviousBtn,
    handleResendOtp,
    resendCooldown,
    isButtonDisabled,
    setResendCooldown,
    setIsButtonDisabled,
  } = props;

  useEffect(() => {
    if (resendCooldown > 0) {
      const timer = setTimeout(() => {
        setResendCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000); // 1000 milliseconds = 1 second

      return () => clearTimeout(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [resendCooldown]);

  const handleMoveToNextAndPrev = (current, nextFieldId, prevFieldId) => {
    // console.log(current.target, nextFieldId);
    if (current.keyCode === 8) {
      // console.log("backspace pressed");
      if (current.target.value.length === 0) {
        if (prevFieldId.length > 0) {
          document.getElementById(prevFieldId).focus();
        }
      }
    } else {
      if (
        current.target.value.length ===
        parseInt(current.target.attributes["maxlength"].value)
      ) {
        document.getElementById(nextFieldId).focus();
      }
    }
  };

  const clearOtpFields = () => {
    console.log("clearOtpFields is calling");
    handleInputOtpNumbers({
      target: { name: "otp_number_one", value: "" },
    });
    handleInputOtpNumbers({
      target: { name: "otp_number_two", value: "" },
    });
    handleInputOtpNumbers({
      target: { name: "otp_number_three", value: "" },
    });
    handleInputOtpNumbers({
      target: { name: "otp_number_four", value: "" },
    });
  };

  return (
    <Fragment>
      <div className="login_step_two_wrapper">
        <div className="login_step_two_inner_wrapper">
          <div className="content_wrapper">
            <div className="content_inner_wrapper">
              {props.loginCandidate === "student" ? (
                <form onSubmit={(event) => props.handleSubmitOtpNumber(event)}>
                  <div className="back_btn_wrapper">
                    <button
                      type="button"
                      onClick={() => props.handlePreviousBtn()}
                    >
                      <img src={images.login_2} />
                    </button>
                  </div>
                  {/* <div className="image_container">
                    <img src={images.m2_loginIcon} />
                  </div> */}
                  <h3>OTP Verification</h3>
                  <p>
                    We just sent an OTP on your registered number please check.
                  </p>
                  <div className="otp_input_wrapper">
                    <div className="otp_input_inner_wrapper">
                      <div className="single_input">
                        <input
                          type="text"
                          id="one_text"
                          name="otp_number_one"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(event, "two_text", "")
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="two_text"
                          name="otp_number_two"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "three_text",
                              "one_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="three_text"
                          name="otp_number_three"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "four_text",
                              "two_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="four_text"
                          name="otp_number_four"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "submit_otp_btn",
                              "three_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="btn_wrapper">
                    <button id="submit_otp_btn" type="submit">
                      Verify
                    </button>
                  </div>
                  <div className="resend_btn_wrapper">
                    <button
                      type="button"
                      onClick={() => props.handleResendOtp()}
                      disabled={isButtonDisabled}
                    >
                      Resend OTP {resendCooldown > 0 && `${resendCooldown} sec`}
                    </button>
                  </div>
                </form>
              ) : (
                <form
                  onSubmit={(event) => props.handleSubmitParentOtpNumber(event)}
                >
                  <div className="back_btn_wrapper">
                    <button
                      type="button"
                      onClick={() => props.handlePreviousBtn()}
                    >
                      <img src={images.login_2} />
                    </button>
                  </div>
                  <div className="image_container">
                    <img src={images.m2_loginIcon} />
                  </div>
                  <h3>OTP Verification</h3>
                  <p>
                    We just sent an OTP on your registered number please check.
                  </p>
                  <div className="otp_input_wrapper">
                    <div className="otp_input_inner_wrapper">
                      <div className="single_input">
                        <input
                          type="text"
                          id="one_text"
                          name="otp_number_one"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(event, "two_text", "")
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="two_text"
                          name="otp_number_two"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "three_text",
                              "one_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="three_text"
                          name="otp_number_three"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "four_text",
                              "two_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="four_text"
                          name="otp_number_four"
                          className="otp_number"
                          placeholder=""
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "submit_otp_btn",
                              "three_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button id="submit_otp_btn" type="submit">
                      Verify
                    </button>
                  </div>
                  <div className="resend_btn_wrapper">
                    <button
                      type="button"
                      disabled={isButtonDisabled}
                      onClick={() => props.handleParentResendOtp()}
                    >
                      Resend OTP {resendCooldown > 0 && `${resendCooldown} sec`}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginStepTwo;

// import { Fragment } from "react";
// import "./login_step_two.scss";

// import images from "../../../utilities/images/images";
// const LoginStepTwo = (props) => {
//   const handleMoveToNextAndPrev = (current, nextFieldId, prevFieldId) => {
//     // console.log(current.target, nextFieldId);
//     if (current.keyCode === 8) {
//       // console.log("backspace pressed");
//       if (current.target.value.length === 0) {
//         if (prevFieldId.length > 0) {
//           document.getElementById(prevFieldId).focus();
//         }
//       }
//     } else {
//       if (
//         current.target.value.length ===
//         parseInt(current.target.attributes["maxlength"].value)
//       ) {
//         document.getElementById(nextFieldId).focus();
//       }
//     }
//   };
//   return (
//     <Fragment>
//       <div className="login_step_two_wrapper">
//         <div className="login_step_two_inner_wrapper">
//           <div className="content_wrapper">
//             <div className="content_inner_wrapper">
//               <form onSubmit={(event) => props.handleSubmitOtpNumber(event)}>
//                 <div className="back_btn_wrapper">
//                   <button
//                     type="button"
//                     onClick={() => props.handlePreviousBtn()}
//                   >
//                     <img src={images.login_2} />
//                   </button>
//                 </div>
//                 <div className="image_container">
//                   <img src={images.m2_loginIcon} />
//                 </div>
//                 <h3>OTP Verification</h3>
//                 <p>
//                   We just sent an OTP on your registered number please check.
//                 </p>
//                 <div className="otp_input_wrapper">
//                   <div className="otp_input_inner_wrapper">
//                     <div className="single_input">
//                       <input
//                         type="text"
//                         id="one_text"
//                         name="otp_number_one"
//                         className="otp_number"
//                         placeholder=""
//                         pattern="^\d{1}$"
//                         title="only number"
//                         maxLength="1"
//                         onKeyUp={(event) =>
//                           handleMoveToNextAndPrev(event, "two_text", "")
//                         }
//                         onChange={(event) => props.handleInputOtpNumbers(event)}
//                         required
//                       />
//                     </div>
//                     <div className="single_input">
//                       <input
//                         type="text"
//                         id="two_text"
//                         name="otp_number_two"
//                         className="otp_number"
//                         placeholder=""
//                         pattern="^\d{1}$"
//                         title="only number"
//                         maxLength="1"
//                         onKeyUp={(event) =>
//                           handleMoveToNextAndPrev(
//                             event,
//                             "three_text",
//                             "one_text"
//                           )
//                         }
//                         onChange={(event) => props.handleInputOtpNumbers(event)}
//                         required
//                       />
//                     </div>
//                     <div className="single_input">
//                       <input
//                         type="text"
//                         id="three_text"
//                         name="otp_number_three"
//                         className="otp_number"
//                         placeholder=""
//                         pattern="^\d{1}$"
//                         title="only number"
//                         maxLength="1"
//                         onKeyUp={(event) =>
//                           handleMoveToNextAndPrev(
//                             event,
//                             "four_text",
//                             "two_text"
//                           )
//                         }
//                         onChange={(event) => props.handleInputOtpNumbers(event)}
//                         required
//                       />
//                     </div>
//                     <div className="single_input">
//                       <input
//                         type="text"
//                         id="four_text"
//                         name="otp_number_four"
//                         className="otp_number"
//                         placeholder=""
//                         pattern="^\d{1}$"
//                         title="only number"
//                         maxLength="1"
//                         onKeyUp={(event) =>
//                           handleMoveToNextAndPrev(
//                             event,
//                             "submit_otp_btn",
//                             "three_text"
//                           )
//                         }
//                         onChange={(event) => props.handleInputOtpNumbers(event)}
//                         required
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="btn_wrapper">
//                   <button id="submit_otp_btn" type="submit">
//                     Verify
//                   </button>
//                 </div>
//                 <div className="resend_btn_wrapper">
//                   <button type="button" onClick={() => props.handleResendOtp()}>
//                     Resend OTP?
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default LoginStepTwo;
