import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./EmailUpdateModal.scss";
import axios from "axios";
import { connect } from "react-redux";
import authActions from "../../../../../redux/auth/action";
import { url_187 } from "../../../../../custom_utilities/api_services";

const EmailUpdateModal = (props) => {
  const { isEmailModalOpen, closeEmailModal, setUser, user } = props;

  const [newEmail, setNewEmail] = useState("");
  const [emailErroMsg, setEmailErrorMsg] = useState();

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const updateEmailAPI = async () => {
    const data = {
      email: newEmail,
    };

    try {
      const response = await axios.post(url_187, data);

      if (response.data.status === 200) {
        closeEmailModal();
        const updateUserData = {
          ...user,
          email: newEmail,
        };
        setUser(updateUserData);
      } else {
        setEmailErrorMsg(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateEmail = () => {
    const email = newEmail.trim();

    if (!validateEmail(email)) {
      setEmailErrorMsg("Invalid email");
    } else {
      setEmailErrorMsg("");
      updateEmailAPI();
    }
  };

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <Modal
      show={isEmailModalOpen}
      onHide={closeEmailModal}
      dialogClassName="email-update-wrapper"
      centered
    >
      <Modal.Header closeButton className="email-modal-header"></Modal.Header>

      <Modal.Body>
        <div className="email-details">
          <h2>
            Please Update your Email ID before
            <br /> proceeding to payment.
          </h2>
          <div className="email-input">
            <input
              type="email"
              placeholder="Enter Email ID"
              value={newEmail}
              onChange={handleEmailChange}
            />
            <div id="errorText">{emailErroMsg}</div>
          </div>

          <div className="update-button" onClick={handleUpdateEmail}>
            <button>Update Email</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailUpdateModal);
