import React, { useEffect, useState } from "react";
import "./Transcation.scss";
import { ArrowLeft } from "lucide-react";
import axios from "axios";
import { url_261 } from "../../../custom_utilities/api_services";
import { useHistory } from "react-router-dom";
import DateRangePicker from "./ReferAndEarnModals/DateRangePicker";
import Header_component from "../structure_components/desktop_screens/header_component/header_component";

const Transaction = () => {
  const [state, setState] = useState({
    TranscationData: [],
  });
  const history = useHistory();

  useEffect(() => {
    streaksApi();
  }, []);

  const streaksApi = async () => {
    try {
      const response = await axios.get(url_261);
      if (response.data.status === 200) {
        setState((prevState) => {
          return {
            ...prevState,
            TranscationData: response.data.data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <Header_component />
      <div className="transactions-container">
        <div className="transactions-header">
          <div className="back-button">
            <ArrowLeft size={20} onClick={handleBackClick} />
            <span>Transactions</span>
          </div>
          <DateRangePicker />
        </div>

        <div className="transactions-list">
          {state.TranscationData.length === 0 ? (
            <div className="no-transactions">
              <span>No transactions found</span>
            </div>
          ) : (
            state.TranscationData.map((transaction, index) => (
              <div key={index} className="transaction-item">
                <div className="transaction-info">
                  <span className="date">{transaction.date}</span>
                  <h3 className="title">{transaction.course_name}</h3>
                  <span className="type">{transaction.pay_type}</span>
                </div>
                <div className="transaction-status">
                  <span
                    className={`status ${transaction.status.toLowerCase()}`}
                  >
                    {transaction.status}
                  </span>
                  <span
                    className={`amount ${
                      transaction.isPositive ? "positive" : "negative"
                    }`}
                    style={{ color: transaction.color }}
                  >
                    {transaction.isPositive ? "- " : "+ "}₹{transaction.amount}
                  </span>

                  {transaction.is_cancelable === 1 ? (
                    <button className="action-button">
                      Cancel/Modify Request
                    </button>
                  ) : (
                    transaction.comments && (
                      <span className="error-message">
                        {transaction.comments}
                      </span>
                    )
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Transaction;
