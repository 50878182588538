import React from "react";
import { Link } from "react-router-dom";

const QuestionSwitcher = ({
  currentQuestion,
  totalQuestions,
  handlePreviousQuestionBtn,
  handleNextQuestionBtn,
  scrollUp,
  scrollUp2,
}) => {
  return (
    <div className="sub_sub_section_wrapper_5">
      <div className="left_side_wrapper">
        {currentQuestion > 0 && (
          <div className="btn_wrapper">
            <button
              onClick={() => {
                handlePreviousQuestionBtn(currentQuestion - 1);
                scrollUp2();
              }}
            >
              Previous
            </button>
          </div>
        )}
      </div>
      <div className="right_side_wrapper">
        {totalQuestions === currentQuestion + 1 ? (
          <div className="btn_wrapper">
            <Link
              className="backBtn"
              to={{
                pathname: "/user_dashboard",
                state: { tab: "adaptive_cps" },
              }}
            >
              Exit
            </Link>
          </div>
        ) : (
          <div className="btn_wrapper">
            <button
              onClick={() => {
                handleNextQuestionBtn(currentQuestion + 1);
                scrollUp();
              }}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionSwitcher;
