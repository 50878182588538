import React, { Fragment, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./HomeWorkListItemHomePage.scss";
import HomeWorkDetailModal from "../homwork_modals/HomeWorkDetailModal";
import {
  setHomeworkTestInstruction,
  setHomeworkTestDetails,
} from "../../../../../../redux/homework/HomeWorkSlice";

const HomeWorkListItemHomePage = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [truncatedText, setTruncatedText] = useState("");
  const [isOverflow, setIsOverflow] = useState(false); // Added state for overflow
  const topicListRef = useRef(null);

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
  };

  const startHomeworkTest = (testData) => {
    if (testData.status == "Pending") {
      history.push("/homework-test");
      dispatch(setHomeworkTestDetails(testData));
    } else {
      history.push("/homework-solution");
      dispatch(setHomeworkTestDetails(testData));
    }
  };

  const {
    status,
    subject,
    faculty,
    homework_date,
    data,
    submisstion_date,
  } = item;

  useEffect(() => {
    if (topicListRef.current) {
      const element = topicListRef.current;
      const maxLines = 2;

      // Dynamically truncate based on scrollHeight
      const maxHeight =
        parseInt(getComputedStyle(element).lineHeight, 10) * maxLines;

      let currentText = data.map((item) => item.topic).join(", ");
      element.innerText = currentText;

      // Check for overflow while truncating
      while (element.scrollHeight > maxHeight && currentText.length > 0) {
        currentText = currentText.slice(0, -1); // Remove one character at a time
        element.innerText = `${currentText}...`;
      }

      // Check if overflow exists
      setIsOverflow(element.scrollHeight > maxHeight);

      setTruncatedText(element.innerText); // Save truncated text
    }
  }, [data]);

  const redirectToHomeworkPage = () => {
    history.push("/homework");
  };

  return (
    <Fragment>
      <div
        className="homework_item_container_homepage"
        // onClick={redirectToHomeworkPage}
      >
        <div className="hmwk_top">
          <div className="hmwk_top_left">
            <div className="hmwk_subject">{subject}</div>
            <div
              className={`hmwk_status ${
                status == "Pending" ? "pending" : "submitted"
              }`}
            >
              {status}
            </div>
          </div>
          <div className="hmwk_top_right">
            <div className="hmwk_top_right_left">Submission Date</div>
            <div className="hmwk_top_right_right">{submisstion_date}</div>
          </div>
        </div>
        <div className="hmwk_middle">
          <div className="hmwk_middle_left">
            <div className="hmwk_topic_list" ref={topicListRef}>
              {truncatedText}
              {isOverflow && (
                <span
                  className="view-more-btn"
                  onClick={() => setShowDetailModal(true)}
                >
                  View More
                </span>
              )}
            </div>
          </div>
          <div className="hmwk_middle_right">By {faculty}</div>
        </div>
        <div className="hmwk_bottom">
          <div className="hmwk_bottom_left">
            <ul>
              {data?.map((elem) => (
                <li key={elem.label}>{elem.label}</li>
              ))}
            </ul>
          </div>
          <div
            className="hmwk_bottom_right"
            onClick={() => startHomeworkTest(item)}
          >
            {status == "Pending" ? "Start Homework" : "View Solutions"}
          </div>
        </div>

        <div className="hmwk_final_bottom">
          <div className="hmwk_final_bottom_left">Assigned on</div>
          <div className="hmwk_final_bottom_right">{homework_date}</div>
        </div>
      </div>
      {showDetailModal && (
        <HomeWorkDetailModal handleCloseDetailModal={handleCloseDetailModal} />
      )}
    </Fragment>
  );
};

export default HomeWorkListItemHomePage;
