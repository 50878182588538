import React, { useEffect, useState, useRef, useCallback } from "react";
import "./StreamingVideoDetails.scss";
import images from "../../../../../../../utilities/images/images";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  url_216,
  url_220,
  url_221,
  url_222,
  url_223,
  url_224,
  url_225,
  url_226,
  url_227,
  url_228,
  url_229,
  url_39,
  url_7,
} from "../../../../../../../custom_utilities/api_services";
import { connect } from "react-redux";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";
import uiActions from "../../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../../redux/user_activity/action";
import userBookmarkActions from "../../../../../../../redux/user_bookmark/action";
import { setNoteAddedVideoTime } from "../../../../../../../redux/watched_video_details/WatchedVideoDetailsSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { BASE_URL } from "../../../../../../../custom_utilities/baseURL";
import VideoDrmPlayer from "../../../../../features/video_feature/video_feature_content_component/VideoDrmPlayer";

const StreamingVideoDetails = ({
  streamingDetails,
  isLive,
  live_class_id,
  token,
  tokenType,
  startLoading,
  finishLoading,
  currentVideoWatchTime,
  setNoteAddedVideoTimeAction,
  setNotesData,
  setExerciseData,
  setRelatedVideos,
  videoData,
}) => {
  const history = useHistory();
  const { sub_name, topic_name, meeting_topic } = streamingDetails;
  const intervalRef = useRef(null);

  const [state, setState] = useState({
    videoId: null,
    videoDetails: {},
    relatedVideo: {},
    optionFeatureType: 2,
    dppCollections: {},
    videosDrmUrl: {},
    flag: false,
    activeTab: 0,
    studyMaterialList: {},
    rating: null,
    hover: null,
    comment: "",
    commentsList: {},
    timeline: [],
    note: {},
    notesList: {},
    notesInput: false,
    openOptionsIndex: null,
    enableNotesEdit: null,
    timeElapsed: "",
    deleteModal: false,
    deleteId: null,
    reportId: null,
    reportModal: false,
    report: "",
    reportCategory: [
      "Incorrect Question",
      "Incorrect Option",
      "Incorrect Answer Key",
      "Incorrect Solution",
      "Others",
    ],
    reportCategoryOption: "",
    jumpTo: "",
  });
  const [currentVideoCryptId, setCurrentVideoCryptId] = useState(null);
  const [videoComponent, setVideoComponent] = useState("iframe"); // "iframe" or "drm"
  const [selectedVideoData, setSelectedVideoData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setVideoId();
    setVideoComponentInitialTime();
  }, []);

  useEffect(() => {}, [state.likesArray]);

  useEffect(() => {
    if (state.videoId) {
      getVideoDetails();
      getStudyMaterialList();
      initialiseLikes();
      getCommentsList();
      getTimeline();
      getNotesList();
      getAdditionalVideoDetails();
    }
  }, [state.videoId]);

  useEffect(() => {
    if (!isEmpty(state.videoDetails) && state.videoDetails.last_paused) {
      handleJumpToParticularTime();
    }
  }, [state.videoDetails]);

  const setVideoId = () => {
    let { video_id } = videoData;
    setState((prevState) => ({ ...prevState, videoId: video_id }));
  };

  const setVideoComponentInitialTime = () => {
    let d = new Date();
    // setVideoComponentTime(d.getTime());
  };

  const handleTabClick = (index) => {
    setState((prevState) => ({
      ...prevState,
      activeTab: index,
    }));
  };

  const getVideoDetails = async () => {
    let { videoId } = state;
    try {
      const response = await axios.get(`${url_7}?video_id=${videoId}`);

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          videoDetails: response.data.data.VideoDetail,
        }));

        // setVideoDetailsForTime(response.data.data.VideoDetail);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAdditionalVideoDetails = async () => {
    let { videoId } = state;
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/related_videos?video_id=${videoId}`
      );

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          relatedVideo: response.data.data.data,
        }));

        setRelatedVideos(response.data.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleComment = (e) => {
    setState((prevState) => ({
      ...prevState,
      comment: e.target.value,
    }));
  };

  const handleNotesInput = (e) => {
    setState((prevState) => ({
      ...prevState,
      note: e.target.value,
    }));
  };

  const handleReportInput = (e) => {
    setState((prevState) => ({
      ...prevState,
      report: e.target.value,
    }));
  };

  const getCommentsList = async () => {
    let videoId = state?.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_222}?video_id=${videoId}`);
      if (response?.data?.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          commentsList: response?.data?.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const initialiseLikes = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_222}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        const likesArray = response.data.data.map((item) => item.like_status); // Extracting like_status values
        setState((prevState) => ({
          ...prevState,
          likesArray: likesArray, // Storing like_status values in likesArray
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTimeline = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_224}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          timeline: response.data.data.timeline,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleReportSubmit = async () => {
    if (state.report === "" || state.reportCategoryOption === "") {
      alert("please enter a feedback and select an option");
    } else {
      let videoId = state.videoId;
      let data = {
        video_id: videoId,
        description: state.report,
        id: state.reportId,
        option: state.reportCategoryOption,
      };
      startLoading();
      try {
        const response = await axios.post(url_229, data);
        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            reportId: null,
            reportModal: false,
            report: "",
            reportCategoryOption: "",
          }));
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const getNotesList = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_225}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          notesList: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleCommentSubmit = async () => {
    if (state.rating === null && state.comment === "") {
      alert("Please enter a comment or give a rating");
    } else {
      let videoId = state.videoId;
      let data = {
        video_id: videoId,
        rate: state.rating,
        comments: state.comment,
      };
      startLoading();
      try {
        const response = await axios.post(url_221, data);
        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            comment: "",
            likesArray: [...prevState.likesArray, 1],
          }));
          getCommentsList();
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const handleNoteSubmit = async () => {
    if (state.note === "") {
      alert("Please enter note");
    } else {
      let videoId = state.videoId;
      let data = {
        video_id: videoId,
        time: currentVideoWatchTime,
        notes: state.note,
      };
      startLoading();
      try {
        const response = await axios.post(url_226, data);
        if (response.data.status === 200) {
          finishLoading();
          getNotesList();
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
      setState((prevState) => ({
        ...prevState,
        note: "",
        notesInput: false,
      }));
    }
  };

  const handleNoteEditSubmit = async (id) => {
    if (state.note === "") {
      alert("Please enter note");
    } else {
      let data = {
        id: id,
        time: currentVideoWatchTime,
        notes: state.note,
      };
      startLoading();
      try {
        const response = await axios.post(url_227, data);
        if (response.data.status === 200) {
          finishLoading();
          getNotesList();
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
      setState((prevState) => ({
        ...prevState,
        note: "",
        notesInput: false,
        openOptionsIndex: null,
        enableNotesEdit: null,
      }));
    }
  };

  const handleNoteDelete = async (id) => {
    let data = {
      id: state.deleteId,
    };
    startLoading();
    try {
      const response = await axios.post(url_228, data);
      if (response.data.status === 200) {
        finishLoading();
        getNotesList();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
    setState((prevState) => ({
      ...prevState,
      deleteId: null,
      deleteModal: false,
    }));
  };

  const handleDeleteModal = (id) => {
    setState((prevState) => ({
      ...prevState,
      openOptionsIndex: null,
      deleteModal: true,
      deleteId: id,
    }));
  };

  const handleNoteEdit = (note, index) => {
    setState((prevState) => ({
      ...prevState,
      note: note,
      enableNotesEdit: prevState.enableNotesEdit === index ? null : index,
    }));
  };

  const handleLikeClick = async (id, status, index) => {
    let videoId = state.videoId;
    let data = {
      id: id,
      like: 1,
      video_id: videoId,
      status: status,
    };
    startLoading();
    try {
      const response = await axios.post(url_223, data);
      if (response.data.status === 200) {
        finishLoading();
        getCommentsList();
        setState((prevState) => {
          const updatedLikesArray = [...prevState.likesArray];
          updatedLikesArray[index] = status === 1 ? 0 : 1;
          return {
            ...prevState,
            likesArray: updatedLikesArray,
          };
        });
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleClickOnNotes = (time) => {
    setNoteAddedVideoTimeAction(time);
  };

  const formattedTime = (currentTime) => {
    const hours = Math.floor(currentTime / 3600);
    const minutes = Math.floor((currentTime % 3600) / 60);
    const seconds = Math.floor(currentTime % 60);

    const Time = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return Time;
  };

  // const handleOnLoad = () => {
  //   let frame = document.querySelector("#iframe");
  //   frame.contentWindow.postMessage(
  //     `{"activate": true,"userId":"${userData.user_id}"}`,
  //     "*"
  //   );

  //   // //////jump to particular time///////////////////////
  //   // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

  //   ////////////////testing///////////////////////////////////
  //   let eventMethod = window.addEventListener
  //     ? "addEventListener"
  //     : "attachEvent";
  //   let eventer = window[eventMethod];
  //   let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

  //   eventer(messageEvent, function (e) {
  //     if (e.data && e.data.currentTime) {
  //       let videoTimmingDetails = { currentTime: e.data.currentTime };
  //       sessionStorage.setItem(
  //         "videoTimmingDetails",
  //         JSON.stringify(videoTimmingDetails)
  //       );
  //     }
  //   });
  // };

  const handleJumpToParticularTime = () => {
    let videoDetails = { ...state.videoDetails };
    if (!isEmpty(videoDetails) && videoDetails.last_paused) {
      let frame = document.querySelector("#iframe");
      //////jump to particular time///////////////////////
      frame.contentWindow.postMessage(
        JSON.stringify({ seekTo: videoDetails.last_paused }),
        "*"
      );
    }
  };

  const handleSeekTo = (jumpTime) => {
    let timeSplitArr = jumpTime.split(":");
    let timeInSeconds =
      timeSplitArr[0] * 60 * 60 + timeSplitArr[1] * 60 + timeSplitArr[2];

    let frame = document.querySelector("#iframe");
    //////jump to particular time///////////////////////
    frame.contentWindow.postMessage(
      JSON.stringify({ seekTo: timeInSeconds }),
      "*"
    );
  };

  const handleBookmarkVideo = async (isSaved) => {
    let videoDetails = { ...state.videoDetails };
    setState((prevState) => ({
      ...prevState,
      videoDetails: { ...prevState.videoDetails, IsSaved: !isSaved },
    }));

    let data = {};
    if (isSaved) {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 0, //1=> save , 0=>unsave
      };
    } else {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 1, //1=> save , 0=>unsave
      };
    }
    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStoreDataInStorage = (type, data) => {
    switch (type) {
      case "notes":
        data.redirectedFrom = "activity";
        setNotesData(data);
        history.push("/notes");
        break;
      case "dpp":
        data.redirectedFrom = "activity";
        data.videoId = state.videoId;
        data.exerciseType = "DPP";
        setExerciseData(data);
        history.push({
          pathname: "/evaluation",
          state: { type: "dpp" },
        });
        break;
      case "videos":
        data.redirectedFrom = "activity";
        // Set the video data
        setCurrentVideoCryptId(data);
        setSelectedVideoData(data);
        // Change the video component to use DRM player instead of iframe
        setVideoComponent("drm");
        break;
      default:
        setCurrentVideoCryptId(data);
        break;
    }
  };

  const handleRedirect = () => {
    history.goBack();
    // postWatchedVideoDetails();
  };

  const getStudyMaterialList = async () => {
    let videoId = state.videoId;
    startLoading();
    try {
      const response = await axios.get(`${url_220}?video_id=${videoId}`);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          studyMaterialList: response.data.data.study_material,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getViewsCount = useCallback(async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };
    try {
      const response = await axios.get(
        `${url_216}?live_class_id=${live_class_id}`,
        headers
      );
      console.log(response.data.data.viewers_count);
      setViewsCount(response.data.data.viewers_count);
    } catch (error) {
      console.log(error);
    }
  }, [live_class_id, token, tokenType]);

  const leaveClassApiCall = useCallback(async () => {
    const headers = {
      Authorization: `${tokenType} ${token}`,
    };

    const response = await axios.post(
      `https://learning.motion.ac.in/motioneducation/api/v1/live/viewers/update?live_class_id=${live_class_id}&status=left`,
      { headers }
    );

    if (response.data.status === 200) {
      history.push("/user_dashboard");
    } else {
      history.push("/user_dashboard");
    }
  }, [live_class_id, history, token, tokenType]);

  useEffect(() => {
    getViewsCount();

    if (isLive) {
      intervalRef.current = setInterval(() => {
        getViewsCount();
      }, 20000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [getViewsCount, isLive]);

  const onBackButtonEvent = useCallback(
    (e) => {
      e.preventDefault();
      if (isLive) {
        leaveClassApiCall();
      }
    },
    [isLive, leaveClassApiCall]
  );

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  const [viewsCount, setViewsCount] = useState(0);

  /* eslint-disable no-restricted-globals */
  const leaveClass = () => {
    const userConfirmed = confirm(
      "Are you sure you want to leave the meeting?"
    );

    if (userConfirmed) {
      leaveClassApiCall();
    }
  };
  /* eslint-enable no-restricted-globals */

  const handleBackButton = () => {
    if (isLive) {
      alert("Are you sure you want to leave meeting?");

      history.push("/user_dashboard");

      leaveClassApiCall();
    } else {
      history.push("/topic_component");
    }
  };

  const classEditor = (isLive) => {
    return isLive ? "is-live" : "is-recorded-video";
  };

  return (
    <div className={`video-player-container ${classEditor(isLive)}`}>
      <div className="video-details-watch-count">
        <div className="video-details">
          <img
            src={images.backBtn1}
            alt="back-btn"
            style={{
              width: "2.25rem",
              height: "2.25rem",
              marginRight: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => {
              handleBackButton();
            }}
          />

          <div className="subject-name">{sub_name}</div>

          <span className="morethan-symbol">
            <img src={images.greaterThan} alt="greater-than" />
          </span>

          <div className="topic-name">{topic_name}</div>
        </div>
        {isLive && (
          <div className="watch-count">
            <div className="img-and-count">
              <img src={images.eyeView} alt="eye-view" />
              {viewsCount}
            </div>
          </div>
        )}
      </div>
      {videoComponent === "iframe" ? (
        <iframe
          src={streamingDetails?.playbackurl}
          height="100%"
          width="100%"
          title="Iframe Example"
          allow="fullscreen"
          className="video-player"
          scrolling="no"
        ></iframe>
      ) : (
        <VideoDrmPlayer
          id_video={selectedVideoData?.video_crypt_id}
          data={selectedVideoData}
        />
      )}
      <div className="more-details">
        {isLive && (
          <div className="live">
            <div className="live-circle"></div>
            <div className="live-text">Live</div>
          </div>
        )}

        <div className="topic-and-leave-class">
          <div className="topic">
            {currentVideoCryptId?.video_description || meeting_topic}
          </div>
          {isLive && (
            <div className="leave-class-btn" onClick={leaveClass}>
              Leave Class
            </div>
          )}
        </div>
      </div>
      {!isLive && (
        <div className="video_details_content_wrapper">
          <div className="content_wrapper_1">
            <div className="left_side_content_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">
                  {currentVideoCryptId?.video_description ||
                    state.videoDetails.video_title}
                </p>
              </div>
            </div>
            <div className="right_side_content_wrappers">
              <div className="label_content_wrapper">
                <label>
                  <span>
                    <img src={images.user_activity_2} alt="user_activity" />
                  </span>
                  <span>
                    {!isEmpty(state.videoDetails)
                      ? state.videoDetails.video_rating
                      : null}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="video_tabs">
            <ul className="tab-list">
              <li
                className={state.activeTab === 0 ? "active" : ""}
                onClick={() => handleTabClick(0)}
              >
                My Notes
              </li>
              <li
                className={state.activeTab === 1 ? "active" : ""}
                onClick={() => handleTabClick(1)}
              >
                Study Material
              </li>
              <li
                className={state.activeTab === 3 ? "active" : ""}
                onClick={() => handleTabClick(3)}
              >
                Ratings
              </li>
              <li
                className={state.activeTab === 4 ? "active" : ""}
                onClick={() => handleTabClick(4)}
              >
                Timeline
              </li>
              <li
                className={state.activeTab === 5 ? "active" : ""}
                onClick={() => handleTabClick(5)}
              >
                Related videos
              </li>
            </ul>
            <div
              className={
                state.activeTab === 0 ? "tab-content active" : "tab-content"
              }
            >
              <div className="notes_wrapper">
                <div className="add_button d-flex justify-content-end">
                  <button
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        notesInput: true,
                      }))
                    }
                    className={state.notesInput ? `` : `active`}
                  >
                    <span>+</span> Add Notes
                  </button>
                </div>
                {state.notesInput && (
                  <div className="notes_input">
                    <input
                      type="text"
                      placeholder="Write your notes here...."
                      onChange={(e) => handleNotesInput(e)}
                    />
                    <div className="note_buttons d-flex justify-content-end align-items-center">
                      <p>{state.timeElapsed}</p>
                      <button
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            notesInput: false,
                            note: "",
                          }))
                        }
                      >
                        Cancel
                      </button>
                      <button onClick={() => handleNoteSubmit()}>Save</button>
                    </div>
                  </div>
                )}
                {state.notesList.data?.length > 0 ? (
                  <div className="notes_inner">
                    <div className="notes_list">
                      {state.notesList.data?.length > 0 &&
                        state.notesList.data?.map((element, index) => {
                          return (
                            <>
                              {state.enableNotesEdit === index ? (
                                <div className="notes_input">
                                  <input
                                    type="text"
                                    placeholder="Write your notes here...."
                                    onChange={(e) => handleNotesInput(e)}
                                    value={state.note}
                                  />
                                  <div className="note_buttons d-flex justify-content-end align-items-center">
                                    <p>{state.timeElapsed}</p>
                                    <button
                                      onClick={() =>
                                        setState((prevState) => ({
                                          ...prevState,
                                          enableNotesEdit: null,
                                          openOptionsIndex: null,
                                          note: "",
                                        }))
                                      }
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleNoteEditSubmit(element.id)
                                      }
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="note d-flex align-items-center justify-content-between">
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleClickOnNotes(element.time)
                                    }
                                  >
                                    {element.notes}
                                  </p>
                                  <div className="ml-auto d-flex align-items-center">
                                    <p>{formattedTime(element.time)}</p>
                                    <button
                                      className={
                                        state.openOptionsIndex === index
                                          ? `active`
                                          : ``
                                      }
                                      onClick={() =>
                                        setState((prevState) => ({
                                          ...prevState,
                                          openOptionsIndex:
                                            prevState.openOptionsIndex === index
                                              ? null
                                              : index,
                                        }))
                                      }
                                    >
                                      <img
                                        src={images.new_options}
                                        alt="options"
                                      />
                                    </button>
                                  </div>
                                  {state.openOptionsIndex === index && (
                                    <ul>
                                      <li
                                        onClick={() =>
                                          handleNoteEdit(element.notes, index)
                                        }
                                      >
                                        <img
                                          src={images.edit_note}
                                          alt="edit"
                                        />
                                        <p>Edit</p>
                                      </li>
                                      <li onClick={() => handleNoteDelete()}>
                                        <img
                                          src={images.delete_note}
                                          alt="delete"
                                        />
                                        <p>Delete</p>
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="notes_empty">
                    <img src={images.new_folder_warning} alt="warning" />
                    <p>
                      You have not added any notes yet.
                      <br /> Add a notes now by clicking on the
                      <br /> “+ Add Notes” .
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                state.activeTab === 1 ? "tab-content active" : "tab-content"
              }
            >
              <div className="d-flex study_material">
                {state.studyMaterialList.length > 0 ? (
                  state.studyMaterialList?.map((element) => {
                    return (
                      <div className="d-flex topic_card">
                        <div>
                          <img src={images.new_pdf} alt="pdf" />
                          <p>
                            {element.notes_title.length > 50
                              ? `${element.notes_title.slice(0, 50)}...`
                              : element.notes_title}
                          </p>
                        </div>
                        <a
                          href={element.notes}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={images.new_download} alt="download" />
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <div className="sm_empty">
                    <img src={images.new_folder_warning} alt="warning" />
                    <p>No study material available at the moment.</p>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                state.activeTab === 3 ? "tab-content active" : "tab-content"
              }
            >
              <div className="rating">
                <h3>Rate Now</h3>
                <div className="rating_star_div">
                  <div className="stars">
                    {[...Array(5)].map((_, index) => {
                      const currentRating = index + 1;
                      return (
                        <label>
                          <input
                            type="radio"
                            value={currentRating}
                            onClick={() =>
                              setState((prevState) => ({
                                ...prevState,
                                rating: currentRating,
                              }))
                            }
                          />
                          <span
                            onMouseEnter={() =>
                              setState((prevState) => ({
                                ...prevState,
                                hover: currentRating,
                              }))
                            }
                            onMouseLeave={() =>
                              setState((prevState) => ({
                                ...prevState,
                                hover: null,
                              }))
                            }
                          >
                            {currentRating <= (state.hover || state.rating) ? (
                              <img src={images.new_full_star} alt="star" />
                            ) : (
                              <img src={images.new_empty_star} alt="star" />
                            )}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <div className="rating_guide d-flex justify-content-between">
                    <span>Very poor</span>
                    <span>Excellent</span>
                  </div>
                </div>
                <div className="comments_div">
                  <h3>Add Comment(If any)</h3>
                  <div className="comment_input">
                    <input
                      type="textarea"
                      placeholder="Write comment"
                      value={state.comment}
                      onChange={(e) => handleComment(e)}
                    />
                    <button onClick={() => handleCommentSubmit()}>
                      <img src={images.new_send} alt="send" />
                    </button>
                  </div>
                  {state.commentsList.length
                    ? state.commentsList?.map((element, index) => {
                        return (
                          <div className="comment_details">
                            <p className="user_name">{element.name}</p>

                            <p className="comment">{element.comments}</p>
                            <div className="like_report">
                              <span>
                                {state?.likesArray?.[index] ? (
                                  <img
                                    onClick={() => {
                                      handleLikeClick(element.id, 1, index);
                                    }}
                                    src={images?.new_likes}
                                    alt="likes"
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      handleLikeClick(element.id, 0, index);
                                    }}
                                    src={images?.new_liked}
                                    alt="liked"
                                  />
                                )}

                                {element?.like && <p>{element?.like} Likes</p>}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            <div
              className={
                state.activeTab === 4 ? "tab-content active" : "tab-content"
              }
            >
              <div className="timeline_div">
                <div className="timeline">
                  <div className="content_wrapper_2">
                    <div className="text_content_wrapper">
                      <p className="text_content">Content</p>
                      <div className="image_wrapper">
                        {!state?.videoDetails.IsSaved ? (
                          <img
                            alt="bookmark off"
                            src={images.user_activity_bookmark_off}
                            onClick={() => handleBookmarkVideo()}
                          />
                        ) : (
                          <img
                            alt="bookmark on"
                            src={images.user_activity_bookmark_on}
                            onClick={() =>
                              handleBookmarkVideo(state.videoDetails)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="topic_collection">
                      {!isEmpty(state?.videoDetails) &&
                        state?.videoDetails.content.length &&
                        state?.videoDetails.content.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              className="sub_content_wrapper"
                              onClick={() => handleSeekTo(item.content_time)}
                            >
                              <div className="left_side_content_wrapper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">
                                    <label className="key_tag">{`Topic - ${
                                      idx + 1
                                    } :`}</label>
                                    <label className="value_tag">
                                      {item.content}
                                    </label>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        jumpTo: state?.timeline?.map((e) => e?.seconds),
                      }))
                    }
                  >
                    {state?.timeline?.map((e) => e?.seconds)}
                  </button>
                </div>
              </div>
            </div>
            <div
              className={
                state.activeTab === 5 ? "tab-content active" : "tab-content"
              }
            >
              <div className="sec_2_inner_wrapper">
                {state.relatedVideo?.length ? (
                  <div
                    id="parentScrollDiv"
                    className="videosListBox"
                    style={{
                      height: "60vh",
                      overflow: "auto",
                      overflowY: "scroll",
                      padding: "5px",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={state.relatedVideo.length}
                      next={true}
                      hasMore={true}
                      scrollableTarget="parentScrollDiv"
                    >
                      <div className="videos_collection_wrapper">
                        {state.relatedVideo.map((element, index) => (
                          <div key={index} className="single_video_wrapper">
                            <div
                              className="left_side_content_wrapper"
                              onClick={() =>
                                handleStoreDataInStorage("videos", element)
                              }
                            >
                              <div className="image_wrapper">
                                <img
                                  src={element.video_thumnail}
                                  alt="thumbnail"
                                />
                              </div>
                            </div>
                            <div className="right_side_content_wrappers">
                              <div className="right_side_content_wrappers_top">
                                <div
                                  className="text_content_wrapper_1"
                                  onClick={() =>
                                    handleStoreDataInStorage("videos", element)
                                  }
                                >
                                  <p className="text_content_1">
                                    {element.video_title}
                                  </p>
                                </div>
                                <div className="image_wrapper">
                                  {element.IsSaved ? (
                                    <img
                                      src={images.user_activity_bookmark_on}
                                      alt="Bookmarked"
                                      onClick={() =>
                                        handleBookmarkVideo(element)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={images.user_activity_bookmark_off}
                                      alt="Not Bookmarked"
                                      onClick={() =>
                                        handleBookmarkVideo(element)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  by {element.name}
                                </p>
                              </div>
                              <div className="sub_content_wrapper">
                                <div className="right_side_content_wrappers">
                                  <button
                                    onClick={() =>
                                      handleStoreDataInStorage(
                                        "videos",
                                        element
                                      )
                                    }
                                  >
                                    Watch Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    <img src={images.new_m2_video_ntF} alt="No Videos" />
                    <p>There is no video yet!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    videoComponentTime: state.userBookmarkData.videoComponentTime,
    videoCurrentTime: state.userBookmarkData.videoCurrentTime,
    videoDetailsForTime: state.userBookmarkData.videoDetailsForTime,
    topicData: state.userActivity.topicData,
    subjectData: state.userActivity.subjectData,
    watchedVideoData: state.WatchedVideoDetailsSlice.watchedVideoDetails,
    videoLastPaused: state.WatchedVideoDetailsSlice.lastPaused,
    totalWatchTime: state.WatchedVideoDetailsSlice.totalWatchTime,
    currentVideoWatchTime: state.WatchedVideoDetailsSlice.currentvideoTime,
    token: state.auth.authToken,
    tokenType: state.auth.tokenType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
    setExerciseResultData: (payload) => {
      dispatch(userActivityActions.setExerciseResultData(payload));
    },
    setVideoComponentTime: (payload) => {
      dispatch(userBookmarkActions.setVideoComponentTime(payload));
    },
    setRelatedVideos: (payload) => {
      dispatch(userBookmarkActions.setRelatedVideos(payload));
    },
    setVideoDetailsForTime: (payload) => {
      dispatch(userBookmarkActions.setVideoDetailsForTime(payload));
    },
    setVideoCurrentTime: (payload) => {
      dispatch(userBookmarkActions.setVideoCurrentTime(payload));
    },

    setNoteAddedVideoTimeAction: (payload) => {
      dispatch(setNoteAddedVideoTime(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StreamingVideoDetails);
