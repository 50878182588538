import React from "react";
import "./mock_test.scss";
import { connect } from "react-redux";
import MockTestAdvance from "./mockMain&Adv&NeetComponent/mockTestAdvance";
import MockTestMain from "./mockMain&Adv&NeetComponent/mockTestMain";
import MockTestNeet from "./mockMain&Adv&NeetComponent/mockTestNeet";

const Mock_test = (props) => {
  let { userPreference } = props;

  let tab = "mains";

  let { targetDetails } = userPreference;

  const renderMainAdv = () => {
    return (
      <>
        <div className="mainAdvTabBox">
          <div className="mainAdvTab">
            <ul class="nav nav-tabs mainAdvExamUl" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class={tab == "mains" ? "nav-link active" : "nav-link"}
                  id="mains-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#mains"
                  type="button"
                  role="tab"
                  aria-controls="mains"
                  aria-selected="false"
                >
                  Jee Mains
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={tab == "advance" ? "nav-link active" : "nav-link"}
                  id="advance-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#advance"
                  type="button"
                  role="tab"
                  aria-controls="advance"
                  aria-selected="false"
                >
                  Jee Advance
                </button>
              </li>
            </ul>
          </div>

          <div
            className="mainAdvTabContent"
            style={{ height: "67vh", overflowX: "auto", padding: "8px" }}
          >
            <div class="tab-content" id="myTabContent">
              <div
                class={
                  tab == "mains" ? "tab-pane fade show active" : "tab-pane fade"
                }
                id="mains"
                role="tabpanel"
                aria-labelledby="mains-tab"
              >
                <MockTestMain />
              </div>

              <div
                class={
                  tab == "advance"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="advance"
                role="tabpanel"
                aria-labelledby="advance-tab"
              >
                <MockTestAdvance />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderNeet = () => {
    return (
      <>
        <MockTestNeet />
      </>
    );
  };

  return (
    <>
      <div className="mockTestWrapper">
        {targetDetails[0]?.target_id == "2" && renderMainAdv()}
        {targetDetails[0]?.target_id != "2" && renderNeet()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(Mock_test);
