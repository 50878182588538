import { React, useEffect, useState } from "react";
import "./AdaptiveCpsSubjects.scss";
import { isEmpty } from "../../../../../../../../custom_utilities/custom_useful_functions";
import { url_213 } from "../../../../../../../../custom_utilities/api_services";
import images from "../../../../../../../../utilities/images/images";
import axios from "axios";
import { Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../../../redux/ui/action";
import adaptiveCpsActions from "../../../../../../../../redux/adaptive_cps/action";
import { Modal } from "react-bootstrap";

const AdaptiveCpsSubjects = (props) => {
  let {
    userPreference,
    handleSubjectClick,
    handleSubjectClick2,
    is_subscribe,
    startLoading,
    finishLoading,
    CommonBannerData,
    tab,
  } = props;

  const [state, setState] = useState({
    topicSubjectList: {},
    showModal: false,
    selectedSubId: null,
  });
  useEffect(() => {
    getTopicSubjectList();
  }, []);

  const getPaperType = () => {
    const tabToPaperType = {
      adaptive_cps: 4,
      cps: 2,
      "Exam Cracker - JEE Mains": 9,
      "Exam Cracker - JEE Advanced": 8,
      "Exam Cracker - NEET": 6,
    };

    const paperType = tabToPaperType[tab];
    if (paperType === undefined) {
      console.warn(
        `Warning: No paper type found for tab "${tab}". Defaulting to 4.`
      );
      return 4;
    }
    return paperType;
  };

  // Fetching the subject list
  const getTopicSubjectList = async () => {
    let { courseDetails, classDetails } = userPreference;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_213}/?paper_type=${getPaperType()}`,
        data
      );
      if (response.data.status === 200) {
        finishLoading();
        // De-duplicate the subject list based on sub_id
        const uniqueSubjects = response.data.data.subject.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.sub_id === value.sub_id)
        );

        setState((prevState) => ({
          ...prevState,
          topicSubjectList: {
            ...response.data.data,
            subject: uniqueSubjects,
          },
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  let { subject: subjectList } = state.topicSubjectList;

  const handleSubjectClickBoth = (sub_name, sub_id) => {
    if (
      CommonBannerData?.data?.package_active == 0 &&
      CommonBannerData?.data?.is_attempt == 0
    ) {
      setState((prevState) => ({
        ...prevState,
        showModal: true,
        selectedSubId: sub_id,
      }));
    } else {
      handleSubjectClick2(sub_name);
    }
  };

  const backGroundColorFromBackend = (color_code) => {
    const colorCode = color_code ? color_code.split(",") : null;

    return colorCode
      ? {
          background: `linear-gradient(277deg,${colorCode[0]}, ${colorCode[1]} 101.46%)`,
        }
      : {};
  };

  return (
    <>
      <div className="ACpsTopicWiseWrapper justify-content-between">
        {!isEmpty(subjectList)
          ? subjectList.length
            ? subjectList.map((elem, index) => {
                return (
                  <div
                    key={index}
                    className="ACpsTopicBox"
                    onClick={() =>
                      handleSubjectClickBoth(elem.sub_name, elem.sub_id)
                    } // Pass sub_id here
                    style={backGroundColorFromBackend(elem.color_code)}
                  >
                    <div className="ACpsTopicNameImg">
                      <div className="ACpsImg_wrp">
                        <img src={elem.icon} alt="icon" />
                      </div>
                      <div className="name">{elem.sub_name}</div>
                    </div>
                    <div className="arrow">
                      <img src={images.right_Ar} alt="arrow" />
                    </div>
                  </div>
                );
              })
            : null
          : null}
      </div>
      <Modal
        id="acpsm"
        show={state.showModal}
        onHide={() =>
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }))
        }
        animation={false}
        centered
      >
        <Modal.Body>
          <p>Adaptive CPS</p>
          <p>
            <strong style={{ color: "#121212", fontWeight: "500" }}>
              All your free attempts have been completed!
            </strong>
          </p>
          <p>
            Please click on “<strong>Buy Now</strong>” button to purchase
            Adaptive CPS package for attempting further tests.
          </p>
          <div className="d-flex justify-content-center">
            <button>
              <Link
                to={`/store/${userPreference?.targetDetails?.map(
                  (e) => e?.target_id
                )}/${state?.selectedSubId}`}
                className="class-pill"
              >
                <span className="modal-button"> Buy Now</span>{" "}
              </Link>
            </button>
          </div>
          <div
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                showModal: false,
              }))
            }
          >
            <img src={images.close_btn} alt="close" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    CommonBannerData: state.userActivity.commonBannerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setAdaptiveCpsSubjectAction: (payload) => {
      dispatch(adaptiveCpsActions.setAdaptiveCpsSubject(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdaptiveCpsSubjects);
