import { Fragment, useState, useEffect } from "react";
import "./examCrackerTestContentComponent.scss";
import ExamCrackerTestInstructionView from "./examCrackerTestInstructionView/examCrackerTestInstructionView";
import ExamCrackerTestQuestionView from "./examCrackerTestQuestionView/examCrackerTestQuestionView";

import axios from "axios";
import { useLocation } from "react-router-dom";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import {
  url_116,
  url_117,
  url_203,
} from "../../../../../../custom_utilities/api_services";

const ExamCrackerTestContentComponent = (props) => {
  let {
    history,
    testData,
    startLoading,
    finishLoading,
    userData,
    userPreference,
    target_id,
  } = props;
  const [state, setState] = useState({
    step: 1,
    testDetails: {},
    testInstructionData: {},
    testQuestionsDetails: [],
    currentQuestionDetails: {},
    subjecSectiontWiseTestQuestions: {},
    checkQuestion: {},
    currentQuestion: 0,
    currentOptionId: null,
    page: 1,
    countDownTimer: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    initialTime: 0,
    useTime: 0,
    left: 0,
    is_paid: 0,
    hours: 72,
    minutes: 0,
    seconds: 0,
  });

  const location = useLocation();

  const package_name = location?.state?.package_name;

  useEffect(() => {}, [state.currentQuestion, state.testQuestionsDetails]);

  useEffect(() => {
    getTestDetails();
  }, []);

  useEffect(() => {
    if (state.step === 2) {
      createCountDownTimer(state.testDetails.duration_mins);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [state.step]);

  let timeInterval;

  const createCountDownTimer = (duration) => {
    let useTime = state.useTime ?? 0;
    useTime = useTime * 1000;

    let countdownTime = duration * 60 * 1000 + new Date().getTime();
    countdownTime = countdownTime - useTime;

    timeInterval = setInterval(() => {
      let now = new Date().getTime();
      let distance = countdownTime - now;
      if (distance < 0) {
        clearInterval(timeInterval);
        //hidemyend    //handleSubmitTest();
      }
      // console.log(distance);
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      // console.log("MINUTES=", minutes);
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // console.log("SECONDS=", seconds);
      setState((prevState) => ({
        ...prevState,
        countDownTimer: {
          ...prevState.countDownTimer,
          hours,
          minutes,
          seconds,
        },
      }));
    }, 1000);
  };

  const getTestDetails = () => {
    setState((prevState) => ({ ...prevState, testDetails: testData }));
  };

  const getTestQuestionsList = async () => {
    const trimmedAndLowercasedPackageName = package_name
      .replace(/\s+/g, "")
      .toLowerCase();
    let target_id;

    if (trimmedAndLowercasedPackageName === "examcracker-jeemain") {
      target_id = 1;
    } else if (trimmedAndLowercasedPackageName === "examcracker-jeeadvanced") {
      target_id = 2;
    } else if (trimmedAndLowercasedPackageName === "examcracker-neet") {
      target_id = 3;
    }

    const data = {
      subject_id: testData.subject.subjectId,
      topic_id: testData.topic_id,
      sub_topic_name: testData.topic_name,
      page: 0,
      lang_id: 1,
      target_id: target_id,
    };

    startLoading();
    try {
      // const response = await axios.post(url_115, data);
      const response = await axios.post(url_203, data);

      if (response.data.status === 200) {
        finishLoading();
        getCurrentQuestionDetails(state.page);
        return {
          result: 1,
          data: { apiData: response.data },
        };
      } else {
        finishLoading();
        return {
          result: 0,
          data: { apiData: {} },
        };
      }
    } catch (error) {
      console.log(error);
      finishLoading();
      return {
        result: 0,
        data: { apiData: {} },
      };
    }
  };

  const getCurrentQuestionDetails = async (page) => {
    const trimmedAndLowercasedPackageName = package_name
      .replace(/\s+/g, "")
      .toLowerCase();
    let target_id;

    if (trimmedAndLowercasedPackageName === "examcracker-jeemain") {
      target_id = 1;
    } else if (trimmedAndLowercasedPackageName === "examcracker-jeeadvanced") {
      target_id = 2;
    } else if (trimmedAndLowercasedPackageName === "examcracker-neet") {
      target_id = 3;
    }

    const data = {
      subject_id: testData.subject.subjectId,
      topic_id: testData.topic_id,
      sub_topic_name: testData.topic_name,
      page: page,
      lang_id: 1,
      target_id: target_id,
    };

    // startLoading();
    try {
      const response = await axios.post(url_203, data);

      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          currentQuestionDetails: response.data.data.AllQuestionList,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNowTime = () => {
    let d = new Date();
    //3 days from the time created
    const date_created = new Date(userData.created_on);
    const numOfHours = 72;
    date_created.setTime(date_created.getTime() + numOfHours * 60 * 60 * 1000);
    console.log(d);
    let diffInMilliseconds = Math.abs(Math.abs(date_created - d) / 1000);

    // let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliseconds / 86400);

    // calculate hours
    const hours = 24 * days + (Math.floor(diffInMilliseconds / 3600) % 24);
    diffInMilliseconds -= hours * 3600;
    console.log("calculated hours", hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliseconds / 60) % 60;
    // diffInMilliseconds -= minutes * 60;
    console.log(hours + ":" + minutes);

    return d.getTime();
  };

  const startBtn = async () => {
    // let testSectionsDetails = { ...state.testSectionsDetails };
    let testDetails = { ...state.testDetails };
    if (!isEmpty(testDetails)) {
      let returnedResponse = await getTestQuestionsList();

      if (returnedResponse.result) {
        if (returnedResponse?.data?.apiData?.data?.AllQuestionList) {
          returnedResponse?.data?.apiData?.data?.AllQuestionList?.forEach(
            (element) => {
              element.isReview = false;
              element.isAttempted = false;
              element.isAnswerGuessed = false;
              element.totalTimeTaken = 0;
              element.isAnswerCorrect = 0;
              element.correctAnswerIdCollection = [];
              element.wrongAnswerIdCollection = [];
              element.userAnswerCollection = [];
            }
          );

          let time = getNowTime();

          setState((prevState) => ({
            ...prevState,
            step: 2,
            testQuestionsDetails:
              returnedResponse.data.apiData.data.AllQuestionList,
            //subjecSectiontWiseTestQuestions: _subjecSectiontWiseTestQuestions,
            initialTime: time,
            useTime: returnedResponse.data.apiData.total_time,
          }));
        } else {
          alert("No test questions found");
        }
      } else {
        alert("No test questions found");
      }
    } else {
      alert("You don't have Test Details");
    }
  };

  const getCalculatedTime = (initialTime) => {
    let d = new Date();
    let time = d.getTime();

    let calculatedTime = Math.round((time - initialTime) / 1000);

    return calculatedTime;
  };

  const handleCurrentOption = (optionId) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentOptionId: optionId,
      };
    });
  };

  const handleSaveQuestion = async () => {
    let {
      currentQuestion,
      initialTime,
      testDetails,
      currentQuestionDetails,
      testQuestionsDetails,
    } = state;

    let formatData =
      testDetails.format ===
      "MAIN PATTERN TEST  [20 SINGLE, 10 NUMERIC] EACH SUBJECT"
        ? "MAIN"
        : testDetails.format === "NEET NEW PATTERN 2021 OTS"
        ? "NEET"
        : "OTHER";

    let calculatedTime = getCalculatedTime(initialTime);

    let data = {};

    if (currentQuestionDetails.answer_type == "Integer Correct") {
      data = {
        option_int: testQuestionsDetails[currentQuestion]?.integer_answer
          ?.length
          ? testQuestionsDetails[currentQuestion].integer_answer
          : "",
        qid: currentQuestionDetails.qid,
        subject: testData.subject.subjectName,
        time: calculatedTime,
        toughness: currentQuestionDetails.toughness_name,
      };
    } else if (currentQuestionDetails.answer_type == "Multiple Correct") {
      data = {
        option_id: currentQuestionDetails?.userAnswerCollection?.length
          ? currentQuestionDetails.userAnswerCollection.map((item) => ({
              option_id: item.option_id,
            }))
          : [],
        qid: currentQuestionDetails.qid,
        subject: testData.subject.subjectName,
        time: calculatedTime,
        toughness: currentQuestionDetails.toughness_name,
      };
    } else {
      data = {
        option_id: state.currentOptionId,
        qid: currentQuestionDetails.qid,
        subject: testData.subject.subjectName,
        time: calculatedTime,
        toughness: currentQuestionDetails.toughness_name,
      };
    }

    startLoading();
    try {
      const response = await axios.post(url_116, data);

      if (response.data.status === 200) {
        finishLoading();
      }
      finishLoading();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextQuestionBtn = (page) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;

    let calculatedTime = getCalculatedTime(initialTime);
    currentQuestionDetails.totalTimeTaken = calculatedTime;

    handleSaveQuestion();
    if (
      testQuestionsDetails[currentQuestion].isAttempted ||
      testQuestionsDetails[currentQuestion].option.filter(
        (m) => m.is_user_correct_ans
      ).length
    ) {
      testQuestionsDetails[currentQuestion].isReview = false;
    } else {
      testQuestionsDetails[currentQuestion].isReview = true;
    }
    let time = getNowTime();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: currentQuestion + 1,
      testQuestionsDetails: testQuestionsDetails,
      initialTime: time,
      page: page,
    }));

    if (testQuestionsDetails[currentQuestion].isAttempted) {
      getCurrentQuestionDetails(page);
    }

    window.scrollTo(0, 0);
  };

  const handlePreviousQuestionBtn = (page) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;
    let calculatedTime = getCalculatedTime(initialTime);

    // testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    currentQuestionDetails.totalTimeTaken = calculatedTime;

    let time = getNowTime();

    handleSaveQuestion();
    //handleCheckQuestion();
    if (currentQuestion >= 1) {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion - 1,
        testQuestionsDetails: testQuestionsDetails,
        initialTime: time,
        page: page,
      }));
      getCurrentQuestionDetails(page);

      window.scrollTo(0, 0);
    }
    // if (state.testQuestionsDetails[state.currentQuestion].option.filter((opt) => {
    //   opt.
    // }))
  };

  const handleSingleSelectAnswer = (event, optionId) => {
    let { checked } = event.target;
    let { currentQuestion, testQuestionsDetails } = state;
    //localStorage.setItem("current", currentQuestion);
    if (checked) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
      testQuestionsDetails[currentQuestion].userAnswerCollection[0] = {
        option_id: optionId,
      };

      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        //if you not find the answer in backendCorrect answer array
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection[0] =
          Number(optionId);
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection[0] =
          optionId;
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
        testQuestionsDetails[currentQuestion].isAnswerCorrect = 1;
      }
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
      testQuestionsDetails[currentQuestion].userAnswerCollection = [];
      testQuestionsDetails[currentQuestion].wrongAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].correctAnswerIdCollection = [];
      testQuestionsDetails[currentQuestion].isAnswerCorrect = 0;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleMultiSelectAnswer = (_event, optionId) => {
    let { currentQuestion, testQuestionsDetails } = state;

    let findedIndex = testQuestionsDetails[
      currentQuestion
    ].userAnswerCollection.findIndex((element) => {
      return element.option_id === optionId;
    });

    if (findedIndex === -1) {
      let Obj = {
        option_id: optionId,
      };
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        testQuestionsDetails[currentQuestion].userAnswerCollection.length,
        0,
        Obj
      );
    } else {
      testQuestionsDetails[currentQuestion].userAnswerCollection.splice(
        findedIndex,
        1
      );
    }

    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    if (
      testQuestionsDetails[currentQuestion].userAnswerCollection.indexOf(
        optionId
      ) === -1
    ) {
      if (
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.indexOf(
            optionId
          ),
          1
        );
      }
    } else {
      if (
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.indexOf(
          optionId
        ) === -1
      ) {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[currentQuestion].correctAnswerIdCollection
            .length,
          0,
          optionId
        );
      } else {
        testQuestionsDetails[currentQuestion].correctAnswerIdCollection.splice(
          testQuestionsDetails[
            currentQuestion
          ].correctAnswerIdCollection.indexOf(optionId),
          1
        );
      }
    }

    if (testQuestionsDetails[currentQuestion].wrongAnswerIdCollection.length) {
      testQuestionsDetails[currentQuestion].isAnswerCorrect = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleInputFieldChange = (event) => {
    let { value } = event.target;
    // value = value.replace(/[^0-9]/g, "")
    let { currentQuestion, testQuestionsDetails } = state;
    testQuestionsDetails[currentQuestion].integer_answer = value.trim();

    if (testQuestionsDetails[currentQuestion].integer_answer.length) {
      testQuestionsDetails[currentQuestion].isAttempted = true;
    } else {
      testQuestionsDetails[currentQuestion].isAttempted = false;
    }

    setState((prevState) => ({ ...prevState, testQuestionsDetails }));
  };

  const handleQuestionJump = (questionNumber) => {
    let {
      currentQuestion,
      initialTime,
      testQuestionsDetails,
      currentQuestionDetails,
    } = state;
    let calculatedTime = getCalculatedTime(initialTime);
    testQuestionsDetails[currentQuestion].totalTimeTaken = calculatedTime;
    handleSaveQuestion();
    setState((prevState) => ({
      ...prevState,
      currentQuestion: questionNumber,
      page: questionNumber + 1,
    }));
    getCurrentQuestionDetails(questionNumber + 1);
  };

  const handleSubmitTest = async () => {
    let { testDetails, testQuestionsDetails } = state;
    let questionArr = [];

    testQuestionsDetails.forEach((element) => {
      if (element.answer_type.toLowerCase() === "single correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "multiple correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          // toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      } else if (element.answer_type.toLowerCase() === "integer correct") {
        let data = {
          qid: element.qid,
          type_id: element.type_id,
          is_saved: element.is_saved,
          total_time: element.totalTimeTaken,
          is_attempted: element.isAttempted,
          paragraph_answer: element.paragraph_answer,
          integer_answer: element.integer_answer,
          is_AnswerCorrect: element.isAnswerCorrect,
          is_GessTypeAnswer: element.isAnswerGuessed,
          positive_marks: element.positive_marks,
          negative_marks: element.negative_marks,
          partial_marks: element.partial_marks,
          CorrectAnswerId: element.correctAnswerIdCollection,
          WrongeAnswerId: element.wrongAnswerIdCollection,
          answer: element.userAnswerCollection,
          toughness_id: element.toughness_id,
          subject: element.subject,
          topic_id: element.topic_id,
        };
        if (!data.type_id) {
          data.type_id = 6;
        }
        questionArr.push(data);
      }
    });
    let returnedResponse = await submitTestApi(
      userPreference,
      testDetails,
      questionArr
    );

    console.log(returnedResponse);
    if (returnedResponse.result) {
      finishLoading();
      history.push({
        pathname: "/exam_cracker",
        state: {
          tab: "exam_cracker",
        },
      });
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };

  const submitTestApi = async () => {
    let { currentQuestion, testQuestionsDetails } = state;
    let crntQuestionDetail = testQuestionsDetails[currentQuestion];
    let requestPayload = {
      topic_id: testData.topic_id,
      time: crntQuestionDetail.totalTimeTaken,
    };
    try {
      const response = await axios.post(url_117, requestPayload);

      if (response.data.status === 200) {
        return {
          result: 1,
          data: {
            message: response.data.message,
          },
        };
      } else {
        return {
          result: 0,
          data: {
            message: response.data.message,
          },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: {
          message: "Something went wrong!!!",
        },
      };
    }
  };

  const handleRedirectBtn = () => {
    history.push("/exam_cracker");
  };
  const renderView = (step) => {
    switch (step) {
      case 1:
        return <ExamCrackerTestInstructionView startBtn={startBtn} />;
      case 2:
        return (
          <ExamCrackerTestQuestionView
            testQuestionsDetails={state.testQuestionsDetails}
            currentQuestionDetails={state.currentQuestionDetails}
            page={state.page}
            subjecSectiontWiseTestQuestions={
              state.subjecSectiontWiseTestQuestions
            }
            handleCurrentOption={handleCurrentOption}
            currentQuestion={state.currentQuestion}
            countDownTimer={state.countDownTimer}
            handlePreviousQuestionBtn={handlePreviousQuestionBtn}
            handleNextQuestionBtn={handleNextQuestionBtn}
            handleSaveQuestion={handleSaveQuestion}
            handleSingleSelectAnswer={handleSingleSelectAnswer}
            handleMultiSelectAnswer={handleMultiSelectAnswer}
            handleInputFieldChange={handleInputFieldChange}
            handleQuestionJump={handleQuestionJump}
            handleSubmitTest={handleSubmitTest}
            checkQuestion={state.checkQuestion}
            userId={userData.user_id}
            startLoading={startLoading}
            finishLoading={finishLoading}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="exam_cracker_test_content_component_wrapper">
        <div className="test_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

export default ExamCrackerTestContentComponent;
