import { Fragment } from "react";
import "./HomeworkTest.scss";
import LoaderPopUp from "../../../../../../components/loader/loader";
import HomeworkTestData from "./HomeworkTestData";
//Redux
import { connect } from "react-redux";

const HomeworkTest = (props) => {
  let { history, loader, userData } = props;
  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          <Fragment>
            <HomeworkTestData userData={userData} history={history} />
          </Fragment>
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(HomeworkTest);
