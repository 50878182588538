import React from "react";
import { Modal } from "react-bootstrap";
import "./HomeWorkDetailModal.scss";

const HomeWorkDetailModal = ({ handleCloseDetailModal }) => {
  return (
    <Modal show={true} onHide={handleCloseDetailModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="hmwk_detail_container">
          <div className="hmwk_detail_header">HomeWork Syallbus</div>
          <div className="hmwk_content">
            <table>
              <thead>
                <tr className="hmwk_tbl_header">
                  <th>Module sheet</th>
                  <th>CPS</th>
                  <th>PYQ</th>
                  <th>EXAMS</th>
                </tr>
              </thead>

              <tbody className="hmwk_tbl_body">
                <tr>
                  <td>
                    Modern Physics-1, SMH, Modern Physics-2, Waves & sound waves
                  </td>
                  <td>
                    Aldehyde, ketones & carboxylic acid, aromatic compound,
                    carbonyl compound,Chemical equilibrium, Ionic equilibrium
                  </td>{" "}
                  <td>
                    Differential equation, probability, area under the curve,
                    ellipse, parabola hyperbola
                  </td>{" "}
                  <td>
                    Differential equation, probability, area under the curve,
                    ellipse, parabola hyperbola
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="hmwk_detail_footer" onClick={handleCloseDetailModal}>
            Done
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HomeWorkDetailModal;
