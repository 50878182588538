import React, { Fragment, useState, useRef } from "react";
import "./QuestionBankTestQuestionsView.scss";
import { Button, Modal } from "react-bootstrap";
import images from "../../../../../../utilities/images/images";
import axios from "axios";
import {
  url_101,
  url_102,
} from "../../../../../../custom_utilities/api_services";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastifyMessage } from "../../../../../../custom_utilities/toastify_config";
import ShakaSolutionViewVideoPlayer from "../../../../../../custom_utilities/shakaVideoPlayer/ShakaSolutionViewVideoPlayer";
import useMathjax from "../../../../../../hooks/useMathjax";
import GridIcon from "../../../../../../utilities/images/test-screens/GridIcon";
import GridIcon1 from "../../../../../../utilities/images/test-screens/GridIcon1";
import ListIcon from "../../../../../../utilities/images/test-screens/ListIcon";
import ListIcon1 from "../../../../../../utilities/images/test-screens/ListIcon1";

const QuestionBankTestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSaveQuestion,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleQuestionJump,
    handleSubmitTest,
    userId,
    startLoading,
    finishLoading,
  } = props;

  const questionsCollectionRef = useRef(null);
  const [reportErrorData, setReportErrorData] = useState([]);
  const [isList, setisList] = useState(true);
  const [saveReportErrorData, setSaveReportErrorData] = useState({
    user_id: userId,
    q_id: testQuestionsDetails[currentQuestion].qid,
    option_id: "",
    description: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [videoShow, setVideoShow] = useState(false);
  const [showError, setShowError] = useState(false);
  // for report error modal
  const [lang, setLang] = useState(true);
  const [sol, setSol] = useState(true);
  const [disabled1, setDisabled] = useState(false);

  const isDisable =
    saveReportErrorData.option_id.length == 0 ||
    saveReportErrorData.description.length == 0
      ? true
      : false;
  const handleCloseReportError = () => {
    setShowError(false);
    setSaveReportErrorData({ option_id: "" });
  };
  const handleShowReportError = () => setShowError(true);

  const handleGetReportErrorOptions = () => {
    startLoading();

    try {
      axios.get(url_101).then((response) => {
        setReportErrorData([...response.data.data]);

        if (response.status === 200) {
          finishLoading();
          handleShowReportError();
        }
      });
    } catch (error) {
      finishLoading();
    }
  };

  //function for posting user error data to api

  const handlePostReportErrorData = () => {
    startLoading();
    try {
      axios.post(url_102, saveReportErrorData).then((response) => {
        if (response.data.status === 200) {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Submitted Successfully",
          });
          setSaveReportErrorData({ option_id: "" });
        } else {
          finishLoading();
          handleCloseReportError();
          toastifyMessage({
            status: response.data.status,
            message: "Your Report has Failed",
          });
        }
      });
    } catch (error) {}
  };

  const scrollUp = () => {
    const nextQuestionIndex = currentQuestion + 1;
    const questionElement =
      questionsCollectionRef.current.children[nextQuestionIndex];
    questionElement?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollUp2 = () => {
    const prevQuestionIndex = currentQuestion - 1;
    const questionElement =
      questionsCollectionRef.current.children[prevQuestionIndex];
    questionElement?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useMathjax(currentQuestion, lang, sol);

  const handleSol = () => {
    setSol(true);
    setDisabled(false);
  };
  const handleSolution = () => {
    setSol(false);
    setVideoShow(false);
  };

  const disableSol = () => {
    setDisabled(true);
  };

  const renderOptions = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr.map((val, i) => {
        let step, type;
        type = val.answer_type;
        if (type.toLowerCase() === "single correct") {
          step = 1;
        } else if (type.toLowerCase() === "integer correct") {
          step = 2;
        } else {
          step = 3;
        }
        switch (step) {
          case 1:
            return renderSingleSelect(questionsArr[i], i, lang);
          case 2:
            return renderInputField(i);
          case 3:
            return renderMultiSelect(questionsArr[i], i, lang);
          default:
            return null;
        }
      });
    } else {
      return null;
    }
  };
  const classEditor = (s, wc, optionId) => {
    if (!sol && Number(s)) {
      return "single_option_wrapper classCorrect";
    } else if (!sol && wc && wc.indexOf(Number(optionId)) > -1) {
      return "single_option_wrapper classWrong";
    } else {
      return "single_option_wrapper";
    }
  };

  const handlePurticularQuestion = () => {
    if (
      testQuestionsDetails[currentQuestion - 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion - 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const handlePurticularQuestion3 = () => {
    if (
      testQuestionsDetails[currentQuestion + 1].userAnswerCollection.length ||
      testQuestionsDetails[currentQuestion + 1].isAttempted === true
    ) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const renderQuestion = (currentQuestionDetails, index) => {
    if (currentQuestion == index) {
      if (lang) {
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: currentQuestionDetails.q_text,
            }}
          ></p>
        );
      } else {
        if (ConvertStringToHTML(currentQuestionDetails?.q_text_hindi)) {
          return (
            <p
              dangerouslySetInnerHTML={{
                __html: currentQuestionDetails.q_text_hindi,
              }}
            ></p>
          );
        } else {
          return (
            <p
              dangerouslySetInnerHTML={{
                __html: currentQuestionDetails.q_text,
              }}
            ></p>
          );
        }
      }
    }
  };

  // method for converting string(coming from api) into hmtl
  let ConvertStringToHTML = function (str) {
    if (!str) {
      return false;
    }
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");

    return doc.body.children.length > 0;
  };

  const handlePurticularQuestion2 = (currentQuestion) => {
    if (testQuestionsDetails[currentQuestion].userAnswerCollection.length) {
      handleSolution();
    } else {
      handleSol();
    }
  };

  const renderSingleSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang == true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "examcrcker_single_select_option_collection_wrapper show"
                : "examcrcker_single_select_option_collection_wrapper hide"
            }
          >
            {questionData.option?.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id == 1) {
                    return (
                      <div key={index} className="excrker_single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          disabled={sol ? false : true}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[currentQuestion2]
                                  .userAnswerCollection[0] === element.option_id
                              : element.is_user_correct_ans == 1
                              ? true
                              : false
                          }
                          // checked={element.is_user_correct_ans == 1}
                          onChange={(event) => {
                            handleSingleSelectAnswer(event, element.option_id);
                            disableSol();
                          }}
                        />

                        <label
                          htmlFor={element.option_id}
                          className={classEditor(
                            element.is_correct,
                            testQuestionsDetails[currentQuestion2]
                              .wrongAnswerIdCollection,
                            element.option_id
                          )}
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (lang == false) {
      if (questionData.option !== undefined && questionData.option !== null) {
        return (
          <Fragment>
            <div
              className={
                currentQuestion2 == currentQuestion
                  ? "examcrcker_single_select_option_collection_wrapper show"
                  : "examcrcker_single_select_option_collection_wrapper hide"
              }
            >
              {questionData.option?.length
                ? questionData.option.map((element, index) => {
                    if (element.lang_id == 2) {
                      return (
                        <div key={index} className="excrker_single_select">
                          <input
                            type="checkbox"
                            disabled={sol ? false : true}
                            id={element.option_id}
                            name={`current_question_${currentQuestion2}`}
                            checked={
                              testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection.length
                                ? testQuestionsDetails[currentQuestion2]
                                    .userAnswerCollection[0] ===
                                  element.option_id
                                : element.is_user_correct_ans == 1
                                ? true
                                : false
                            }
                            // checked={element.is_user_correct_ans == 1}
                            onChange={(event) => {
                              handleSingleSelectAnswer(
                                event,
                                element.option_id
                              );
                              disableSol();
                            }}
                          />
                          <label
                            htmlFor={element.option_id}
                            className={classEditor(
                              element.is_correct,
                              testQuestionsDetails[currentQuestion2]
                                .wrongAnswerIdCollection,
                              element.option_id
                            )}
                          >
                            <div className="option_initial">
                              <p className="text_content_2">{`${renderOptionInitial(
                                index
                              )}.`}</p>
                            </div>
                            <div className="option_final">
                              <p
                                className="text_content_3"
                                dangerouslySetInnerHTML={renderOptionText(
                                  element.option
                                )}
                              ></p>
                            </div>
                          </label>
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className="excrker_single_select">
                          <input
                            type="checkbox"
                            id={element.option_id}
                            disabled={sol ? false : true}
                            name={`current_question_${currentQuestion2}`}
                            checked={
                              testQuestionsDetails[currentQuestion2]
                                .userAnswerCollection.length
                                ? testQuestionsDetails[currentQuestion2]
                                    .userAnswerCollection[0] ===
                                  element.option_id
                                : element.is_user_correct_ans == 1
                                ? true
                                : false
                            }
                            // checked={element.is_user_correct_ans == 1}
                            onChange={(event) => {
                              handleSingleSelectAnswer(
                                event,
                                element.option_id
                              );
                              disableSol();
                            }}
                          />
                          <label
                            htmlFor={element.option_id}
                            className={classEditor(
                              element.is_correct,
                              testQuestionsDetails[currentQuestion2]
                                .wrongAnswerIdCollection,
                              element.option_id
                            )}
                          >
                            <div className="option_initial">
                              <p className="text_content_2">{`${renderOptionInitial(
                                index
                              )}.`}</p>
                            </div>
                            <div className="option_final">
                              <p
                                className="text_content_3"
                                dangerouslySetInnerHTML={renderOptionText(
                                  element.option
                                )}
                              ></p>
                            </div>
                          </label>
                        </div>
                      );
                    }
                  })
                : null}
            </div>
          </Fragment>
        );
      }
    }
  };

  const renderMultiSelect = (questionData, currentQuestion2, lang) => {
    if (
      lang == true &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "examcracker_multi_select_option_collection_wrapper show"
                : "examcracker_multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id == 1) {
                    return (
                      <div key={index} className="excrker_single_select">
                        <input
                          type="checkbox"
                          id={element.option_id}
                          disabled={sol ? false : true}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) => {
                            handleMultiSelectAnswer(event, element.option_id);
                            disableSol();
                          }}
                        />
                        <label
                          htmlFor={element.option_id}
                          className={classEditor(
                            element.is_correct,
                            testQuestionsDetails[currentQuestion2]
                              .wrongAnswerIdCollection,
                            element.option_id
                          )}
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    } else if (
      lang == false &&
      questionData.option !== undefined &&
      questionData.option !== null
    ) {
      return (
        <Fragment>
          <div
            className={
              currentQuestion2 == currentQuestion
                ? "examcracker_multi_select_option_collection_wrapper show"
                : "examcracker_multi_select_option_collection_wrapper hide"
            }
          >
            {questionData.option.length
              ? questionData.option.map((element, index) => {
                  if (element.lang_id == 2) {
                    return (
                      <div key={index} className="excrker_single_select">
                        <input
                          type="checkbox"
                          disabled={sol ? false : true}
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) => {
                            handleMultiSelectAnswer(event, element.option_id);
                            disableSol();
                          }}
                        />
                        <label
                          htmlFor={element.option_id}
                          className={classEditor(
                            element.is_correct,
                            testQuestionsDetails[currentQuestion2]
                              .wrongAnswerIdCollection,
                            element.option_id
                          )}
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="excrker_single_select">
                        <input
                          type="checkbox"
                          disabled={sol ? false : true}
                          id={element.option_id}
                          name={`current_question_${currentQuestion2}`}
                          checked={
                            testQuestionsDetails[currentQuestion2]
                              .userAnswerCollection.length
                              ? testQuestionsDetails[
                                  currentQuestion2
                                ].userAnswerCollection.find((item) => {
                                  return item === element.option_id;
                                })
                              : false
                          }
                          onChange={(event) => {
                            handleMultiSelectAnswer(event, element.option_id);
                            disableSol();
                          }}
                        />
                        <label
                          htmlFor={element.option_id}
                          className={classEditor(
                            element.is_correct,
                            testQuestionsDetails[currentQuestion2]
                              .wrongAnswerIdCollection,
                            element.option_id
                          )}
                        >
                          <div className="option_initial">
                            <p className="text_content_2">{`${renderOptionInitial(
                              index
                            )}.`}</p>
                          </div>
                          <div className="option_final">
                            <p
                              className="text_content_3"
                              dangerouslySetInnerHTML={renderOptionText(
                                element.option
                              )}
                            ></p>
                          </div>
                        </label>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </Fragment>
      );
    }
  };

  const renderInputField = (currentQuestion2) => {
    return (
      <Fragment>
        <div
          className={
            currentQuestion2 == currentQuestion
              ? "input_wrapper show2"
              : "input_wrapper hide"
          }
        >
          <div className="input_inner_wrapper">
            <input
              key={currentQuestion2}
              disabled={sol ? false : true}
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion].integer_answer || ""}
              onChange={(event) => {
                handleInputFieldChange(event);
                disableSol();
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionLabel1 = (question, index) => {
    if (currentQuestion === index) {
      return (
        <label
          className="unanswered_lbl1 current_lbl"
          onClick={() => {
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox1">
            <div className="questionNumber"> {`${index + 1}.`} </div>

            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang
                    ? question.q_text
                    : question.q_text_hindi !== null
                    ? question.q_text_hindi
                    : question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="" />
        </label>
      );
    } else if (question.isAttempted) {
      return (
        <label
          className="unanswered_lbl1 answered_lbl"
          onClick={() => {
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox1">
            <div className="questionNumber"> {`${index + 1}.`} </div>

            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang
                    ? question.q_text
                    : question.q_text_hindi !== null
                    ? question.q_text_hindi
                    : question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} alt="" />
        </label>
      );
    } else {
      return (
        <label
          className="unanswered_lbl1"
          onClick={() => {
            handleQuestionJump(index);
            handlePurticularQuestion2(index);
          }}
        >
          <div className="questionBox1">
            <div className="questionNumber"> {`${index + 1}.`} </div>
            <div className="QBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: lang
                    ? question.q_text
                    : question.q_text_hindi !== null
                    ? question.q_text_hindi
                    : question.q_text,
                }}
              ></p>
            </div>
          </div>
          <img className="arrow" src={images.right_Ar} />
        </label>
      );
    }
  };

  const renderQuestionLabel = (question, index) => {
    let labelClassName = "unanswered_lbl";
    if (currentQuestion === index) {
      labelClassName += " current_lbl";
    } else if (question.isAttempted) {
      labelClassName += " answered_lbl";
    }

    return (
      <label
        className={labelClassName}
        onClick={() => {
          handleQuestionJump(index);
          handlePurticularQuestion2(index);
        }}
      >
        <div className="questionBox">{index + 1}</div>
      </label>
    );
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userId}"}`,
      "*"
    );

    // //////jump to particular time///////////////////////
    // frame.contentWindow.postMessage(JSON.stringify({ seekTo: 1 }), "*");

    ////////////////testing///////////////////////////////////
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data && e.data.currentTime) {
        let videoTimmingDetails = { currentTime: e.data.currentTime };
        sessionStorage.setItem(
          "videoTimmingDetails",
          JSON.stringify(videoTimmingDetails)
        );
      }
    });
  };

  const handleVideoShow = () => {
    setVideoShow(!videoShow);
  };

  const renderVideo = (currentQuestionDetails) => {
    const videoId = currentQuestionDetails?.video_id_english;
    const hindiVideoId = currentQuestionDetails?.video_id_hindi;
    const englishVideoCriptId = currentQuestionDetails?.english_video_cript_id;
    const hindiVideoCriptId = currentQuestionDetails?.hindi_video_cript_id;

    const isHindi = !lang && hindiVideoId && hindiVideoCriptId;
    const selectedVideoId = isHindi ? hindiVideoId : videoId;
    const selectedVideoCriptId = isHindi
      ? hindiVideoCriptId
      : englishVideoCriptId;

    const urlPattern = /^https:\/\/speed\.mogiio\.com\/embed3\/[0-9a-f]{24}$/;

    if (!urlPattern.test(selectedVideoId)) {
      return null;
    }

    const buttonLabel = lang ? "View Video Solution" : "वीडियो समाधान देखें";

    if (selectedVideoCriptId && selectedVideoCriptId.trim() !== "") {
      return (
        <div className="excrvideoWrapper">
          <button
            className="examcracker_view_videoSolution_btn"
            onClick={handleVideoShow}
          >
            {buttonLabel}
          </button>
          {videoShow ? (
            <ShakaSolutionViewVideoPlayer
              id_video={selectedVideoCriptId}
              className="shakaPlayer"
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="excrvideoWrapper">
          {selectedVideoId && (
            <button
              className="examcracker_view_videoSolution_btn"
              onClick={handleVideoShow}
            >
              {buttonLabel}
            </button>
          )}

          {videoShow ? (
            <iframe
              id="iframe"
              src={selectedVideoId}
              onLoad={handleOnLoad}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : null}
        </div>
      );
    }
  };

  const renderSolutions = (currentQuestionDetails) => {
    if (!sol) {
      let solutionText = currentQuestionDetails.sol_text;
      let solutionHeading = "Solution :";

      if (!lang && currentQuestionDetails.sol_text_hindi) {
        const hindiSolutionText = currentQuestionDetails.sol_text_hindi;
        const hindiSolutionHeading = "समाधान :";

        const isHindiSolutionEmpty =
          !hindiSolutionText.trim() || hindiSolutionText.includes("<html>");

        if (!isHindiSolutionEmpty) {
          solutionText = hindiSolutionText;
          solutionHeading = hindiSolutionHeading;
        }
      }

      return (
        <>
          <p className="solHeading">{solutionHeading}</p>
          <p
            className="solution-text"
            dangerouslySetInnerHTML={{
              __html: solutionText,
            }}
          ></p>
        </>
      );
    }
  };

  return (
    <Fragment>
      <div className="questionbank_test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="questionBnktestProgressIndicator">
                      <div className="qstnCount">
                        <span>{currentQuestion + 1}</span>
                        {` / `}
                        <span>{testQuestionsDetails.length}</span>
                      </div>

                      <div className="languageSwitcherIcon">
                        {testQuestionsDetails[currentQuestion] &&
                        testQuestionsDetails[currentQuestion]?.q_text_hindi ? (
                          <button
                            style={{ backgroundColor: "#263238" }}
                            onClick={() => setLang(!lang)}
                          >
                            {lang ? "अ" : "A"}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div
                      className="qstnBnkquestionAndSolutionWrapper"
                      style={{
                        height: "67vh",
                        overflowY: "auto",
                        padding: "8px",
                      }}
                    >
                      <div className="quesitonInfoSection">
                        <div className="text_content_wrapper">
                          <div className="typeLanguageWrapper">
                            <span>
                              <span className="qstnTypeKey">
                                Question Type:{" "}
                              </span>
                              {
                                testQuestionsDetails[currentQuestion]
                                  ?.answer_type
                              }
                            </span>
                          </div>

                          <div className="qstnCount">
                            Question {`${Number(currentQuestion) + 1}.`}
                          </div>

                          <div className="questionBox">
                            {testQuestionsDetails.length
                              ? testQuestionsDetails.map((element, index) => {
                                  return renderQuestion(element, index);
                                })
                              : null}
                          </div>
                        </div>
                        <div className="questionOptions">{renderOptions()}</div>

                        <div className="solutionBoxWrapper"></div>
                        {!sol ? (
                          <div>
                            {testQuestionsDetails.length
                              ? testQuestionsDetails.map((element, index) => {
                                  return (
                                    <>
                                      {index === currentQuestion &&
                                        renderSolutions(element)}
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="excrvideoWrapper">
                          {testQuestionsDetails.length
                            ? testQuestionsDetails.map((elem, index) => {
                                if (index == currentQuestion) {
                                  return <>{renderVideo(elem)}</>;
                                }
                              })
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="qstbBnkquestionSwitcherWrrpr">
                      <div className="reportAndError">
                        <span>
                          <img src={images.user_activity_14_1} alt=""></img>
                        </span>
                        <span
                          onClick={handleGetReportErrorOptions}
                          className="reportAndErrText"
                        >
                          Report an error
                        </span>
                      </div>

                      <div className="qstnBnkquestionSwitcherWrrpr">
                        <div className="exc_left_side_wrapper">
                          {currentQuestion > 0 && (
                            <div className="btn_wrapper">
                              <button
                                onClick={() => {
                                  handlePreviousQuestionBtn();
                                  scrollUp2();
                                  handlePurticularQuestion();
                                }}
                              >
                                Previous
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="exc_right_side_wrapper">
                          {testQuestionsDetails.length ===
                          currentQuestion + 1 ? (
                            <div className="btn_wrapper">
                              {sol ? (
                                <button
                                  disabled={disabled1 ? false : true}
                                  className={
                                    !disabled1
                                      ? "opacity solDisable"
                                      : "true solEnable"
                                  }
                                  onClick={() => {
                                    handleSolution();
                                  }}
                                >
                                  Solution
                                </button>
                              ) : (
                                <button
                                  className="submitBtn"
                                  onClick={() => {
                                    handleSaveQuestion();
                                    handleShow();
                                  }}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          ) : (
                            <div className="btn_wrapper">
                              {sol ? (
                                <button
                                  disabled={disabled1 ? false : true}
                                  className={
                                    !disabled1
                                      ? "opacity solDisable"
                                      : "true solEnable "
                                  }
                                  onClick={() => {
                                    handleSolution();
                                  }}
                                >
                                  Solution
                                </button>
                              ) : (
                                <button
                                  className="excr_next_btn"
                                  onClick={() => {
                                    handleNextQuestionBtn();
                                    // handleSol();
                                    scrollUp();
                                    handlePurticularQuestion3();
                                  }}
                                >
                                  Next
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="sub_section_1_wrapper">
                    <div className="sub_section_heading">
                      <div className="text_content_wrapper">
                        <p className="text_content">
                          {testQuestionsDetails[currentQuestion]?.topic_name ??
                            "Test"}
                        </p>
                        <button
                          onClick={() => {
                            handleSaveQuestion();
                            handleShow();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="sub_section_2_wrapper">
                        <div className="section_2_1">
                          <p className="allQuestionsText">All Questions</p>
                          <div className="gridviewIcons">
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <ListIcon1 /> : <ListIcon />}
                            </button>
                            <button onClick={() => setisList(!isList)}>
                              {isList ? <GridIcon /> : <GridIcon1 />}
                            </button>
                          </div>
                        </div>
                        <div className="question_marking_wrapper">
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_1"></label>
                            <label className="question_marking_text">
                              Completed
                            </label>
                          </div>
                          <div className="single_marking">
                            <label className="box_pic box_pick_background_3"></label>
                            <label className="question_marking_text">
                              Pending
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="questions_serial_number_collection"
                    ref={questionsCollectionRef}
                  >
                    <div
                      className={` 
                  ${isList ? "" : "questions_wrapper"}`}
                    >
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className={` 
                    ${
                      isList
                        ? "qstn_bnk_single_question_number_wrapper1"
                        : "qstn_bnk_single_question_number_wrapper"
                    }`}
                                id="myDiv"
                              >
                                {isList
                                  ? renderQuestionLabel1(element, index)
                                  : renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* toaster for  successfull submission of Report error  */}

        <ToastContainer />
        <Modal
          show={show}
          onHide={handleClose}
          className="onSubmitModal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="submit_Popup">
              <div className="exit_img">
                <img src={images.m2_exit} alt="" />
              </div>
              <h5>Are you Sure?</h5>
              <p>Do you want to continue the exam or you want to Submit.</p>
              <div className="submitCancelBtn">
                <button className="cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button onClick={() => handleSubmitTest()}>Submit</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showError}
          onHide={handleCloseReportError}
          className="errorModal"
          centered
        >
          <Modal.Header closeButton>
            {" "}
            <h3>Report an Error</h3>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <div className="error_Popup">
              <p className="text_content">
                You can just write to us, If there is any incorrect or error in
                question and given options.
              </p>
              <ul className="errorList">
                {reportErrorData?.map((element, index) => {
                  return (
                    <li className="errorListItem" key={index}>
                      <input
                        type="radio"
                        id={element.id}
                        name="error"
                        value={element.option_name}
                        onChange={(event) => {
                          setSaveReportErrorData({
                            ...saveReportErrorData,
                            option_id: event.target.id,
                          });
                        }}
                      />{" "}
                      <label htmlFor={element.id}>{element.option_name}</label>
                    </li>
                  );
                })}
              </ul>
              <textarea
                className="textarea_content"
                type="text"
                onChange={(event) =>
                  setSaveReportErrorData({
                    ...saveReportErrorData,
                    description: event.target.value,
                  })
                }
                placeholder="Write to Us..."
              />{" "}
              <br />
              <Button disabled={isDisable} onClick={handlePostReportErrorData}>
                Submit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default QuestionBankTestQuestionsView;
