import { Fragment, useEffect, useState } from "react";
import "./MtsResultPageContent.scss";

const MtsResultPageContent = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Function to show loader
  const startLoading = () => {
    setIsLoading(true);
  };

  // Function to hide loader when iframe loads
  const stopLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    // Start loading when component mounts
    startLoading();
  }, []);

  return (
    <Fragment>
      <div className="userEditProfileContentComponent_wrapper_mts">
        <div className="container">
          <div className="userEditProfileContentComponent_inner_wrapper_mts">
            {isLoading && (
              <p
                style={{
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Loading...
              </p>
            )}

            <div
              className="iframe_container"
              style={{ display: isLoading ? "none" : "block" }}
            >
              <iframe
                src="https://mtseresult.motion.ac.in/login.php"
                title="MTS Result"
                style={{
                  width: "100%",
                  height: "80vh",
                  border: "none",
                  marginTop: "20px",
                }}
                allowFullScreen
                onLoad={stopLoading} // Stop loader when iframe loads
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MtsResultPageContent;
